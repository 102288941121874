import { ACTIONTYPE_HOSPITALSTAFFLIST, ACTIONTYPE_LOCATIONLIST, ACTIONTYPE_PHYSICIANLIST, ACTIONTYPE_ANALYSTLIST, ACTIONTYPE_PATIENTLIST_COUNT, ACTIONTYPE_PATIENTLIST_REMOVEALL, ACTIONTYPE_SETTING_TO_RESET_PASSWORD, ACTIONTYPE_SETTING_TO_DEMO_VIEW, ACTIONTYPE_SETTING_TO_LOGIN, ACTIONTYPE_MESSAGE_CONFIG, ACTIONTYPE_TIMER_APIKEYTIMER } from "../redux/actionTypes";
import { useIntl } from "react-intl";
import { useDispatch, useMappedState } from "redux-react-hook";
import { useCallback } from 'react';
import Cookies from 'js-cookie';

var decode = require('jwt-claims');

export function useAxiosHandler() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const apiKeyTimer = useMappedState(useCallback(state => state.timer.apiKeyTimer));

  const timeoutCallback = () => {
    dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.apiKeyExpired' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top' },
      onClose: dispatch({ type: ACTIONTYPE_SETTING_TO_LOGIN, value: { toLogin: true } }) } });

    Cookies.remove('username', { path: '/', secure: true });
    Cookies.remove('apiKey', { path: '/', secure: true });
    Cookies.remove('uuid', { path: '/', secure: true });

    // clear patient list
    dispatch({
      type: ACTIONTYPE_PATIENTLIST_REMOVEALL, value: {
        listContent: [],
      }
    });
    dispatch({
      type: ACTIONTYPE_PATIENTLIST_COUNT,
      value: 0,
    });

    // clear init data
    dispatch({ type: ACTIONTYPE_ANALYSTLIST, value: { analystList: [] } });
    dispatch({ type: ACTIONTYPE_PHYSICIANLIST, value: { physicianList: [] } });
    dispatch({ type: ACTIONTYPE_LOCATIONLIST, value: { locationList: [] } });
    dispatch({ type: ACTIONTYPE_HOSPITALSTAFFLIST, value: { hospitalStaffList: [] } });
  }

  function updateJwtToken(token) {
    console.log(token);
    console.log(decode);
    try {
      var claims = decode(token);
      console.log(claims);
      var _time = (claims.exp - claims.iat) * 1000;
      console.log("timeout: " + _time)
      dispatch({ type: ACTIONTYPE_TIMER_APIKEYTIMER, value: setTimeout(timeoutCallback, _time) })
      Cookies.set('apiKey', token, { path: '/', secure: true });
    } catch(error) {
      console.error("Failed to parse token, reset cookies and redirect to login")
      console.error(error);
      Cookies.remove('username', { path: '/', secure: true });
      Cookies.remove('apiKey', { path: '/', secure: true });
      dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.apiKeyExpired' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top' } } });
      dispatch({ type: ACTIONTYPE_SETTING_TO_LOGIN, value: { toLogin: true } });
    }
    
  }

  function updateToken(response) {
    if (response.headers && response.headers.apikey) {
      updateJwtToken(response.headers.apikey);
    }
  }

  function successHandler(response, callback, callbackParams) {

    console.log("useAxiosHandler-successHandler");
    console.log(response);
    if(response.headers.apikey) {
      updateJwtToken(response.headers.apikey);
    }

    if(response.status === 200) {
      switch (response.data.error) {
        case 0:
          break;
        case 3:
          dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.apiKeyExpired' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top'} } });
          dispatch({ type: ACTIONTYPE_SETTING_TO_LOGIN, value: { toLogin: true } });
          Cookies.remove('username', { path: '/', secure: true });
          Cookies.remove('apiKey', { path: '/', secure: true });
          break;
        case 4:
          dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.insufficientPrivilege' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top'} } });
          break;
        case 5:
          dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'resetPassword.firstLogin' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top'} } });
          dispatch({ type: ACTIONTYPE_SETTING_TO_RESET_PASSWORD, value: { toResetPassword: true } });
          break;
        case 7:
          dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.demoAccount' }), timeout: 3000, color: 'info', anchorOrigin: { horizontal: 'center', vertical: 'top'} } });
          dispatch({ type: ACTIONTYPE_SETTING_TO_DEMO_VIEW, value: { toDemoView: true } });
          break;
        case 8:
          dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.authenticationFailed' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top'} }});
          break;
        case 10:
          dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.passwordResetAlready' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top' } } });
          break;
        case 12:
          dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.fileComp.binUploadDeny' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top' } } });
          break;
        case 13:
          dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.azure' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top' } } });
          break;
        case 14:
          dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.secure.ipWhiteList' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top' } } });
          break;
        case 101: 
          dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.patientNotFound' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top'} } });
          break;
        case 103: 
          dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.deviceNotFound' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top'} } });
          break;
        case 109:
          dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.patchNotFound' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top' } } });
          break;
        case 123:
          dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.wrongModel' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top' } } });
          break;
        case 401:
          dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.profile.userIdRequired' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top'} } });
          break;
        case 402:
          dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.profile.userIdUnique' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top'} } });
          break;
        case 403:
          dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.profile.userIdRegex' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top'} } });
          break;
        case 404:
          dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.profile.firstNameRequired' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top'} } });
          break;
        case 405:
          dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.profile.firstNameAlpha' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top'} } });
          break;
        case 406:
          dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.profile.lastNameRequired' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top'} } });
          break;
        case 407:
          dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.profile.lastNameAlpha' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top'} } });
          break;
        case 408:
          dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.profile.emailRequired' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top'} } });
          break;
        case 409:
          dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.profile.emailEmail' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top'} } });
          break;
        case 410:
          dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.profile.newPasswordRequired' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top'} } });
          break;
        case 411:
          dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.profile.newPasswordConfirm' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top'} } });
          break;
        case 412:
          dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.profile.newPasswordConfirmRequired' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top'} } });
          break;
        case 413:
          dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.profile.birthDate' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top'} } });
          break;
        case 414:
          dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.profile.ageNumeric' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top'} } });
          break;
        case 415:
          dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.profile.genderNumeric' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top'} } });
          break;
        case 416:
          dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.profile.newPasswordRequiredWith' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top'} } });
          break;
        case 417:
          dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.profile.oldPasswordRequiredWith' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top'} } });
          break;
        case 418:
          dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.profile.passwordIncorrect' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top'} } });
          break;
        case 419:
          dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.profile.locationNameNotString' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top' } } });
          break;
        case 420:
          dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.profile.locationUuidNotString' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top' } } });
          break;
        case 421:
          dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.profile.locationRequired' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top' } } });
          break;
        case 422:
          dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.profile.locationStatusNotInteger' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top' } } });
          break;
        case 423:
          dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.profile.locationRoleNotInteger' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top' } } });
          break;
        case 424:
          dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.profile.webviewNotBoolean' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top' } } });
          break;
        case 501:
          dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.location.addFail' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top' } } });
          break;
        case 510:
          dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.location.updateFail' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top' } } });
          break;
        case 9:
        case 110:
        case 111:
        case 112:
        case 113: 
          break;
        default:
          dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.serverError' }, { errorCode: response.data.error }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top'}}})
          break;
      }
    } else {
      console.log("Status code is "+response.status);
    }

    if (typeof callback === 'function') {
      if(callbackParams) {
        return callback(response, callbackParams);
      } else {
        return callback(response);}
    }

    return null;
  }

  function errorHandler(error, callback, callbackParams) {

    console.log("useAxiosHandler-errorHandler");
    console.log(error);

    const loopBreaker = false;

    do{
      if (error && error.response) {
        // client received and error response
        console.log("get error response from server.");

        if (error.response.status === 503 && /^/.test(error.response.data)) {
          console.log('get 503, jump to maintenance page')
          dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.server.maintanence' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top' } } });
          break;
        } else if (error.response.status === 401) { // 401: Unauthorized
          Cookies.remove('username', { path: '/', secure: true });
          Cookies.remove('apiKey', { path: '/', secure: true });
          dispatch({ type: ACTIONTYPE_SETTING_TO_LOGIN, value: { toLogin: true, }});
        } else if (error.response.headers.apikey) {
          updateJwtToken(error.response.headers.apikey);
          // setCookie('apiKey', error.response.headers.apikey, { path: '/', secure: true });
        }
      } else if (error && error.request) {
        // client never received a response, or request never left
        console.log("failed send request to server.");
      } else {
        // anything else
        console.log("unexpected error occurred.");
      }

      if(error.message) {
        dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: error.message, timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top' } } });
      }
    } while(loopBreaker);

    if(typeof callback === 'function') {
      if(callbackParams) {
        return callback(error, callbackParams);
      } else {
        return callback(error);}
    }

    return null;
  }

  return [successHandler, errorHandler, updateToken];
}

export default useAxiosHandler;