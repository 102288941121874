import React from 'react'; 
import { Navigate } from "react-router";
import Cookies from "js-cookie";

export const ProtectedRoute = ({ children }) => {
  if (!(Cookies.get('username') && Cookies.get('apiKey'))) {
    return <Navigate to="/webanalyzer/v1/login" />;
  }
  return children;
};

export default ProtectedRoute;