import React, { useEffect, useState, useCallback } from "react";
import { Navigate } from "react-router-dom";
import classNames from "classnames";
import PropTypes from 'prop-types';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Poppers from "@material-ui/core/Popper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Language from "@material-ui/icons/Language";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
// core components
import Button from "components/CustomButtons/Button.jsx";
import Cookies from "js-cookie";
import CookieConsent from "react-cookie-consent";
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import { useDispatch, useMappedState } from "redux-react-hook";
import { ACTIONTYPE_SETTING_LANGUAGE,
  ACTIONTYPE_PATIENTLIST_REMOVEALL, ACTIONTYPE_PATIENTLIST_COUNT,
  ACTIONTYPE_ANALYSTLIST, ACTIONTYPE_PHYSICIANLIST, ACTIONTYPE_LOCATIONLIST, ACTIONTYPE_HOSPITALSTAFFLIST, ACTIONTYPE_SETTING_TO_LOGIN,
  ACTIONTYPE_MESSAGE_CONFIG, ACTIONTYPE_TIMER_APIKEYTIMER, ACTIONTYPE_SETTING_TO_DEMO_VIEW, ACTIONTYPE_SETTING_TO_PATIENTLIST } from "../../redux/actionTypes";
import { FormattedMessage, useIntl } from 'react-intl';
import UserProfile from "../../views/UserProfile/UserProfile";
import Export from "../../views/Settings/Export";
import SalesPrivilege from "../../views/Settings/SalesPrivilege";
import { DialogTitle } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import LocationProfile from "../../views/LocationProfile/LocationProfile";
import Announcement from "../../views/Settings/Announcement";
import { axiosService } from "axios/axiosInstance";
import useAxiosHandler from "../../axios/axiosHandler";
import { isPowerUser, isAnalyst, isSeniorSales, isJuniorSales } from "../../components/Azure/Utilities";
import { useSnackbar } from 'notistack';

function HeaderLinks(props) {
  const { onlySetting = false } = props;
  const { resetPassword = false } = props;
  const dispatch = useDispatch();
  const { closeSnackbar } = useSnackbar();
  const [anchorElSetting, setAnchorElSetting] = useState(null);
  const [openSetting, setOpenSetting] = useState(false);
  const [anchorElLanguage, setAnchorElLanguage] = useState(null);
  const [openLanguage, setOpenLanguage] = useState(false);
  const [dialogComponent, setDialogComponent] = useState(null);
  const role = useMappedState(useCallback(state => state.setting.role));
  const toLogin = useMappedState(useCallback(state => state.setting.toLogin));
  const toResetPassword = useMappedState(useCallback(state => state.setting.toResetPassword));
  const toDemoView = useMappedState(useCallback(state => state.setting.toDemoView));
  const toPatientList = useMappedState(useCallback(state => state.setting.toPatientList));
  const apiKeyTimer = useMappedState(useCallback(state => state.timer.apiKeyTimer));
  const [canAddUser, setCanAddUser] = useState(isPowerUser(role));
  const [canAddLocation, setCanAddLocation] = useState(isPowerUser(role));
  const [canEditAnnouncement, setCanEditAnnouncement] = useState(isPowerUser(role));
  const [canExportQualityDocument, setCanExportQualityDocument] = useState(isPowerUser(role) || isAnalyst(role));
  const [canEditSalesPrivilege, setCanEditSalesPrivilege] = useState(isPowerUser(role) || isSeniorSales(role));
  const [canOpenDemo, setCanOpenDemo] = useState(isPowerUser(role) || isSeniorSales(role) || isJuniorSales(role));
  const [showCookiePolicy, setShowCookiePolicy] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [privacyConfirmChecked, setPrivacyConfirmChecked] = useState(false);
  const [queryDeviceId, setQueryDeviceId] = useState('');
  const intl = useIntl();
  const [, errorHandler, updateToken] = useAxiosHandler();

  useEffect(() => {
    console.log(navigator.languages);
    console.log(!Cookies.get('_SG_P_P'));// SiGknow Privacy Policy
    console.log(navigator.languages.map(v => v.toLowerCase()).includes('zh-tw') && !Cookies.get('_SG_P_P') );
    if (navigator.languages.map(v => v.toLowerCase()).includes('zh-tw')) {
      if (!Cookies.get('_SG_P_P')) {
        setShowPrivacyPolicy(true);
      }
      if (!Cookies.get('_SG_C_P')) {
        setShowCookiePolicy(true);
      }
    }
  }, []);

  useEffect(()=> {
    setCanAddUser(isPowerUser(role));
    setCanAddLocation(isPowerUser(role));
    setCanEditAnnouncement(isPowerUser(role));
    setCanExportQualityDocument(isPowerUser(role) || isAnalyst(role));
    setCanEditSalesPrivilege(isPowerUser(role) || isSeniorSales(role));
    setCanOpenDemo(isPowerUser(role) | isSeniorSales(role) | isJuniorSales(role));
  }, [role]);

  const handleToggleLanguage = (event) => {
    setOpenLanguage(!openLanguage);
    setAnchorElLanguage(event.currentTarget);
  };

  const handleToggle = (event) => {
    setOpenSetting(!openSetting);
    setAnchorElSetting(event.currentTarget);
  };

  const handleClickAway = event => {
    console.log(event);
    setOpenSetting(false);
    setOpenLanguage(false);
  }

  const handleClose = event => {
    console.log(event);
    // Remove username cookie after stop listening UserNotification channel.
    // Cookies.remove('username', { path: '/', secure: true });
    Cookies.remove('apiKey', { path: '/', secure: true });
    Cookies.remove('uuid', { path: '/', secure: true });
    setOpenSetting(false);
    setDialogComponent(null)
    dispatch({
      type: ACTIONTYPE_SETTING_TO_LOGIN, value: {
        toLogin: true,
      }
    })

    // clear patient list
    dispatch({
      type: ACTIONTYPE_PATIENTLIST_REMOVEALL, value: {
        listContent: [],
      }
    });
    dispatch({
      type: ACTIONTYPE_PATIENTLIST_COUNT,
      value: 0,
    });

    // clear init data
    dispatch({ type: ACTIONTYPE_ANALYSTLIST, value: { analystList: [] } });
    dispatch({ type: ACTIONTYPE_PHYSICIANLIST, value: { physicianList: [] } });
    dispatch({ type: ACTIONTYPE_LOCATIONLIST, value: { locationList: [] } });
    dispatch({ type: ACTIONTYPE_HOSPITALSTAFFLIST, value: { hospitalStaffList: [] } });

    // clear apikey timer
    if (apiKeyTimer !== null) {
      clearTimeout(apiKeyTimer);
    }
    dispatch({ type: ACTIONTYPE_TIMER_APIKEYTIMER, value: null });
  };

  const onCloseDialog = () => {
    console.log('onCloseProfileDialog');
    setDialogComponent(null);
  };

  const handleOpenDemo = () => {
    if(window.location.pathname.toLowerCase().includes('demo')) {
      dispatch({ type: ACTIONTYPE_SETTING_TO_PATIENTLIST, value: { toPatientList: true } });
      dispatch({ type: ACTIONTYPE_LOCATIONLIST, value: { locationList: [] } });
    } else {
      dispatch({ type: ACTIONTYPE_SETTING_TO_DEMO_VIEW, value: { toDemoView: true } });
      dispatch({ type: ACTIONTYPE_LOCATIONLIST, value: { locationList: [{ Name: "Demo" }] } });
    }
    handleClickAway();
  }

  const handleEditSalesPrivilege = () => {
    setDialogComponent(<Dialog open onClose={onCloseDialog} aria-labelledby="form-dialog-title">
      <DialogContent>
        <SalesPrivilege onClose={onCloseDialog}></SalesPrivilege>
      </DialogContent>
    </Dialog>);
    handleClickAway();
  }

  const handleExportQualityDocument = () => {
    setDialogComponent(<Dialog open onClose={onCloseDialog} aria-labelledby="form-dialog-title">
      <DialogContent>
        <Export onClose={onCloseDialog}></Export>
      </DialogContent>
    </Dialog>);
    handleClickAway();
  }

  const handleAddUser = () => {
    setDialogComponent(<Dialog open fullWidth maxWidth={"md"} onClose={onCloseDialog} aria-labelledby="form-dialog-title">
      <DialogContent>
        <UserProfile newUser onClose={onCloseDialog} />
      </DialogContent>
    </Dialog>);
    handleClickAway();
  }

  const handleAddLocation = () => {
    setDialogComponent(<Dialog open maxWidth={"sm"} onClose={onCloseDialog} aria-labelledby="form-dialog-title">
      <DialogContent>
        <LocationProfile onClose={onCloseDialog} />
      </DialogContent>
    </Dialog>);
    handleClickAway();
  }

  const handleEditAnnouncement = () => {
    setDialogComponent(<Dialog open maxWidth={"sm"} onClose={onCloseDialog} aria-labelledby="form-dialog-title">
      <DialogContent>
        <Announcement onClose={onCloseDialog} />
      </DialogContent>
    </Dialog>);
    handleClickAway();
  }

  const handleEditUser = () => {
    setDialogComponent(<Dialog open fullWidth maxWidth={"md"} onClose={onCloseDialog} aria-labelledby="form-dialog-title">
      <DialogContent>
        <UserProfile onClose={onCloseDialog} />
      </DialogContent>
    </Dialog>);
    handleClickAway();
  }

  const RedirectUi = () => {
    if(toLogin) {
      handleClose();
      closeSnackbar();
      return <Navigate to='/webanalyzer/v1/login' />;
    } else if (toDemoView) {
      return <Navigate to='/webanalyzer/v1/demo' />;
    } else if (toResetPassword) {
      return <Navigate to='/webanalyzer/v1/user/resetpassword' />;
    } else if(toPatientList) {
      return <Navigate to='/webanalyzer/v1/patientlist' />;
    } else {
      return null;
    }
  };

  const handleClosePrivacyPolicy = () => {
    const expireDate = new Date();
    expireDate.setDate(expireDate.getDate() + 150);
    Cookies.set("_SG_P_P", true, { path: '/', secure: true, expires: expireDate });
    setShowPrivacyPolicy(false);
  }

  const handleQueryDevice = async (deviceId) => {
    try {
      if(deviceId.length !== 11) {
        dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.fileComp.serviceIdFail' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top' } } });
        return;
      }
      const model = (deviceId.startsWith('T')) ? 'UG01' : 'UG02';
      const response = await axiosService.get(
        '/match/device?q=1&deviceId=' + deviceId + (model === 'UG01' ? '' : '&serviceId=' + deviceId) + '&model=' + model,
        {
          headers: {
            'Authorization': 'Bearer ' + Cookies.get('apiKey'),
            'X-Socket-ID': window.Echo ? window.Echo.socketId() : ''
          }
        });
      console.log(response);
      updateToken(response);
      if (response.status !== 200 || response.data.error !== 0) {
        console.log("device check response error: " + response.data.error);

        // bypass customer and device doesn't match error
        console.log("index result: " + [9, 110, 111, 112, 113].indexOf(response.data.error));

        if ([9, 110, 111, 112, 113].indexOf(response.data.error) === -1 || 
            !response.data.message || !response.data.message.days ||
            response.data.message.days === 0) {
          dispatch({
            type: ACTIONTYPE_MESSAGE_CONFIG, value: {
              open: true,
              message: intl.formatMessage({ id: 'headerLinks.unableToGetDeviceDays' }),
              timeout: 3000,
              color: 'error',
              anchorOrigin: { horizontal: 'center', vertical: 'top' }
            }
          });
          return;
        }
      }

      dispatch({
        type: ACTIONTYPE_MESSAGE_CONFIG, value: {
          open: true,
          message: intl.formatMessage({ id: 'headerLinks.deviceDays' }, { deviceId: deviceId, days: response.data.message.days }),
          timeout: 3000,
          color: 'info',
          anchorOrigin: { horizontal: 'center', vertical: 'top' }
        }
      });
    } catch (error) {
      errorHandler(error);
      return;
    }
  }

  const classes = props;
  return (
    <div>
      <div>{dialogComponent}</div>
      {toLogin || toResetPassword || toDemoView || toPatientList ? <RedirectUi /> :
        <div className={classes.manager}
          style={{ display: "flex", flexDirection: "row-reverse" }}>
          {onlySetting ? null : <Button
            color="transparent"
            justIcon={true}
            simple={false}
            aria-owns={null}
            aria-haspopup="true"
            onClick={handleToggle}
            className={classes.buttonLink}
          >
            <Person className={classes.icons}/>
          </Button>}
          <Poppers
            open={openSetting}
            anchorEl={anchorElSetting}
            transition
            disablePortal
            className={
              classNames({ [classes.popperClose]: !openSetting })
            }
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="menu-list-grow"
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom"
                }}
              >
                <Paper>
                  <ClickAwayListener mouseEvent={"onMouseDown"} onClickAway={handleClickAway}>
                    <MenuList role="menu">
                      {canOpenDemo && !resetPassword ? <MenuItem
                        onClick={handleOpenDemo}
                        className={classes.dropdownItem}>
                        {window.location.pathname.toLowerCase().includes('demo') ? <FormattedMessage id="headerLinks.leaveDemo"/>:<FormattedMessage id="headerLinks.toggleDemo"/>}
                      </MenuItem> : null}
                      {canEditSalesPrivilege && !resetPassword ? <MenuItem
                        onClick={handleEditSalesPrivilege}
                        className={classes.dropdownItem}>
                        <FormattedMessage id="headerLinks.editSalesPrivilege" />
                      </MenuItem> : null}
                      {canExportQualityDocument && !resetPassword ? <MenuItem
                        onClick={handleExportQualityDocument}
                        className={classes.dropdownItem}>
                        <FormattedMessage id="headerLinks.exportQualityDocument" />
                      </MenuItem> : null}
                      {canAddUser && !resetPassword ? <MenuItem
                        onClick={handleAddUser}
                        className={classes.dropdownItem}>
                        <FormattedMessage id="headerLinks.addUser" />
                      </MenuItem> : null}
                      {canAddLocation && !resetPassword ? <MenuItem
                        onClick={handleAddLocation}
                        className={classes.dropdownItem}>
                        <FormattedMessage id="headerLinks.location" />
                      </MenuItem> : null}
                      {canEditAnnouncement && !resetPassword ? <MenuItem
                        onClick={handleEditAnnouncement}
                        className={classes.dropdownItem}>
                        <FormattedMessage id="headerLinks.editAnnouncement" />
                      </MenuItem> : null}
                      <MenuItem
                        button={false}
                      >
                        <InputBase
                          value={queryDeviceId}
                          placeholder={intl.formatMessage({ id: 'headerLinks.getDevicePlaceholder' })}
                          inputProps={{ 'aria-label': 'query device', style: {width: '150px'} }}
                          onClick={(e) => e.stopPropagation()}
                          onInput={(e) => {
                            if (e.target.value.length > 11) {
                              e.target.value = e.target.value.slice(0, 11);
                            }
                            setQueryDeviceId(e.target.value);
                          }}
                          onKeyDown = { event => {
                            if ((event.key === 'Enter' || event.keyCode === 13) && !event.shiftKey)
                              handleQueryDevice(queryDeviceId)
                          }}
                        />
                        <IconButton type="submit" aria-label="search">
                          <SearchIcon onClick={(e) => {
                            handleQueryDevice(queryDeviceId);
                            e.stopPropagation();
                          }} />
                        </IconButton>
                      </MenuItem>
                      {!resetPassword ? <MenuItem
                        onClick={handleEditUser}
                        className={classes.dropdownItem}>
                        <FormattedMessage id="headerLinks.editProfile" />
                      </MenuItem> : null}
                      <MenuItem
                        onClick={handleClickAway}
                        className={classes.dropdownItem}
                      >
                        <a href={Cookies.get('lang') === "en-US" ? "https://sites.google.com/view/ezyiseeapp/english" : "https://sites.google.com/view/ezyiseeapp/%E7%B9%81%E9%AB%94%E4%B8%AD%E6%96%87"} target="_blank" rel="noreferrer"><FormattedMessage id={'headerLinks.cookiePolicy'} /></a>
                      </MenuItem>
                      <MenuItem
                        onClick={handleClose}
                        className={classes.dropdownItem}
                      >
                        <FormattedMessage id="headerLinks.logout" />
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Poppers>

          <Button
            color="transparent"
            justIcon={true}
            simple={false}
            aria-owns={null}
            aria-haspopup="true"
            onClick={handleToggleLanguage}
            className={classes.buttonLink}
          >
            <Language className={classes.icons}
            />
          </Button>
          <Poppers
            open={openLanguage}
            anchorEl={anchorElLanguage}
            transition
            disablePortal
            className={
              classNames({ [classes.popperClose]: !openLanguage })
            }
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="menu-list-grow"
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom"
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClickAway}>
                    <MenuList role="menu">
                      <MenuItem onClick={() => {
                        dispatch({type: ACTIONTYPE_SETTING_LANGUAGE, value: {language: 'zh-TW'}});
                        Cookies.set('lang', 'zh-TW', { path: '/', secure: true });
                      }}>
                        繁體中文
                      </MenuItem>
                      <MenuItem onClick={() => {
                        dispatch({ type: ACTIONTYPE_SETTING_LANGUAGE, value: { language: 'en-US' } });
                        Cookies.set('lang', 'en-US', { path: '/', secure: true });
                      }}>
                        English
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Poppers>
        </div>}
      {showPrivacyPolicy ? <div>
        <Dialog open fullWidth maxWidth={"md"}
          onClose={()=>{}} aria-labelledby="form-dialog-title">
          <DialogTitle style={{textAlign: 'center'}}>
            <FormattedMessage id="headerLinks.privacyTitle" />
          </DialogTitle>
          <DialogContent>
            <Grid constainer>
              <Grid xs={12} item >
                <span style={{textAlign: "center"}}>
                  <FormattedMessage id="headerLinks.privacyContent" 
                    values={{ 
                      br: <br />,
                      mailto: str => <a href={'mailto:'+str} >{str}</a>,
                      li: str=> <li style={{ textAlign: "left" }}>{str}</li>,
                    }} />
                </span>
              </Grid>
              <Grid xs={12} item style={{ textAlign: "center"}}>
                <FormControl component="fieldset" className={classes.formControl}>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={privacyConfirmChecked} onClick={() => {
                        setPrivacyConfirmChecked(!privacyConfirmChecked);
                      }} />}
                      label={intl.formatMessage({ id: "headerLinks.privacyCheck" })}
                      style={{color: "#000000"}}
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button disabled={!privacyConfirmChecked} color="primary" onClick={handleClosePrivacyPolicy}>
              <FormattedMessage id='headerLinks.accept' ></FormattedMessage>
            </Button>
          </DialogActions>
        </Dialog>
      </div> : null}
      {showCookiePolicy ? <CookieConsent
        location="bottom"
        buttonText={intl.formatMessage({ id: 'headerLinks.accept' })}
        cookieName="_SG_C_P" // Sigknow cookie policy
        style={{ color: "rgb(50,113,174)", backgroundColor: "rgb(228,243,250,0.5)" }}
        buttonStyle={{ background: "#084A96", color: "#FFFFFF", fontSize: "13px" }}
        expires={150}
        onAccept={() => {
          setShowCookiePolicy(false);
        }}
      >
        <FormattedMessage id="headerLinks.cookieConsent" values={{
          link: <a href={Cookies.get('lang') === "en-US" ? "https://sites.google.com/view/ezyiseeapp/english" : "https://sites.google.com/view/ezyiseeapp/%E7%B9%81%E9%AB%94%E4%B8%AD%E6%96%87"} target="_blank" rel="noreferrer"><FormattedMessage id={'headerLinks.cookiePolicy'} /></a>
        }} />
      </CookieConsent> : null}
    </div>
  );
}

HeaderLinks.propTypes = {
  onlySetting: PropTypes.bool,
  resetPassword: PropTypes.bool
}

export default withStyles(headerLinksStyle)(HeaderLinks);
