import React from 'react';
import './ToolbarButton.css';
import PropTypes from 'prop-types';
import { Send } from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton"

const ToolbarButton = (props) => {
  const { onHandleClick } = props;
  return (
    <IconButton><Send color="primary" data-testid="button" onClick={onHandleClick}>
    </Send></IconButton>);
}

ToolbarButton.propTypes = {
  icon: PropTypes.string,
  onHandleClick: PropTypes.func
}

export default ToolbarButton;