import React, { useEffect, useState, useCallback, useRef, lazy, Suspense } from "react";
import PropTypes from "prop-types";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Timer from '@material-ui/icons/Timer';
import NewReleases from "@material-ui/icons/NewReleases"
import Warning from "@material-ui/icons/Warning";
import Message from "@material-ui/icons/Message";
// core components
import Popover from '@material-ui/core/Popover';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { axiosService } from "axios/axiosInstance";
import { useDispatch, useMappedState } from "redux-react-hook";
import Cookies from "js-cookie";
import useAxiosHandler from "../../axios/axiosHandler";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import EnhancedTable from "../../components/Table/EnhancedTable";
import { ACTIONTYPE_ANALYSTLIST, ACTIONTYPE_PHYSICIANLIST, ACTIONTYPE_LOCATIONLIST, ACTIONTYPE_HOSPITALSTAFFLIST, ACTIONTYPE_PATIENTLIST_UPDATE, ACTIONTYPE_PATIENTLIST_ADD, ACTIONTYPE_PATIENTLIST_REMOVEALL, ACTIONTYPE_PATIENTLIST_COUNT, ACTIONTYPE_SETTING_TO_LOGIN, ACTIONTYPE_MESSAGE_CONFIG } from "../../redux/actionTypes";
import Echo from 'laravel-echo';

import Button from "components/CustomButtons/Button.jsx";

import FileComp from "components/FileComponent/FileComp";
import { useIntl, FormattedMessage } from 'react-intl';

import { io } from 'socket.io-client';

const PatientDetail = lazy(() => import('views/PatientDetail/PatientDetail'));

const styles = {
  icons: {
    verticalAlign: "middle"
  }
};

export function TimeComponent(props) {
  const { timeString, lang } = props;
  const utctime = new Date(timeString);
  const time = new Date(Date.UTC(utctime.getFullYear(), utctime.getMonth(), utctime.getDate(), utctime.getHours(), utctime.getMinutes(), utctime.getSeconds()));

  if ((Date.now() - time.getTime()) / 1000 < 31536000) { // less than 1 year
    if ((Date.now() - time.getTime()) / 1000 < 86400) { // less than 1 day
      return (<Tooltip title={TimeString(time, lang)}><div>{(time.getHours() < 10 ? '0' : '') + time.getHours() + ':' + (time.getMinutes() < 10 ? '0' : '') + time.getMinutes()}</div></Tooltip>);
    } else {
      return (<Tooltip title={TimeString(time, lang)}><div>{lang === 'en-US' ?(time.getDate() < 10 ? '0' : '') + time.getDate() + '/' + (time.getMonth() + 1 < 10 ? '0' : '') + (time.getMonth() + 1) :(time.getMonth() + 1 < 10 ? '0' : '') + (time.getMonth() + 1) + '/' + (time.getDate() < 10 ? '0' : '') + time.getDate()}</div></Tooltip>);
    }
  } else {
    return (<Tooltip title={TimeString(time, lang)}><div>{time.getFullYear()}</div></Tooltip>);
  }
}

export function TimeString(time, lang) {
  if (time instanceof Date) {
    if(lang === 'en-US') {
      return (time.getDate() < 10 ? '0' : '') + time.getDate() + '/' + (time.getMonth() < 9 ? '0' : '') + (time.getMonth() + 1) + '/' +time.getFullYear() + ' ' +
      (time.getHours() < 10 ? '0' : '') + time.getHours() + ':' + (time.getMinutes() < 10 ? '0' : '') + time.getMinutes() + ':' + (time.getSeconds() < 10 ? '0':'') + time.getSeconds();
    } else {
      const options = { second: '2-digit', minute: '2-digit', hour: '2-digit', hour12: false, day: '2-digit', month: '2-digit', year: 'numeric' };
      return time.toLocaleString(navigator.language, options);
    }
  } else {
    return '';
  }
}

TimeComponent.propTypes = {
  timeString: PropTypes.string.isRequired,
  lang: PropTypes.string
}

const MessageButton = (props) => {
  const { hasMessage, hasNewMessage, onClick } = props;
  const intl = useIntl();
  return (hasMessage ? <Tooltip title = {intl.formatMessage({ id: 'patientDetail.conversation' }) } >
    <IconButton component="div" aria-label="openChat" onClick={onClick}>
      {hasNewMessage ? <Badge badgeContent={hasNewMessage} max={99} color="error">
        <Message style={{ color: "green" }} />
      </Badge> : <Message style={{ color: "green" }} />}
    </IconButton>
    </Tooltip > : null);
}
MessageButton.propTypes = {
  hasMessage: PropTypes.func.isRequired,
  hasNewMessage: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired
}

const EmergentIcon = (props) => {
  const { emergentNoteOrigin } = props;
  const intl = useIntl();
  return emergentNoteOrigin ?<Tooltip title={intl.formatMessage({ id: 'patientList.emergency' })}>
      <NewReleases style={{ ...styles.icons, color: "red" }} />
    </Tooltip> : null;
}
EmergentIcon.propTypes = {
  emergentNoteOrigin: PropTypes.bool.isRequired
}

const UrgentIcon = (props) => {
  const { isUrgent } = props;
  const intl = useIntl();
  return isUrgent ? <Tooltip title={intl.formatMessage({ id: 'patientDetail.info.isUrgent' })}>
    <Timer style={{ ...styles.icons, color: "orange" }} />
  </Tooltip> : null;
}
UrgentIcon.propTypes = {
  isUrgent: PropTypes.bool.isRequired
}

const Icons = (props) => {
  return <div>
    {props.children}
  </div>;
}
Icons.propTypes = {
  children: PropTypes.node
}

export const Tag = (props) => {
  return <Tooltip title={props.title}><div style={{
    marginLeft: '1px',
    marginRight: '1px',
    marginTop: 'auto',
    marginBottom: 'auto',
    backgroundColor: props.color,
    width: 10,
    height: 20,
  }}/>
  </Tooltip>;
}
Tag.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
}

function PatientList(props) {

  const {queryContent, createEchoChannel, classes} = props;
  const dispatch = useDispatch();
  const count = useMappedState(useCallback(state => state.patientList.count));
  const patientInfoArray = useMappedState(useCallback(state => state.patientList.listContent));
  const lang = useMappedState(useCallback(state => state.setting.language));
  const locationList = useMappedState(useCallback(state => state.initialData.locationList));
  const role = useMappedState(useCallback(state => state.setting.role));
  const patientInfoArrayRef = useRef();
  patientInfoArrayRef.current = patientInfoArray;
  const [successHandler, errorHandler] = useAxiosHandler();

  const [dialogComponent, setDialogComponent] = useState(null);
  const [detailUuid, setDetailUuid] = useState('');
  const detailUuidRef = useRef();
  detailUuidRef.current = detailUuid;
  const [showPatientDetail, setShowPatientDetail] = useState(false);
  const [showMessenger, setShowMessenger] = useState(false);
  const showMessengerRef = useRef();
  showMessengerRef.current = showMessenger;
  const [enableChatNotify, setEnableChatNotify] = useState(false);


  const [isFocus, setIsFocus] = useState(true);
  const isFocusRef = useRef();
  isFocusRef.current = isFocus;

  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(20);
  const [order, setOrder] = useState('desc');
  const [orderby, setOrderby] = useState('updateTime');
  const [selected, setSelected] = useState([]);
  const [searchWord, setSearchWord] = useState('');
  const [filterList, setFilterList] = useState([]);
  const [showStatus, setShowStatus] = useState(true);
  const [showLocation, setShowLocation] = useState(false);
  const [socketId, setSocketId] = useState(window.Echo ? window.Echo.socketId() : '');
  // const [showSigknowTag, setShowSigknowTag] = useState(false);

  const [isOnSubmit, setIsOnSubmit] = useState(false);
  const [isDetailModified, setIsDetailModified] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const [newRecordTags, setNewRecordTags] = useState(null);
  const [newPatientInfoArray, setNewPatientInfoArray] = useState([]);
  const newPatientInfoArrayRef = useRef();
  newPatientInfoArrayRef.current = newPatientInfoArray;
  const [isSigknow, setIsSigknow] = useState(false);

  const intl = useIntl();

  const onFocus = () => {
    setIsFocus(true);
  }

  const onBlur = () => {
    setIsFocus(false);
  }

  const onMessageButtonClick = ({ uuid }) => {

    setDetailUuid(uuid);
    const target = patientInfoArrayRef.current.find((value) => {
      return value.uuid === uuid;
    });
    if (target !== undefined) {
      setEnableChatNotify(target.hasSubscribed);
      dispatch({
        type: ACTIONTYPE_PATIENTLIST_UPDATE, value: {
          ...target,
          hasNewMessage: 0,
          icons: <Icons>
              <MessageButton hasMessage={target.hasMessage} hasNewMessage={0} onClick={e => {
                e.stopPropagation();
                onMessageButtonClick({ uuid: target.uuid, hasSubscribed: target.hasSubscribed, boardEditable: target.boardEditable });
                }} />
              <EmergentIcon emergentNoteOrigin={target.emergentNoteOrigin} />
              <UrgentIcon isUrgent={target.isUrgent} />
            </Icons>,
          isUpdated: false
        }
      });
    }
    setShowMessenger(true);
  }

  function createData(item, locations, tags, sigknowTags) {
    const icons = <Icons>
      <MessageButton hasMessage={item.hasMessage} hasNewMessage={item.hasNewMessage} onClick={e => {
        e.stopPropagation();
        onMessageButtonClick({ uuid: item.uuid, hasSubscribed: item.hasSubscribed, boardEditable: item.boardEditable });
      }} />
      <EmergentIcon emergentNoteOrigin={item.emergentNote} />
      <UrgentIcon isUrgent={item.isUrgent} />
    </Icons>;
    const sex = item.sex === 0 ?
      intl.formatMessage({ id: 'patientList.male' }) :
      item.sex === 1 ? intl.formatMessage({ id: 'patientList.female' }) :
        intl.formatMessage({ id: 'patientList.unknown' });
    const updateTimeComponent = <TimeComponent timeString={item.updateTime} lang={lang} />;
    const createTimeComponent = <TimeComponent timeString={item.createTime} lang={lang}/>;
    console.log('CanOpenPdfViewer %s, CanDownloadPdf %s, CanEditComment %s', item.canOpenPdfViewer, item.canDownloadReport, item.canEditComment);
    const signalComponent = 
      <FileComp identifier="list" type={'signal'} fileType={'.bin'} canUpload={item.canUploadSignal} canDownload={item.canDownloadSignal} uuid={item.uuid} deviceId={item.deviceId} patchId={item.patchId} inputFileName={item.signalName} inputFileSize={item.signalSize} onUploaded={() => console.log('patient list onUploaded')} onDownloaded={() => { }} prePathname={"/file/request"} postPathname={"/file/request"}></FileComp>;
    const reportComponent = 
      <FileComp identifier="list" type={'report'} fileType={'.pdf,.doc,.docx'} canUpload={item.canUploadReport} canDownload={item.canDownloadReport} canOpenWebViewer={item.canOpenWebViewer} canOpenPdfViewer={item.canOpenPdfViewer} canEditComment={item.canEditComment} uuid={item.uuid} deviceId={item.deviceId} patchId={item.patchId} inputFileName={item.reportName} signalName={item.signalName} inputFileSize={item.reportSize} onUploaded={() => { }} onDownloaded={() => { }} setDialogComponent={setDialogComponent} prePathname={"/file/request"} postPathname={"/file/request"} isCommentModified={item.isCommentModified}></FileComp>;

    // If item doesn't have location field, then current user is not Sigknow user and only one location exists.
    // One location applies to one 'tags' element(4 tags included).

    // const sigknowTag = item.sigknowTag === null || item.sigknowTag === undefined ? null : <div style={{
    //   width: 'fit-content',
    //   marginLeft: 'auto',
    //   marginRight: 'auto',
    //   display: 'flex',
    // }}>{item.sigknowTag[0] !== null ? <Tag color={"red"} title={sigknowTags[0].TagName} /> : null}
    //   {item.sigknowTag[1] !== null ? <Tag color={"green"} title={sigknowTags[1].TagName} /> : null}
    //   {item.sigknowTag[2] !== null ? <Tag color={"orange"} title={sigknowTags[2].TagName} /> : null}
    //   {item.sigknowTag[3] !== null ? <Tag color={"pink"} title={sigknowTags[3].TagName} /> : null}</div>;

    return { uuid: item.uuid , 
      hasSubscribed: item.hasSubscribed, 
      emergentNoteOrigin: item.emergentNote,
      boardEditable: item.boardEditable, 
      hasMessage: item.hasMessage,
      isUrgent: item.isUrgent,
      icons: icons,
      patchId: item.patchId,
      deviceId: item.deviceId, 
      name: item.name, 
      // tag: tag, 
      sex: sex, 
      age: item.age === 0 ? intl.formatMessage({ id: 'patientDetail.info.unknown'}) : item.age, 
      patientId: item.patientId,
      checkId: item.checkId, 
      physician: item.physician, 
      signalComponent: signalComponent, 
      reportComponent: reportComponent, 
      updateTimeComponent: updateTimeComponent, 
      createTimeComponent: createTimeComponent, 
      status: item.status, 
      location: item.location === null || item.location === undefined ? null : locations[item.location-1].Name, 
      // sigknowTag: sigknowTag ,
      isUpdated: item.isUpdated
    };
  }

  const handleCloseDetail = (event, reason) => {
    console.log("reason: "+reason);
    if (isDetailModified && !isOnSubmit){
      console.log("data is modified, please confirm");
      if (event && typeof event.stopPropagation === 'function') {
        event.stopPropagation();
      }
      setOpenConfirmDialog(true);
      return;
    }

    setIsDetailModified(false);
    setShowPatientDetail(false);
    setShowMessenger(false);
    setIsOnSubmit(false);
    setDetailUuid('');
  };


  function handleAddNewPatient() {
    setShowPatientDetail(true);
    setDetailUuid('');
  }

  function queryContentFromServer() {
    return axiosService.post(
      '/patientlist/content?offset=' + offset + '&limit=' + limit + '&orderby=' + orderby + '&order=' + order + '&initdata=' + (locationList.length === 0),
      {
        search: searchWord,
        tag: [],
        filter: filterList,
          // Filter contains many condition objects. Each condition object relates by
          // 'AND' operator.
          // field: the condition specified field
          // limit1: 'start from', 'larger than', 'contain' condition. If there is only 1 search condition
          //         , it should be placed here.
          // limit2: 'end to', 'lesser than' condition. A condition object has limit2 only for
          //         time, age, etc.
          // not: Exclude the search result. For example, condition object is { field: age, limit1: 5, limit2: 50, not: true }
          //      , the response will be the patient who's age is under 5 or elder than 50
          // {
          //   field: 'updateTime', // required
          //   limit1: 'startTimeLimit', // optional
          //   limit2: 'endTimeLimit', // optional
          //   not: false, // optional, default false
          // }
          
      },
      {
        headers: {
          'Authorization': 'Bearer ' + Cookies.get('apiKey'),
          'X-Socket-ID': socketId
        }
      }).then(response => {
        console.log(response.data);
        function onSuccess(response) {
          if(response.status === 200 && response.data.error === 0){
            dispatch({
              type: ACTIONTYPE_PATIENTLIST_REMOVEALL, value: {
                listContent: [],
              }
            });
            handleMessage(response.data.message);
          }
        }
        successHandler(response, onSuccess);
      }).catch(error => {
        errorHandler(error);
      });
  }

  const handleMessage = (message) => {
    if (message) {
      dispatch({
        type: ACTIONTYPE_PATIENTLIST_REMOVEALL, value: {
          listContent: [],
        }
      });
      if (message.initialData !== undefined) {
        if (message.initialData.analystList !== undefined) {
          dispatch({ type: ACTIONTYPE_ANALYSTLIST, value: { analystList: message.initialData.analystList } });
        }
        if (message.initialData.physicianList !== undefined) {
          dispatch({ type: ACTIONTYPE_PHYSICIANLIST, value: { physicianList: message.initialData.physicianList } });
        }
        if (message.initialData.locations !== undefined) {
          dispatch({ type: ACTIONTYPE_LOCATIONLIST, value: { locationList: message.initialData.locations } });
        }
        if (message.initialData.hospitalStaff !== undefined) {
          dispatch({ type: ACTIONTYPE_HOSPITALSTAFFLIST, value: { hospitalStaffList: message.initialData.hospitalStaff } });
        }
      }
      dispatch({
        type: ACTIONTYPE_PATIENTLIST_COUNT,
        value: message.count,
      });
      if (message.patientInfo.length > 0) {
        setShowStatus(message.patientInfo[0].status !== undefined);
        setShowLocation(message.patientInfo[0].location !== undefined);
        // setShowSigknowTag(message.patientInfo[0].sigknowTag !== undefined);
        message.patientInfo.map((item, index) => {
          dispatch({
            type: ACTIONTYPE_PATIENTLIST_ADD, value: {
              listContentIndex: index,
              listContentItem: createData(item, message.locations, message.tags, message.sigknowTags)
            }
          });
        });
      } else {
        console.log('patient info is empty');
      }
      if (message.sigknowTags !== undefined) {
        setNewRecordTags(message.sigknowTags);
      } else if (message.tags !== undefined) {
        setNewRecordTags(message.tags.length > 0 ? message.tags[0] : "");
      }
      setIsSigknow(!!message.isSigknow)
    } else {
      console.log("message is empty");
    }
  };

  function handleDeleteRequest(deletes) {
    Promise.all(deletes.map((uuid) => {
      return axiosService.delete(
        '/patientdetail/content/' + uuid,
        {
          headers: {
            'Authorization': 'Bearer ' + Cookies.get('apiKey'),
            'X-Socket-ID': socketId
          }
        });
    })).then((responses) => {
      var isSuccess = true;
      for(const resp of responses) {
        console.log(resp);
        if(isSuccess)
          successHandler(resp)
        if(resp.status !== 200){
          isSuccess = false;
          break;
        } else if(resp.data.error !== 0) {
          isSuccess = false;
          break;
        }
      }

      if(!isSuccess){
        dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'patientList.deleteFailed' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top' } } });
      } else {
        setSelected([]);
        queryContentFromServer();
      }
    });
  }

  function handleRefreshRequest() {
    if (queryContent) {
      handleMessage(queryContent({ order, orderby, offset, limit, searchWord }));
    } else {
      queryContentFromServer();
    }
  }

  function handleOnClick(event, uuid) {
    setDetailUuid(uuid);
    setShowPatientDetail(true);
    const target = patientInfoArrayRef.current.find((value) => {
      return value.uuid === uuid;
    });
    if (target !== undefined) {
      dispatch({
        type: ACTIONTYPE_PATIENTLIST_UPDATE, value: {
          ...target,
          hasNewMessage: 0,
          icons: <Icons>
            <MessageButton hasMessage={target.hasMessage} hasNewMessage={0} onClick={e => {
              e.stopPropagation();
              onMessageButtonClick({ uuid: target.uuid, hasSubscribed: target.hasSubscribed, boardEditable: target.boardEditable });
            }} />
            <EmergentIcon emergentNoteOrigin={target.emergentNoteOrigin} />
            <UrgentIcon isUrgent={target.isUrgent} />
          </Icons>,
          isUpdated: false
        }
      });
    }
  }

  useEffect(useCallback(() => {
    if(detailUuid !== ''){
      const target = patientInfoArrayRef.current.find((value) => {
        return value.uuid === detailUuid;
      });
      if (target !== undefined) {
        console.log("set hasSubscribed: " + target.hasSubscribed + ", " + enableChatNotify);
        dispatch({
          type: ACTIONTYPE_PATIENTLIST_UPDATE, value: {
            ...target,
            hasSubscribed: enableChatNotify,
          }
        });
      }
    }
  }), [enableChatNotify]);

  useEffect(() => {
    if (Cookies.get('username') && Cookies.get('apiKey') && socketId && socketId.length !== 0) {
      if (queryContent) {
        handleMessage(queryContent({ order, orderby, offset, limit, searchWord }));
      } else {
        queryContentFromServer();
      }
    } else {
      if (!Cookies.get('username'))
        console.warn('username is empty');
      if (!Cookies.get('apiKey'))
        console.warn('apiKey is empty');
      if (!socketId)
        console.warn('socketId is empty');
      else if(socketId.length === 0)
        console.warn('socketId length is 0');
    }
  }, [order, orderby, offset, limit, searchWord, socketId]);

  useEffect(() => {

    window.addEventListener('focus', onFocus, { capture: true });
    window.addEventListener('blur', onBlur, { capture: true });
    let dialog = document.getElementById("detailDialog");
    if (!!dialog && showMessenger) {
      dialog.scrollTop = dialog.scrollHeight;
    }
    if(createEchoChannel) {
      createEchoChannel();
    } else {
      if (!(Cookies.get('username') && Cookies.get('apiKey'))) {
        dispatch({ type: ACTIONTYPE_SETTING_TO_LOGIN, value: { toLogin: true, } });
      }

      /****
       * navigator.deviceMemory is only available in Edge & Chrome.
       * Please see: https://developer.mozilla.org/en-US/docs/Web/API/Navigator/deviceMemory#browser_compatibility
       */
      if (navigator.deviceMemory && navigator.deviceMemory < 8) {
        dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'patientList.memoryNotEnough' }, { br: <br /> }), timeout: 5000, color: 'warning', anchorOrigin: { horizontal: 'center', vertical: 'top' } } });
      } else  if(!!!navigator.deviceMemory){
        console.warn("navigator.deviceMemory is not supportted in current browser.");
      }

      if (window.Echo === undefined || window.Echo === null) {
        window.Echo = new Echo({
          broadcaster: 'socket.io',
          host: window.location.protocol + '//' + window.location.hostname,
          transports: ['websocket'], // Fix CORS error!
          enabledTransports: ['ws', 'wss'],
          client: io,
          authEndpoint: '/webanalyzer/v1/broadcasting/auth',
          // As I'm using JWT tokens, I need to manually set up the headers.
          auth: {
            headers: {
              //AccessControlAllowOrigin: 'https://' + window.location.hostname,
              Authorization: 'Bearer ' + Cookies.get('apiKey'),
              Accept: 'application/json',
            },
          },
        });

        window.Echo.connector.socket.on('connect', () => {
          setSocketId(window.Echo.socketId());
          console.log("socketid", window.Echo.socketId());
        });
      }

      window.Echo.private('UserNotificationChannel-' + Cookies.get('username'))
      .listen('UserNotification', (e) => {
        console.log("receive user login notification!");
        console.log(e);
        const msg = JSON.parse(e.data);
        if (msg.type === 'forcelogout' && Cookies.get('username') === msg.userId && msg.socketId !== window.Echo.socketId()) {
          dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'login.alreadyLogin' }, { ip: msg.source }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top'}},
            onClose: dispatch({ type: ACTIONTYPE_SETTING_TO_LOGIN, value: { toLogin: true } }) });

          Cookies.remove('username', { path: '/', secure: true });
          Cookies.remove('apiKey', { path: '/', secure: true });
          Cookies.remove('uuid', { path: '/', secure: true });

          // clear patient list
          dispatch({
            type: ACTIONTYPE_PATIENTLIST_REMOVEALL, value: {
              listContent: [],
            }
          });
          dispatch({
            type: ACTIONTYPE_PATIENTLIST_COUNT,
            value: 0,
          });

          // clear init data
          dispatch({ type: ACTIONTYPE_ANALYSTLIST, value: { analystList: [] } });
          dispatch({ type: ACTIONTYPE_PHYSICIANLIST, value: { physicianList: [] } });
          dispatch({ type: ACTIONTYPE_LOCATIONLIST, value: { locationList: [] } });
          dispatch({ type: ACTIONTYPE_HOSPITALSTAFFLIST, value: { hospitalStaffList: [] } });
        } else if (msg.type === 'message'){
          console.log('lang: '+lang);
          console.log(JSON.parse(msg.message));
          console.log(msg.anchor.horizontal);
          console.log(msg.anchor.vertical);
          if (isFocusRef.current) {
            console.log('focus, show message');
            dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: <div dangerouslySetInnerHTML={{ __html: JSON.parse(msg.message)[lang] }}/>, timeout: msg.duration, color: msg.color, anchorOrigin: { horizontal: msg.anchor.horizontal, vertical: msg.anchor.vertical } } });
            console.log('messageId: ' + msg.messageId);
            axiosService.post(
              '/notification',
              {
                messageId: msg.messageId,
              },
              {
                headers: {
                  'Authorization': 'Bearer ' + Cookies.get('apiKey'),
                  'X-Socket-ID': window.Echo ? window.Echo.socketId() : ''
                }
              }).then(response => {
                console.log(response.data);
                successHandler(response);
              }).catch(error => {
                errorHandler(error);
              });
          } else {
            console.log('not focus, skip message');
          }
        }
      });

      window.Echo.private('UserChannel-'+Cookies.get('username'))
        .listen('UpdateData', (e) => {

          const getNewRow = (msg, target) => {
            var newRowValue = { ...target };

            console.log('getNewRow', target, msg);
            newRowValue.updateTimeComponent = msg.updateTime ? <TimeComponent timeString={msg.updateTime} lang={lang} /> : target.updateTimeComponent;
            if (msg.patientInfo) {
              if (msg.patientInfo.sex !== undefined) {
                let sex = typeof msg.patientInfo.sex === 'number' ? msg.patientInfo.sex : parseInt(msg.patientInfo.sex);
                newRowValue.sex = sex === 0 ?
                  intl.formatMessage({ id: 'patientList.male' }) :
                  sex === 1 ? intl.formatMessage({ id: 'patientList.female' }) :
                    intl.formatMessage({ id: 'patientList.unknown' });
              }
              newRowValue.patchId = target.patchId;
              newRowValue.deviceId = target.deviceId;
              newRowValue.checkId = msg.patientInfo.checkId ? msg.patientInfo.checkId : target.checkId;
              newRowValue.name = msg.patientInfo.name ? msg.patientInfo.name : target.name;
              if (msg.patientInfo.age !== undefined) {
                let age = typeof msg.patientInfo.age === 'number' ? msg.patientInfo.age : parseInt(msg.patientInfo.age);
                newRowValue.age = age === 0 ? intl.formatMessage({ id: 'patientDetail.info.unknown' }) : age;
              }
              newRowValue.patientId = msg.patientInfo.patientId ? msg.patientInfo.patientId : target.patientId;
              newRowValue.physician = msg.patientInfo.physician ? msg.patientInfo.physician : target.physician;
              newRowValue.status = msg.patientInfo.status ? msg.patientInfo.status : target.status;
              newRowValue.location = msg.patientInfo.location ? msg.patientInfo.location.Name : target.location;
              console.log('msg.patientInfo.isUrgent',msg.patientInfo.isUrgent);
              if(msg.patientInfo.isUrgent !== undefined) {
                newRowValue.isUrgent = msg.patientInfo.isUrgent;
                newRowValue.icons = <Icons>
                  <MessageButton hasMessage={newRowValue.hasMessage} hasNewMessage={newRowValue.hasNewMessage} onClick={e => {
                    e.stopPropagation();
                    onMessageButtonClick({ uuid: newRowValue.uuid, hasSubscribed: newRowValue.hasSubscribed, boardEditable: newRowValue.boardEditable });
                  }} />
                  <EmergentIcon emergentNoteOrigin={newRowValue.emergentNoteOrigin} />
                  <UrgentIcon isUrgent={msg.patientInfo.isUrgent} />
                </Icons>;
              }
            }
            if (msg.reportInfo) {
              newRowValue.status = msg.reportInfo.status ? msg.reportInfo.status : target.status;
            }
            if (msg.emergentNote) {
              newRowValue.emergentNoteOrigin = msg.emergentNote.isEmergency === undefined ? target.emergentNoteOrigin : msg.emergentNote.isEmergency;
              newRowValue.icons = <Icons>
                <MessageButton hasMessage={newRowValue.hasMessage} hasNewMessage={newRowValue.hasNewMessage} onClick={e => {
                  e.stopPropagation();
                  onMessageButtonClick({ uuid: newRowValue.uuid, hasSubscribed: newRowValue.hasSubscribed, boardEditable: newRowValue.boardEditable });
                }} />
                <EmergentIcon emergentNoteOrigin={newRowValue.emergentNoteOrigin} />
                <UrgentIcon isUrgent={newRowValue.isUrgent} />
              </Icons>;
            }
            if (msg.history) {
              const targetJson = JSON.parse(JSON.stringify(target));
              if (msg.history.type.includes('signal')) {
                newRowValue.signalComponent = <FileComp identifier="list" type={'signal'} fileType={'.bin'} canUpload={targetJson.signalComponent.props.canUpload} canDownload={targetJson.signalComponent.props.canDownload} uuid={targetJson.signalComponent.props.uuid} deviceId={newRowValue.deviceId} patchId={newRowValue.patchId} inputFileName={msg.history.name} inputFileSize={msg.history.size} onUploaded={() => console.log('patient list onUploaded')} onDownloaded={() => { }} prePathname={"/file/request"} postPathname={"/file/request"}></FileComp>;
              } else if (msg.history.type.includes('report')) {
                newRowValue.reportComponent = <FileComp identifier="list" type={'report'} fileType={'.pdf,.doc,.docx'} canUpload={targetJson.reportComponent.props.canUpload} canDownload={targetJson.reportComponent.props.canDownload} canOpenWebViewer={targetJson.reportComponent.props.canOpenWebViewer} canOpenPdfViewer={targetJson.reportComponent.props.canOpenPdfViewer} canEditComment={targetJson.reportComponent.props.canEditComment} uuid={newRowValue.uuid} deviceId={newRowValue.deviceId} patchId={newRowValue.patchId} inputFileName={msg.history.name} signalName={targetJson.reportComponent.props.signalName} inputFileSize={msg.history.size} onUploaded={() => { }} onDownloaded={() => { }} setDialogComponent={setDialogComponent} prePathname={"/file/request"} postPathname={"/file/request"} isCommentModified={targetJson.reportComponent.props.isCommentModified}></FileComp>;
              }
            }
            if(msg.comment) {
              const targetJson = JSON.parse(JSON.stringify(target));
              if (msg.comment.isModified) {
                newRowValue.reportComponent = <FileComp identifier="list" type={'report'} fileType={'.pdf,.doc,.docx'} canUpload={targetJson.reportComponent.props.canUpload} canDownload={targetJson.reportComponent.props.canDownload} canOpenWebViewer={targetJson.reportComponent.props.canOpenWebViewer} canOpenPdfViewer={targetJson.reportComponent.props.canOpenPdfViewer} canEditComment={targetJson.reportComponent.props.canEditComment} uuid={newRowValue.uuid} deviceId={newRowValue.deviceId} patchId={newRowValue.patchId} inputFileName={targetJson.reportComponent.props.inputFileName} signalName={targetJson.reportComponent.props.signalName} inputFileSize={targetJson.reportComponent.props.inputFileSize} onUploaded={() => { }} onDownloaded={() => { }} setDialogComponent={setDialogComponent} prePathname={"/file/request"} postPathname={"/file/request"} isCommentModified={true}></FileComp>;
              } else {
                newRowValue.reportComponent = <FileComp identifier="list" type={'report'} fileType={'.pdf,.doc,.docx'} canUpload={targetJson.reportComponent.props.canUpload} canDownload={targetJson.reportComponent.props.canDownload} canOpenWebViewer={targetJson.reportComponent.props.canOpenWebViewer} canOpenPdfViewer={targetJson.reportComponent.props.canOpenPdfViewer} canEditComment={targetJson.reportComponent.props.canEditComment} uuid={newRowValue.uuid} deviceId={newRowValue.deviceId} patchId={newRowValue.patchId} inputFileName={targetJson.reportComponent.props.inputFileName} signalName={targetJson.reportComponent.props.signalName} inputFileSize={targetJson.reportComponent.props.inputFileSize} onUploaded={() => { }} onDownloaded={() => { }} setDialogComponent={setDialogComponent} prePathname={"/file/request"} postPathname={"/file/request"} isCommentModified={false}></FileComp>;
              }
            }
            if (msg.chats) {
              console.log("update chats")
              console.log(JSON.stringify(msg))
              newRowValue.hasMessage = true
              newRowValue.hasNewMessage = msg.chats.unreadCount;
              newRowValue.icons = <Icons>
                <MessageButton hasMessage={true} hasNewMessage={newRowValue.hasNewMessage} onClick={e => {
                  e.stopPropagation();
                  onMessageButtonClick({ uuid: newRowValue.uuid, hasSubscribed: newRowValue.hasSubscribed, boardEditable: newRowValue.boardEditable });
                }} />
                <EmergentIcon emergentNoteOrigin={newRowValue.emergentNoteOrigin} />
                <UrgentIcon isUrgent={newRowValue.isUrgent} />
              </Icons>;
            }
            
            return newRowValue;
          };
          try{
            console.log('receive UpdateData event');
            console.log(e);
            const msg = JSON.parse(e.data);
            const target = patientInfoArrayRef.current.find((value) => {
              return value.uuid === msg.uuid;
            });
            // Update patient list
            if (target !== undefined && msg.socket !== window.Echo.socketId()){
              console.log("patientlist target: " + JSON.stringify(target))
              let newRowValue = getNewRow(msg, target);
              
              console.log("patientlist newRowValue: " + JSON.stringify(newRowValue))
              dispatch({
                type: ACTIONTYPE_PATIENTLIST_UPDATE, value: {
                  ...newRowValue,
                  isUpdated: true
                }
              });
            }

            const newPatientIdx = newPatientInfoArrayRef.current.findIndex((value) => {
              return value.uuid === msg.uuid;
            });
            if (newPatientIdx !== -1) {
              console.log(newPatientInfoArrayRef.current);
              let newRowValue = getNewRow(msg, newPatientInfoArrayRef.current[newPatientIdx]);
              setNewPatientInfoArray(newPatientInfoArrayRef.current.map((value, index) => {
                return index === newPatientIdx ? newRowValue : value;
              }));
            }

            // Show notify update in patient detatil
            if (detailUuidRef.current === msg.uuid && msg.socket !== window.Echo.socketId()) {
              dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'patientDetail.updateData' }), timeout: 4000, color: 'warning', anchorOrigin: { horizontal: 'center', vertical: 'top' } } });
            }
          }catch(e){
            console.error(e);
          }
        })
        .listen('NewData', (e) => {
          try {
            console.log('receive NewData event');
            console.log(e);
            const msg = JSON.parse(e.data);
            let rowData = {};
            rowData.uuid = msg.uuid;
            rowData.hasMessage = false;
            rowData.hasSubscribed = msg.hasSubscribed;
            rowData.emergentNoteOrigin = undefined;
            rowData.boardEditable = msg.boardEditable;
            rowData.icons = <Icons>
              <MessageButton hasMessage={false} hasNewMessage={0} onClick={e => {
                e.stopPropagation();
                onMessageButtonClick({ uuid: msg.uuid, hasSubscribed: msg.hasSubscribed, boardEditable: msg.boardEditable });
              }} />
              <EmergentIcon emergentNoteOrigin={undefined} />
              <UrgentIcon isUrgent={undefined}/>
            </Icons>;

            if (msg.patientInfo) {
              rowData.patchId = msg.patientInfo.patch;
              rowData.deviceId = msg.patientInfo.device.DeviceId;
              rowData.checkId = msg.patientInfo.checkId;
              rowData.name = msg.patientInfo.name;
              rowData.sex = msg.patientInfo.sex === 0 ?
                intl.formatMessage({ id: 'patientList.male' }) :
                msg.patientInfo.sex === 1 ? intl.formatMessage({ id: 'patientList.female' }) :
                  intl.formatMessage({ id: 'patientList.unknown' });
              rowData.age = msg.patientInfo.age === 0 ? intl.formatMessage({ id: 'patientDetail.info.unknown' }) : msg.patientInfo.age;
              rowData.patientId = msg.patientInfo.patientId;
              rowData.physician = msg.patientInfo.physicianName;
              rowData.location = msg.patientInfo.location.Name;
              if(msg.patientInfo.isUrgent !== undefined) {
                rowData.icons = <Icons>
                  <MessageButton hasMessage={false} hasNewMessage={0} onClick={e => {
                    e.stopPropagation();
                    onMessageButtonClick({ uuid: msg.uuid, hasSubscribed: msg.hasSubscribed, boardEditable: msg.boardEditable });
                  }} />
                  <EmergentIcon emergentNoteOrigin={undefined} />
                  <UrgentIcon isUrgent={msg.patientInfo.isUrgent} />
                </Icons>;
              }
            }
            rowData.status = msg.status;
            rowData.signalComponent = <FileComp identifier="list" type={'signal'} fileType={'.bin'} canUpload={msg.canUploadSignal} canDownload={msg.canDownloadSignal} uuid={msg.uuid} deviceId={rowData.deviceId} patchId={rowData.patchId} inputFileName={msg.signalName} inputFileSize={msg.signalSize} onUploaded={() => console.log('patient list onUploaded')} onDownloaded={() => { }} prePathname={"/file/request"} postPathname={"/file/request"}></FileComp>;
            rowData.reportComponent = <FileComp identifier="list" type={'report'} fileType={'.pdf,.doc,.docx'} canUpload={msg.canUploadReport} canDownload={msg.canDownloadReport} canOpenWebViewer={msg.canOpenWebViewer} canOpenPdfViewer={msg.canOpenPdfViewer} canEditComment={msg.canEditComment} uuid={msg.uuid} deviceId={rowData.deviceId} patchId={rowData.patchId} inputFileName={msg.reportName} signalName={msg.signalName} inputFileSize={msg.reportSize} onUploaded={() => { }} onDownloaded={() => { }} setDialogComponent={setDialogComponent} prePathname={"/file/request"} postPathname={"/file/request"}></FileComp>;
            rowData.updateTimeComponent = <TimeComponent timeString={msg.updateTime} lang={lang}/>;
            rowData.createTimeComponent = <TimeComponent timeString={msg.createTime} lang={lang}/>;
            rowData.isUpdated = false;
            const newArray = [rowData].concat(newPatientInfoArrayRef.current)
            console.log(newArray);
            setNewPatientInfoArray(newArray);
          }catch(e){
            console.error(e);
          }
        });

      // window.Echo.private('PatientListChannel')
      //   .listen('PatientList', (e) => {
      //     console.log(e);
      //     const msg = JSON.parse(e.data);
      //     if(msg.type === 'chat') {
      //       const target = patientInfoArrayRef.current.find((value) => {
      //         return value.uuid === msg.message.chatUuid;
      //       });
      //       if (target !== undefined) {
      //         console.log("patientlist target: " + JSON.stringify(target))
      //         console.log("msg name: " + msg.message.name + ", cookie: " + Cookies.get('username'));
      //         if (!showMessengerRef.current || detailUuidRef.current !== msg.message.chatUuid) {
      //           updateMessageNotification(target, msg.message.name !== Cookies.get('username'));
      //         }
      //       }
      //     } else if(msg.type === 'upload') {
      //       console.log('upload:');
      //       console.log('uuid: '+msg.message.uuid);
      //       console.log('serviceId: ' + msg.message.serviceId);
      //       console.log('name: ' + msg.message.name);
      //       console.log('size: ' + msg.message.size);
      //       const target = patientInfoArrayRef.current.find((value) => {
      //         return value.uuid === msg.message.uuid;
      //       });
      //       // Update patient list
      //       if (target !== undefined) {
      //         console.log("patientlist target: " + JSON.stringify(target))
      //         console.log("msg name: " + msg.message.name + ", cookie: " + Cookies.get('username'));
      //         dispatch({
      //           type: ACTIONTYPE_PATIENTLIST_UPDATE, value: {
      //             ...target,
      //             patchId: msg.message.serviceId
      //           }
      //         });
      //       }
      //     }
      //   });
      return () => {
        // console.log("patientList exit!");
        // window.Echo.private('PatientListChannel').stopListening('PatientList');
        console.log("UserChannel exit!");
        window.Echo.private('UserChannel-' + Cookies.get('username')).stopListening('UpdateData');

        console.log("UserNotificationChannel exit!");
        window.Echo.private('UserNotificationChannel-' + Cookies.get('username')).stopListening('UserNotification');
        // Cookies.remove('username', { path: '/', secure: true });
        window.removeEventListener('focus', onFocus);
        window.removeEventListener('blur', onBlur);
      };
    }
  }, []);

  // const updateMessageNotification = (target, isShowNotification) => {
  //   if (target.hasSubscribed) {
  //     console.log("set notification: " + target.emergentNoteOrigin+", "+isShowNotification+", "+target.boardEditable);
  //     dispatch({
  //       type: ACTIONTYPE_PATIENTLIST_UPDATE, value: {
  //         ...target,
  //         icons: <Icons>
  //           <MessageButton hasNewMessage={isShowNotification} boardEditable={target.boardEditable} onClick={e => {
  //             onMessageButtonClick({ uuid: target.uuid, hasSubscribed: isShowNotification, boardEditable: target.boardEditable });
  //             e.stopPropagation();
  //           }} />
  //           <EmergentIcon emergentNoteOrigin={target.emergentNoteOrigin} />
  //           <UrgentIcon isUrgent={target.isUrgent} />
  //         </Icons>
  //       }
  //     });
  //   }
  // }

  return (
    <div>
      <div>{dialogComponent}</div>
      {showPatientDetail || showMessenger ? 
        <Suspense fallback={<div></div>}>
          <PatientDetail uuid={detailUuid} isNewRecord={detailUuid === ''} onClose={handleCloseDetail}
            tags={newRecordTags}
            isOnSubmit={isOnSubmit}
            setIsOnSubmit={setIsOnSubmit}
            handleRefreshRequest={handleRefreshRequest}
            offset={offset}
            limit={limit}
            order={order}
            orderby={orderby}
            selected={selected}
            setIsDetailModified={setIsDetailModified}
            pathname={"/patientdetail/content"} />
        </Suspense>
      // <Dialog open fullWidth maxWidth={"md"}
      //   onClose={handleCloseDetail} aria-labelledby="form-dialog-title">
      //   <DialogContent id={"detailDialog"}>
      //     <Suspense fallback={<div></div>}>
      //       <PatientDetail uuid={detailUuid} isNewRecord={detailUuid === ''} onClose={handleCloseDetail}
      //         tags={newRecordTags}
      //         isOnSubmit={isOnSubmit}
      //         setIsOnSubmit={setIsOnSubmit}
      //         handleRefreshRequest={handleRefreshRequest}
      //         offset={offset}
      //         limit={limit}
      //         order={order}
      //         orderby={orderby}
      //         selected={selected}
      //         setIsDetailModified={setIsDetailModified}
      //         detailGet={detailGet}
      //         detailCreate={detailCreate}
      //         detailUpdate={detailUpdate}
      //         uploadHandler={uploadHandler}
      //         downloadHandler={downloadHandler}/>
      //     </Suspense>
      //   </DialogContent>
      //   <DialogActions>
      //     <PatientDetailCancel onClose={handleCloseDetail} />
      //     <PatientDetailConfirm isNewRecord={detailUuid === ''} submit={e => {
      //       setIsOnSubmit(true);
      //     }} />
      //   </DialogActions>
      // </Dialog>
      : null}
      <Popover
        open={openConfirmDialog}
        anchorEl={this}
        onClose={() => {
          setOpenConfirmDialog(false);
        }}
        anchorOrigin={{
          vertical: 'middle',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{ classes: { root: classes.paper } }}
      >
        <Grid container style={{ maxWidth: '400px' }}>
          <Grid xs={12} item style={{ margin: '20px', display: 'flex' }}>
            <Warning style={{ ...styles.icons, color: "#FFCC00" }}/>
            <Typography variant="h6" style={{ paddingLeft: "10px", display: "flex", justifyContent: "center" }}>
              <FormattedMessage id={'patientDetail.confirmDiscardTitle'} />
            </Typography>
          </Grid>
          <Grid xs={12} item style={{margin: '10px'}}>
            <Typography variant="body1" style={{ display: "flex", justifyContent: "center" }}>
              <FormattedMessage id={'patientDetail.confirmDiscard'} />
            </Typography>
          </Grid>
          <Grid xs={12} item style={{direction: "rtl", marginRight: "10px"}}>
            <Button color="primary" onClick={() => {
              setOpenConfirmDialog(false);
              setShowPatientDetail(false);
              setIsDetailModified(false);
              setDetailUuid('');
            }}>
              <FormattedMessage id={'patientList.confirm' }/>
            </Button>
            <Button onClick={() => {
              setOpenConfirmDialog(false);
            }}>
              <FormattedMessage id={'patientDetail.cancel'} />
            </Button>
          </Grid>
        </Grid>
      </Popover>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>
                <FormattedMessage id="routes.patientList" />
              </h4>
              <p className={classes.cardCategoryWhite}></p>
            </CardHeader>
            <CardBody>
              <EnhancedTable
                isSigknow={isSigknow}
                role={role}
                offset={offset}
                setOffset={setOffset}
                limit={limit}
                setLimit={setLimit}
                order={order}
                setOrder={setOrder}
                orderby={orderby}
                setOrderby={setOrderby}
                selected={selected}
                setSelected={setSelected}
                defaultRowsPerPage={limit}
                searchWord={searchWord}
                setSearchWord={setSearchWord}
                filterList={filterList}
                setFilterList={setFilterList}
                locationList={locationList}
                count={count}
                handleDeleteRequest={handleDeleteRequest}
                handleRefreshRequest={handleRefreshRequest}
                handleNewRecord={handleAddNewPatient}
                tableHeaderColor="primary"
                handleOnClick={handleOnClick}
                tableHead={[
                  /**
                   * The id field is also the identity for sorting feature, if the id field is changed, backend also
                   * needs to be sync of parsing sorting identity.
                   * Refer to app\Http\Controllers\webanalyzer\v1\patientlist\contentController.php.
                   */
                  { id: 'icons', numeric: false, disablePadding: true, label: null, sortable: false, canFilter: false },
                  { id: 'patchId', numeric: false, disablePadding: true, label: intl.formatMessage({ id: 'patientList.patchId' }), sortable: true, canFilter: false },
                  { id: 'deviceId', numeric: false, disablePadding: true, label: intl.formatMessage({ id: 'patientList.deviceId' }), sortable: true, canFilter: false },
                  { id: 'checkId', numeric: false, disablePadding: true, label: intl.formatMessage({ id: 'patientList.checkId' }), sortable: true, canFilter: false },
                  { id: 'name', numeric: false, disablePadding: true, label: intl.formatMessage({ id: 'patientList.name' }), sortable: true, canFilter: false },
                  // { id: 'tag', numeric: false, disablePadding: true, label: intl.formatMessage({ id: 'patientList.tag' }), sortable: true, canFilter: false },
                  { id: 'sex', numeric: false, disablePadding: true, label: intl.formatMessage({ id: 'patientList.sex' }), sortable: true, canFilter: true },
                  { id: 'age', numeric: true, disablePadding: true, label: intl.formatMessage({ id: 'patientList.age' }), sortable: true, canFilter: true },
                  { id: 'patientId', numeric: false, disablePadding: true, label: intl.formatMessage({ id: 'patientList.patientId' }), sortable: true, canFilter: false },
                  { id: 'physician', numeric: false, disablePadding: true, label: intl.formatMessage({ id: 'patientList.physician' }), sortable: true, canFilter: false },
                  { id: 'signal', numeric: false, disablePadding: true, label: intl.formatMessage({ id: 'patientList.signal' }), sortable: true, canFilter: false },
                  { id: 'report', numeric: false, disablePadding: true, label: intl.formatMessage({ id: 'patientList.report' }), sortable: true, canFilter: false },
                  { id: 'updateTime', numeric: false, disablePadding: true, label: intl.formatMessage({ id: 'patientList.update' }), sortable: true, canFilter: false },
                  { id: 'createTime', numeric: false, disablePadding: true, label: intl.formatMessage({ id: 'patientList.create' }), sortable: true, canFilter: false },
                  showStatus ? { id: 'status', numeric: false, disablePadding: true, label: intl.formatMessage({ id: 'patientList.status' }), sortable: true, canFilter: true } : null,
                  showLocation ? { id: 'location', numeric: false, disablePadding: true, label: intl.formatMessage({ id: 'patientList.location' }), sortable: true, canFilter: true } : null,
                  // showSigknowTag ? { id: 'sigknowTag', numeric: false, disablePadding: true, label: intl.formatMessage({ id: 'patientList.sigknowTag' }), sortable: true, canFilter: false, fontStyle: 'italic' } : null,
                ]}

                tableData={patientInfoArray}
                newData={newPatientInfoArray}
                setNewData={setNewPatientInfoArray}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

PatientList.propTypes = {
  classes: PropTypes.object.isRequired,
  queryContent: PropTypes.func,
  createEchoChannel: PropTypes.func,
};

PatientList.defaultProps = {
  /***
   * TODO: Set default value for real environment
   */
  queryContent: undefined,
  createEchoChannel: undefined,
};

export default withStyles(dashboardStyle)(PatientList);
