import React, { useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import HistoryIcon from '@material-ui/icons/History';
import Typography from '@material-ui/core/Typography';
import { Grid } from "../PatientDetail/PatientDetail";
import Popover from '@material-ui/core/Popover';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { humanFileSize } from "../../components/Azure/Utilities";
import {TimeComponent} from "../PatientList/PatientList";
import FileComp from "components/FileComponent/FileComp";
import { useMappedState } from "redux-react-hook";

const useStyles = makeStyles((/*theme*/) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  paper: {
    width: 500,
  },

  fileField: {
    marginLeft: 2,
    marginRight: 2,
    width: "90%",
  }
}));

function FileHistory(props) {

  const { signalHistory, history, historySetter, title, type, fileType, canUpload, canDownload, canOpenWebViewer, canOpenPdfViewer, canEditComment, uuid, deviceId, patchId, setDialogComponent, isDemo, onUploaded, onDownloaded, isCommentModified } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const lang = useMappedState(useCallback(state => state.setting.language));

  const [inputFileName, setInputFileName] = React.useState(undefined);
  const [inputFileSize, setInputFileSize] = React.useState(undefined);
  const [signalName, setSignalName] = React.useState(undefined);

  useEffect(() => {
    if (history && history.length > 0) {
      setInputFileName(history[history.length - 1].name);
      setInputFileSize(history[history.length - 1].size);
    } else {
      setInputFileName(undefined);
      setInputFileSize(undefined);
    }
  }, [history]);

  useEffect(() => {
    if (signalHistory && signalHistory.length > 0)
      setSignalName(signalHistory[signalHistory.length - 1].name);
  }, [signalHistory]);

  const open = Boolean(anchorEl);
  // const id = open ? 'simple-popover' : undefined;
  const anchorRefEl = useRef(null);

  const handleClick = () => {
    if(history.length > 0) {
      setAnchorEl(anchorRefEl.current);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function onDownloadedCB(response, { filename, filesize, time}) {
    if(onDownloaded && typeof onDownloaded === 'function') {
      onDownloaded(response, {
        filename: filename,
        filesize: filesize,
        time: time,
      });
    }
  }

  function onUploadedCB(response, { filename, filesize, time }) {
    console.log(response);
    if (onUploaded && typeof onUploaded === 'function') {
      onUploaded(response, {
        uuid: uuid,
        type: type,
        filename: filename,
        filesize: filesize,
      });
    }
    historySetter(oldArray => [...oldArray, {name: filename, size: filesize, time: time}]);
  }

  return (
    <div>
      <Grid xs={12} container>
        <Grid ref={anchorRefEl} xs={12} container>
          <FormControl style={{ marginTop: '16px', width: '100%' }} className={classes.fileField}>
            <InputLabel htmlFor={"history-name-"+type} shrink={true}>{title}</InputLabel>
            <Grid xs={12} container style={{ display: "inline-flex" }} id={"history-name-"+type}>
              <Grid xs={5} item style={{ alignSelf: "center" }} >
                <Typography variant="body1">
                  {history && history.length !== 0 ? history[history.length-1].name : "---"}
                </Typography>
              </Grid>
              <Grid xs={2} item style={{ alignSelf: "center" }}>
                <Typography variant="body1">
                  {history && history.length !== 0 ? humanFileSize(history[history.length - 1].size, true) : "---"}
                </Typography>
              </Grid>
              <Grid xs={1} item style={{ alignSelf: "center" }}>
                <Typography variant="body1">
                  {history && history.length !== 0 ? <TimeComponent timeString={(history[history.length - 1].time)} lang={lang}/> : "---"}
                </Typography>
              </Grid>
              <Grid xs={3} item style={{ alignSelf: "center" }}>
                <FileComp isDemo={isDemo} identifier="fileHistory" type={type} fileType={fileType} canUpload={canUpload} canDownload={canDownload} canOpenWebViewer={canOpenWebViewer} canOpenPdfViewer={canOpenPdfViewer} canEditComment={canEditComment} uuid={uuid} deviceId={deviceId} patchId={patchId} inputFileName={inputFileName} signalName={signalName} inputFileSize={inputFileSize} onUploaded={onUploadedCB} onDownloaded={onDownloadedCB} setDialogComponent={setDialogComponent} prePathname={isDemo ? "/demo/file" : "/file/request"} postPathname={isDemo ? undefined : "/file/request"} isCommentModified={isCommentModified} />
              </Grid>
              <Grid xs={1} item style={{ alignSelf: "center" }}>
                <IconButton onClick={handleClick}>
                  <HistoryIcon></HistoryIcon>
                </IconButton>
              </Grid>
            </Grid>
          </FormControl>
          </Grid>
        <Grid xs={1} item ></Grid>
      </Grid>
      
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{ classes: { root: classes.paper } }}
      >
        <List>{history ?
          (history.map((value, index) => {
            return (<ListItem key={index}>
              <Grid container>
                <Grid xs={4} item>
                  <Typography variant="body1" style={{ display: "flex", justifyContent: "center" }}>
                    {value.name}
                  </Typography>
                </Grid>
                <Grid xs={4} item>
                  <Typography variant="body1" style={{ display: "flex", justifyContent: "center" }}>
                    {humanFileSize(value.size, true)}
                  </Typography>
                </Grid>
                <Grid xs={4} item>
                  <Typography variant="body1" style={{ display: "flex", justifyContent: "center" }}>
                    <TimeComponent timeString={value.time} lang={lang}/>
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>);
          }
          ))
          : null}
        </List>
      </Popover>
    </div>
  );
}

FileHistory.defaultProps={
  isCommentModified: false,
  isDemo: false,
}

FileHistory.propTypes={
  signalHistory: PropTypes.array,
  history: PropTypes.arrayOf('string').isRequired,
  historySetter: PropTypes.func,
  title: PropTypes.string,
  type: PropTypes.string,
  fileType: PropTypes.string,
  canUpload: PropTypes.bool,
  canDownload: PropTypes.bool,
  canOpenWebViewer: PropTypes.bool,
  canOpenPdfViewer: PropTypes.bool,
  canEditComment: PropTypes.bool,
  uuid: PropTypes.string,
  deviceId: PropTypes.string,
  patchId: PropTypes.string,
  setDialogComponent: PropTypes.func,
  isDemo: PropTypes.bool,
  onUploaded: PropTypes.func,
  onDownloaded: PropTypes.func,
  isCommentModified: PropTypes.bool,
}

export default FileHistory;