const zh = {
  /* routes */
  'routes.login': '登入',
  'routes.patientList': '檢查列表',
  'routes.demoPatientList': '檢查列表DEMO',

  /* header */
  'header.system': 'EZY Connect',

  /* header links */
  'headerLinks.toggleDemo': '進入Demo',
  'headerLinks.leaveDemo': '離開Demo',
  'headerLinks.editSalesPrivilege': '權限',
  'headerLinks.exportQualityDocument': '匯出',
  'headerLinks.addUser': '新增使用者',
  'headerLinks.location': '據點',
  'headerLinks.editAnnouncement': '設定公告',
  'headerLinks.editProfile': '編輯資料',
  'headerLinks.logout': '登出',
  'headerLinks.accept': '接受',
  'headerLinks.cookieConsent': '此網站使用Cookie以增進使用者體驗。關於詳細Cookie使用方式，請見{link}',
  'headerLinks.cookiePolicy': 'Cookie使用政策',
  'headerLinks.privacyTitle': '個資告知事項',
  'headerLinks.privacyContent': '準訊生醫股份有限公司（以下簡稱本公司）依據個人資料保護法(以下簡稱個資法)第八條第一項規定，向您告知事項如下，請務必詳閱：{br}\
一、	非公務機關名稱：準訊生醫股份有限公司{br}\
二、	個人資料蒐集之目的{br}\
本公司為心電圖數據分析使用所需、客戶管理與服務、調查/ 統計與研究分析、資訊與資料庫管理、契約/類似契約關係或其他法律關係事務、其他經營合於營業登記項目或組織章程所定之業務範圍內需要，所為之蒐集、處理及利用。{br}\
三、	個人資料的類別{br}\
姓名、通訊方式、公司資訊、產品類別 以及其他詳如申請表單之內容，並以本公司與客戶往來之相關業務或服務及自客戶或第三人處理所實際蒐集之個人資料為準。{br}\
四、	個人資料利用之期間、地區、對象及方式{br}\
<li>期間：特定目的存續期間、依相關法令所定或因執行業務所必須之保存期間或依個別活動就資料之保存所定之保存年限。（以期限最長者為準）。</li>\
<li>地區：「個人資料利用之對象」其國內及國外所在地。</li>\
<li>對象：本公司(含受本公司委託處理事務之委外機構) 、依法令規定利用之機構、其他業務相關之機構、依法有權機關、客戶所同意之對象。</li>\
<li>方式：以自動化機器或其他非自動化之利用方式。</li>\
五、	個人資料之權利行使{br}\
依據個資法第三條規定，您就本公司保有個人資料得行使下列權利：{br}\
<li>請求查詢或閱覽您的個人資料</li>\
<li>請求製給您個人資料的複製本</li>\
<li>請求補充或更正您的個人資料</li>\
<li>請求停止蒐集、處理或利用您的個人資料</li>\
<li>請求刪除您的個人資料</li>\
六、	您如欲行使上述個資法第三條規定之各項權利，有關如何行使之方式，得向{br}\
<li>本公司客服專線(02-27612577)詢問</li>\
<li>本公司客服信箱(<mailto>contact@sigknow.com.tw</mailto>) 詢問</li>\
七、	您得自由選擇是否提供相關個人資料，惟您若拒絕提供相關個人資料，本公司將無法進行必要之審核及處理作業，致無法提供關服務或無法提供較佳之服務，敬請見諒。{br}\
',
  'headerLinks.privacyCheck': '我已詳閱上述個資告知事項，並同意提供個人資料。',
  'headerLinks.getDevicePlaceholder': '請輸入序號',
  'headerLinks.unableToGetDeviceDays': '無法取得機器資訊，請確認序號是否正確。',
  'headerLinks.deviceDays': '{deviceId}為{days}天版本',

  /* login */
  'login.login': '登入',
  'login.password': '密碼',
  'login.username': '帳號',
  'login.alreadyLogin':'您已從{ip}登入',
  'login.outDateBin':'以下機器已過拆機日，請盡速上傳訊號檔：',
  'login.outDateBinDevices': '{devices}',
  'login.unreadChat': '以下機器有未讀訊息：',
  'login.unreadChatDevices': '{devices}',
  'login.passwordResetRemind': '您的密碼已超過{days}天未更新。我們建議您定時更新密碼，以確保帳戶安全。',
  'login.igotit': '我知道了',
  'login.donotshowagain': '不再顯示',

  /* reset password */
  'resetPassword.firstLogin': '第一次登入須重設密碼',
  'resetPassword.oldPassword': '舊密碼',
  'resetPassword.newPassword': '新密碼',
  'resetPassword.newPasswordConfirm': '新密碼確認',
  'resetPassword.continue': '繼續',
  'resetPassword.passwordResetSuccessful': '重設密碼成功',

  /* patient list */
  'patientList.patchId': '服務序號',
  'patientList.checkId': '檢查號',
  'patientList.name': '姓名',
  'patientList.tag': '標籤',
  'patientList.sex': '性別',
  'patientList.male': '男',
  'patientList.female': '女',
  'patientList.unknown': '未知',
  'patientList.age': '年齡',
  'patientList.patientId': '病歷號',
  'patientList.deviceId': '機器號',
  'patientList.physician': '處方醫生',
  'patientList.signal': '訊號',
  'patientList.report': '報告',
  'patientList.update': '更新',
  'patientList.create': '建立',
  'patientList.status': '狀態',
  'patientList.location': '地點',
  'patientList.sigknowTag': '準訊標籤',
  'patientList.searchExamination': '尋找檢查紀錄',
  'patientList.searchPlaceholder': '尋找',
  'patientList.filter': '過濾資料',
  'patientList.addRecord': '新增資料',
  'patientList.refresh': '重新整理',
  'patientList.delete': '刪除',
  'patientList.emergency': '緊急通報',
  'patientList.newMessage': '有新訊息',
  'patientList.rowsPerPage': '顯示筆數',
  'patientList.displayRows': '第{from}-{to}筆 共{count}筆',
  'patientList.notUploadYet': '尚未上傳',
  'patientList.confirm': '確認',
  'patientList.deleteFailed': '刪除失敗，請稍後再試。',
  'patientList.pdfViewer': '瀏覽報告',
  'patientList.editPdf': '編輯備註',
  'patientList.memoryNotEnough': '親愛的客戶,{br}\
     您的電腦的記憶體少於8G，可能會造成上傳速度較慢或上傳大訊號檔失敗。我們建議您使用至少8G記憶體的電腦進行上傳。',
  'patientList.newPatientDivider': '最近新增檢查資料',

  /* file component */
  'fileComp.confirmPatchTitle': '請輸入服務序號',
  'fileComp.confirmPatch': '服務序號',

  /* patient detail */
  'patientDetail.detail': '資料',
  'patientDetail.conversation': '聯繫客服',
  'patientDetail.newPatient': '新病患',
  'patientDetail.newMessageNotification': '新訊息通知',
  'patientDetail.create': '建立',
  'patientDetail.apply': '套用',
  'patientDetail.cancel': '取消',
  'patientDetail.signal': '訊號',
  'patientDetail.report': '報告',
  'patientDetail.inputMessage': '請輸入訊息',
  'patientDetail.confirmDiscardTitle': '即將離開此頁面',
  'patientDetail.confirmDiscard': '資料已被更新，是否放棄已更新資料並離開？',
  'patientDetail.next': '下一步',
  'patientDetail.previous': '上一步',
  'patientDetail.selectModel': '請選擇機器型號',
  'patientDetail.updateData': '資料已被更新，請重新開啟此頁面以檢視最新內容。',

  /* patient detail - patient info */
  'patientDetail.info.emptyField': '必填資料',
  'patientDetail.info.patch': '服務序號',
  'patientDetail.info.patientId': '病歷號',
  'patientDetail.info.patientIdText': '此序號最長可錄製{days}天',
  'patientDetail.info.notFound': '無此資料',
  'patientDetail.info.name': '姓名',
  'patientDetail.info.sex': '性別',
  'patientDetail.info.male': '男',
  'patientDetail.info.female': '女',
  'patientDetail.info.unknown': '未知',
  'patientDetail.info.physician': '處方醫生',
  'patientDetail.info.interpretedDoctor': '判讀醫生',
  'patientDetail.info.age': '年齡',
  'patientDetail.info.dateOfBirth': '生日',
  'patientDetail.info.phone': '電話',
  'patientDetail.info.identifier': '身分證字號',
  'patientDetail.info.birthday': '生日',
  'patientDetail.info.abnormal': '異常通報',
  'patientDetail.info.abnormal.none': '無',
  'patientDetail.info.abnormal.allergy': '過敏提前拆機',
  'patientDetail.info.abnormal.sweat': '大量出汗脫落',
  'patientDetail.info.abnormal.other': '其他(請描述)',
  'patientDetail.info.comment': '備註',
  'patientDetail.info.address': '住址',
  'patientDetail.info.setupDate': '裝機日',
  'patientDetail.info.dayVersion': '型號',
  'patientDetail.info.days': '{time}天',
  'patientDetail.info.mins': '{time}分鐘',
  'patientDetail.info.pacemaker': 'Pacemaker',
  'patientDetail.info.icd': 'ICD',
  'patientDetail.info.rfca': 'RFCA',
  'patientDetail.info.yes': '有',
  'patientDetail.info.no': '無',
  'patientDetail.info.location': '醫療機構',
  'patientDetail.info.checkId': '檢查號',
  'patientDetail.info.deviceId': '機器號',
  'patientDetail.info.model': '模型',
  'patientDetail.info.indication': '醫囑',
  'patientDetail.info.none': '無',
  'patientDetail.info.other': '其他',
  'patientDetail.info.status.created': '已建立',
  'patientDetail.info.status.signalUploading': '訊號上傳中',
  'patientDetail.info.status.signalFailed': '訊號上傳失敗',
  'patientDetail.info.status.signalSuccessed': '訊號上傳成功',
  'patientDetail.info.status.inspecting': '檢閱中',
  'patientDetail.info.status.inspected': '已檢閱',
  'patientDetail.info.status.analyzing': '分析中',
  'patientDetail.info.status.analyzed': '已分析',
  'patientDetail.info.status.reviewing': '複閱中',
  'patientDetail.info.status.reviewed': '已複閱',
  'patientDetail.info.status.confirming': '確認中',
  'patientDetail.info.status.confirmed': '已確認',
  'patientDetail.info.status.reportUploading': '報告上傳中',
  'patientDetail.info.status.reportUploaded': '報告已上傳',
  'patientDetail.info.status.reportReviewing': '報告複檢中',
  'patientDetail.info.status.reportReviewed': '報告已複檢',
  'patientDetail.info.status.reportDownloaded': '報告已下載',
  'patientDetail.info.status.archived': '封存',
  'patientDetail.info.isUrgent': '急件',
  'patientDetail.info.urgentNote': '急件報告可能會產生額外的費用',
  'patientDetail.info.urgentDescription': '急件原因',
  'patientDetail.info.expectedDate': '預定回診日',
  'patientDetail.info.expectedDateNote': '準訊會依狀況保留調整報告上傳日期的權利',
  'patientDetail.info.estimatedDate': '預計完成日',
  'patientDetail.edit.comment': 'Comments',
  'patientDetail.edit.commentNotification': '請在點選接受按鈕後，確認報告的內容已正確更新。',
  'patientDetail.edit.signatures': '簽名',
  'patientDetail.edit.signatureTooltip': '帶入Comments',
  'patientDetail.edit.signAndSave': '簽名並下載',

  /* patient detail - confirm */
  'patientDetail.confirm.title': '請確認以下資料是否正確',

  /* patient detail - emergency */
  'patientDetail.emergency.emergency': '緊急通報',
  'patientDetail.emergency.reason': '原因',
  'patientDetail.emergency.addFigure': '新增圖片',
  'patientDetail.emergency.removeFigure': '刪除圖片',
  'patientDetail.emergency.noFigure': '無上傳圖片',
  'patientDetail.emergency.figures': '已上傳圖片：',
  'patientDetail.emergency.yes': '是',
  'patientDetail.emergency.no': '否',

  /* patient detail - report info */
  'patientDetail.report.analyst': '分析人員',
  'patientDetail.report.inspector': '檢閱者',
  'patientDetail.report.reviewer': '複閱者',
  'patientDetail.report.uploader': '報告上傳者',
  'patientDetail.report.reportReviewer': '報告複閱者',
  'patientDetail.report.status': '狀態',

  /* settings */
  'settings.export.from': '開始',
  'settings.export.to': '結束',
  'settings.export.export': '匯出',
  'settings.privilege.apply': '套用',
  'settings.privilege.location': '地點',
  'settings.privilege.user': '使用者',
  'settings.privilege.update': '權限設定成功',
  'settings.announcement.config': '設定',
  'settings.announcement.status': '狀態',
  'settings.announcement.level': '類別',
  'settings.announcement.duration': '顯示時間',
  'settings.announcement.position': '位置',
  'settings.announcement.notifyAfter': '開始',
  'settings.announcement.notifyBefore': '結束',

  /* user profile */
  'userProfile.addProfile': '新增資料',
  'userProfile.editProfile': '編輯資料',
  'userProfile.addProfileSuccessfully': '新增資料成功',
  'userProfile.editProfileSuccessfully': '編輯資料成功',
  'userProfile.firstName': '名字',
  'userProfile.lastName': '姓氏',
  'userProfile.userId': '帳號',
  'userProfile.oldPassword': '舊密碼',
  'userProfile.newPassword': '新密碼',
  'userProfile.confirmNewPassword': '確認新密碼',
  'userProfile.age': '年齡',
  'userProfile.gender': '性別',
  'userProfile.birth': '生日',
  'userProfile.email': '電子信箱',
  'userProfile.identifier': '身分證號',
  'userProfile.location': '據點',
  'userProfile.phone': '聯絡電話',
  'userProfile.address': '地址',
  'userProfile.add': '建立',
  'userProfile.apply': '套用',
  'userProfile.status': '狀態',
  'userProfile.status.disabled': '已停用',
  'userProfile.status.enabled': '已啟用',
  'userProfile.status.deleted': '已刪除',
  'userProfile.role': '角色',
  'userProfile.role.analyst': '分析員',
  'userProfile.role.hospitalStaff': '醫院人員',
  'userProfile.role.sales': '業務',
  'userProfile.webview': '線上ECG',

  /* location profile */
  'locationProfile.location': '據點',
  'locationProfile.customerId': '客戶編號',
  'locationProfile.name': '名稱',
  'locationProfile.division': '部門',
  'locationProfile.divisionHelper': '多個部門可用分號(;)分開',
  'locationProfile.phone': '電話',
  'locationProfile.address': '地址',
  'locationProfile.apply': '套用',
  'locationProfile.add': '新增',
  'locationProfile.addProfile': '新增據點',
  'locationProfile.success': '據點更新成功',

  /* web viewer */
  'webViewer.sidebar': '頁面縮圖',
  'webViewer.closeSidebar': '關閉頁面縮圖',
  'webViewer.openSidebar': '開啟頁面縮圖',
  'webViewer.collapse': '折疊',
  'webViewer.expand': '展開',
  'webViewer.closeECGWindow': '關閉心電圖視窗',
  'webViewer.openECGWindow': '開啟心電圖視窗',
  'webViewer.firstPage': '第一頁',
  'webViewer.previousPage': '上一頁',
  'webViewer.currentPage': '當前頁面',
  'webViewer.nextPage': '下一頁',
  'webViewer.lastPage': '最後一頁',
  'webViewer.zoomOut': '縮小',
  'webViewer.zoomIn': '放大',
  'webViewer.playReverse': '向後播放',
  'webViewer.playForward': '向前播放',
  'webViewer.stop': '停止',
  'webViewer.resolutionMessage': 'WebViewer建議最佳解析度: 1920 x 937, 當前解析度: ',
  'webViewer.lastPageMessage': '已到最後一頁',
  'webViewer.firstPageMessage': '已到最前一頁',
  'webViewer.inProgress': '執行中，請稍候... ',
  'webviewer.loading': '讀取{reportName}報告中…',
  'webviewer.noData': '{reportName}報告不存在。',
  'webviewer.error': '開啟報告{reportName}發生錯誤，請稍後再試。',
  'webviewer.passwordRequired': '請輸入密碼',
  'webViewer.rawSignal': '原始訊號',
  'webViewer.signal': '濾波訊號',

  /* error message */
  'error.login': '使用者名稱或密碼錯誤！',
  'error.resetPasswordFailed': '重設密碼失敗！',
  'error.passwordDoesNotMatchTheFormat': '密碼不符合格式(需6位數以上)。',
  'error.theNewPasswordCannotBeTheSameAsTheOldPassword': '新密碼不能和舊密碼相同。',
  'error.patientIdEmpty': '未輸入病歷號。',
  'error.nameEmpty': '未輸入姓名。',
  'error.checkIdEmpty': '未輸入檢查號。',
  'error.deviceIdEmpty': '未輸入機器號。',
  'error.locationEmpty': '未選擇醫療機構。',
  'error.setupDayEmpty': '未選擇裝機日期。',
  'error.dayVersionEmpty': '未選擇天數版本。',
  'error.urgentDescriptionEmpty': '未填寫急件原因。',
  'error.expectedDateEmpty': '未填寫預定回診日。',
  'error.ageEmpty': '未輸入年齡。',
  'error.sexEmpty': '未輸入性別。',
  'error.serverError': '系統回傳錯誤({errorCode})，請稍後再試。',
  'error.receiveFailFromServer': '資料傳輸異常，請稍後再試。',
  'error.receiveFailFromECGServer': '心電圖資料傳輸異常，伺服器錯誤訊息: ',
  'error.authenticationFailed': '驗證失敗，請重新登入。',
  'error.demoAccount': '您正在使用DEMO帳號。',
  'error.passwordResetAlready': '密碼無須重新設定。',
  'error.apiKeyExpired': '登入逾時，請重新登入。',
  'error.insufficientPrivilege': '權限不足',
  'error.readFileFail': '讀取檔案失敗，請稍後再試。',
  'error.fileInvalid': '檔案格式不正確。',
  'error.uploadFail': '上傳失敗，請稍後再試。',
  'error.downloadFail': '下載失敗，請稍後再試。',
  'error.fileNameMatchFail': '心電圖原始檔案名稱已重新編輯為”{fileName}”，上傳資料前請再次確認檔案正確。{br}請確認 ”{fileName}” 的(1)服務序號(2)上傳的心電圖檔案 皆正確無誤。',
  'error.checkPatientDataBeforeBinUpload': '心電圖檔案上傳後，無法再修改資料。請務必確認資料填寫無誤後再上傳資料。',
  'error.name_invalid': '檔名無效。請使用數字、英文字母與合法的特殊字元[-_.!~*\'()]所組成的檔案名稱。',
  'error.userId': '請輸入帳號',
  'error.firstName': '請輸入名字',
  'error.lastName': '請輸入姓氏',
  'error.email': '請輸入電子信箱',
  'error.newPassword': '請輸入密碼',
  'error.patientNotFound': '病患資訊不正確',
  'error.emsInvalidResponse': '無效的外部回應，請稍後再試',
  'error.deviceNotFound': '機器號不正確',
  'error.patchNotFound': '服務序號不正確',
  'error.wrongModel': '您輸入的序號與機器型號不符，請返回上一步選擇其他型號',
  'error.profile.userIdRequired': '請輸入帳號',
  'error.profile.userIdUnique': '此帳號已被使用',
  'error.profile.userIdRegex': '帳號名稱不應包含特殊字元',
  'error.profile.firstNameRequired': '請輸入名字',
  'error.profile.firstNameAlpha': '名字不應包含特殊字元',
  'error.profile.lastNameRequired': '請輸入姓名',
  'error.profile.lastNameAlpha': '姓氏不應包含特殊字元',
  'error.profile.emailRequired': '請輸入電子信箱',
  'error.profile.emailEmail': '電子信箱格式無效',
  'error.profile.newPasswordRequired': '請輸入新密碼',
  'error.profile.newPasswordConfirm': '請確認新密碼是否一致',
  'error.profile.newPasswordConfirmRequired': '請輸入確認新密碼',
  'error.profile.birthDate': '請確認日期格式',
  'error.profile.ageNumeric': '請輸入有效年齡',
  'error.profile.genderNumeric': '請輸入有效性別',
  'error.profile.newPasswordRequiredWith': '如欲修改密碼，請輸入新密碼',
  'error.profile.oldPasswordRequiredWith': '如欲修改密碼，請輸入舊密碼',
  'error.profile.passwordIncorrect': '密碼錯誤，請確認密碼輸入正確',
  'error.profile.locationRequired': '據點為必填欄位',
  'error.profile.locationNameNotString': '據點名稱應為字串格式',
  'error.profile.locationUuidNotString': '據點Uuid應為字串格式',
  'error.profile.locationStatusNotInteger': '狀態應為整數',
  'error.profile.locationRoleNotInteger': '角色應為整數',
  'error.server.maintanence': '伺服器暫時停止服務，請稍後再試',
  'error.location.customerId': '請輸入客戶編號',
  'error.location.name': '請輸入地點名稱',
  'error.location.addFail': '新增地點失敗，請稍後再嘗試。',
  'error.location.updateFail': '更新地點失敗，請稍後再嘗試。',
  'error.fileComp.serviceIdFail': '服務序號錯誤，請確認輸入是否正確。如仍輸入錯誤，請洽服務人員。',
  'error.fileComp.deviceIdFail': '機器序號錯誤，請確認輸入是否正確。',
  'error.fileComp.binUploadDeny': '檔案無法上傳，檢查資料已開始進行分析。',
  'error.commentEditSuccess': '報告已更新',
  'error.azure': '雲端內部錯誤。',
  'error.secure.ipWhiteList': '網路位址驗證錯誤。',
};
export default zh;