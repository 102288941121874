import React, { useState, useCallback, useEffect } from 'react';
import { Navigate } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import { axiosService } from "axios/axiosInstance";

import sha256 from "js-sha256";
import Cookies from "js-cookie";
import { useDispatch, useMappedState } from "redux-react-hook";
import { ACTIONTYPE_MESSAGE_CONFIG, ACTIONTYPE_SETTING_TO_LOGIN } from "../../redux/actionTypes";
import { useIntl, FormattedMessage} from "react-intl";
import useAxiosHandler from "../../axios/axiosHandler";


const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};



function ResetPassword() {
  const intl = useIntl();
  const [toPatientList, setToPatientList] = useState(false);
  
  const dispatch = useDispatch();
  const [successHandler, errorHandler] = useAxiosHandler();

  const openSuccess = useMappedState(useCallback(state => state.setting.openSuccess));

  useEffect(() => {
    if (!(Cookies.get('username') && Cookies.get('apiKey'))) {
      dispatch({ type: ACTIONTYPE_SETTING_TO_LOGIN, value: { toLogin: true, } });
    }
  }, []);

  const passwordHash = (value) => {
    if(value && value !== ''){
      const loginUrl = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '') + "/webanalyzer/v1/login";
      return sha256(loginUrl + value);
    } else {
      return null;
    }
  }

  const onSubmit = (e) => {
    e.preventDefault();

    const password = document.getElementById("Password").value;
    const newPassword = document.getElementById("NewPassword").value;
    const newPasswordConfirm = document.getElementById("NewPasswordConfirm").value;

    if (newPassword.length < 6) {
      dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.passwordDoesNotMatchTheFormat' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top'}}});
      return;
    }
    if (password === newPassword) {
      dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.theNewPasswordCannotBeTheSameAsTheOldPassword' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top'}}});
      return;
    }

    let data = new FormData();
    data.append('data', JSON.stringify(
      { oldPassword: passwordHash(password),
        newPassword: passwordHash(newPassword),
        newPassword_confirmation: passwordHash(newPasswordConfirm),}));
    data.append('_method', 'PUT');
    axiosService.post('/user/resetpassword/content/passowrd',
      data,
      {
        headers: {
          'Authorization': 'Bearer ' + Cookies.get('apiKey'),
          'X-Socket-ID': window.Echo ? window.Echo.socketId() : ''
        }
      }
    ).then(response => {
      console.log(response.data);
      function onSuccess(response) {
        if(response.status === 200) {
          if (response.data.error === 0) {
            console.log('reset password success.');
            dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'resetPassword.passwordResetSuccessful' }), timeout: 800, color: 'success', anchorOrigin: { horizontal: 'center', vertical: 'top' } } });
          }
          if (response.data.error === 0 || response.data.error === 10) {
            setToPatientList(true);
          }
        }
      }
      successHandler(response, onSuccess);
    }).catch(error => {
      console.log('request failed', error);
      function onError() {
        dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.resetPasswordFailed' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top'} } });
      }
      errorHandler(error, onError);
    });
  }

  return (
    <div>{toPatientList && !openSuccess ? <Navigate to='/webanalyzer/v1/patientlist' /> : 
      <form onSubmit={(e) => onSubmit(e)}>
        <GridContainer>
          <GridItem style={{ width: "450px", margin: "0 auto" }}>
            <Card>
              <CardHeader color="primary" style={{ display: 'flex', justifyContent: 'center' }}>
                <FormattedMessage id="resetPassword.firstLogin"/>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText={intl.formatMessage({ id: "resetPassword.oldPassword"})}
                      id="Password"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "password"
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText={intl.formatMessage({ id: "resetPassword.newPassword"})}
                      id="NewPassword"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "password"
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText={intl.formatMessage({ id: "resetPassword.newPasswordConfirm"})}
                      id="NewPasswordConfirm"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "password"
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button style={{ marginLeft: "auto", marginRight: "auto" }} color="primary" type="submit">
                  <FormattedMessage id="resetPassword.continue"/>
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </form>}</div>
  );
}

export default withStyles(styles)(ResetPassword);
