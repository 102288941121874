import React from 'react';
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";

// import ContentPaste from "@material-ui/icons/ContentPaste";

// core components/views
import Login from "views/Login/Login.jsx"
import ResetPassword from "views/ResetPassword/ResetPassword.jsx"
import PatientList from "views/PatientList/PatientList.jsx";
import DemoPatientList from "views/Demo/DemoPatientList.jsx";
import { FormattedMessage } from 'react-intl';

const dashboardRoutes = [
  {
    path: "webanalyzer/v1/login",
    sidebarName: <FormattedMessage id='routes.login'/>,
    navbarName: <FormattedMessage id='routes.login' />,
    icon: Dashboard,
    component: Login
  },
  {
    path: "webanalyzer/v1/user/resetpassword",
    privateRoute: true,
    sidebarName: "ResetPassword",
    navbarName: "ResetPassword",
    icon: Dashboard,
    component: ResetPassword
  }/*,{
    path: "/webanalyzer/v1/dashboard",
    sidebarName: "Dashboard",
    navbarName: "Dashboard",
    icon: Dashboard,
    component: DashboardPage
  }*/,
  {
    path: "webanalyzer/v1/patientlist",
    privateRoute: true,
    sidebarName: <FormattedMessage id='routes.patientList' />,
    navbarName: <FormattedMessage id='routes.patientList' />,
    icon: Dashboard,
    component: PatientList
  },
  {
    path: "webanalyzer/v1/demo",
    privateRoute: true,
    sidebarName: <FormattedMessage id='routes.demo' />,
    navbarName: <FormattedMessage id='routes.demo' />,
    icon: Dashboard,
    component: DemoPatientList
  }/*, {
    path: "/webanalyzer/v1/patientdetail",
    sidebarName: "Patient Detail",
    navbarName: "Patient Detail",
    icon: Dashboard,
    component: PatientDetail
  },
  {
    path: "/webanalyzer/v1/user",
    sidebarName: "User Profile",
    navbarName: "Profile",
    icon: Person,
    component: UserProfile
  },
  {
    path: "/webanalyzer/v1/table",
    sidebarName: "Table List",
    navbarName: "Table List",
    icon: "content_paste",
    component: TableList
  },
  {
    path: "/webanalyzer/v1/typography",
    sidebarName: "Typography",
    navbarName: "Typography",
    icon: LibraryBooks,
    component: Typography
  },
  {
    path: "/webanalyzer/v1/icons",
    sidebarName: "Icons",
    navbarName: "Icons",
    icon: BubbleChart,
    component: Icons
  },
  {
    path: "/webanalyzer/v1/maps",
    sidebarName: "Maps",
    navbarName: "Map",
    icon: LocationOn,
    component: Maps
  },
  {
    path: "/webanalyzer/v1/notifications",
    sidebarName: "Notifications",
    navbarName: "Notifications",
    icon: Notifications,
    component: NotificationsPage
  },
  {
    path: "/webanalyzer/v1/upgrade-to-pro",
    sidebarName: "Upgrade To PRO",
    navbarName: "Upgrade To PRO",
    icon: Unarchive,
    component: UpgradeToPro
  },*/
  
  , {
    path: "/",
    privateRoute: true,
    sidebarName: <FormattedMessage id='routes.patientList' />,
    navbarName: <FormattedMessage id='routes.patientList' />,
    icon: Dashboard,
    component: PatientList
  }, {
    path: "*",
    privateRoute: true,
    sidebarName: <FormattedMessage id='routes.patientList' />,
    navbarName: <FormattedMessage id='routes.patientList' />,
    icon: Dashboard,
    component: PatientList
  },
];

export default dashboardRoutes;
