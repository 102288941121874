import React, { useEffect, useCallback } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import { withStyles } from '@material-ui/core/styles';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
// core components
import Grid from "@material-ui/core/Grid";
import HeaderLinks from "./HeaderLinks.jsx";
import { FormattedMessage } from "react-intl";
import { useDispatch, useMappedState } from "redux-react-hook";
import headerStyle from "assets/jss/material-dashboard-react/components/headerStyle.jsx";
import { ACTIONTYPE_SETTING_TO_DEMO_VIEW, ACTIONTYPE_SETTING_TO_PATIENTLIST, ACTIONTYPE_SETTING_TO_RESET_PASSWORD, ACTIONTYPE_SETTING_TO_LOGIN } from "../../redux/actionTypes";
import trademark from "../../assets/img/trademark.png";

function Header({ ...props }) {
  const dispatch = useDispatch();
  const toLogin = useMappedState(useCallback(state => state.setting.toLogin));
  const toResetPassword = useMappedState(useCallback(state => state.setting.toResetPassword));
  const toDemoView = useMappedState(useCallback(state => state.setting.toDemoView));
  const toPatientList = useMappedState(useCallback(state => state.setting.toPatientList));


  useEffect(() => {
    if(toLogin) {
      // reload page to fetch new page if exists
      // window.location.reload();
      // window.location.replace("https://report.sigknow.com/webanalyzer/v1/login");
      dispatch({ type: ACTIONTYPE_SETTING_TO_LOGIN, value: { toLogin: false } });
    }
    if(toResetPassword) {
      dispatch({ type: ACTIONTYPE_SETTING_TO_RESET_PASSWORD, value: { toResetPassword: false}});
    }
    if(toDemoView) {
      dispatch({ type:ACTIONTYPE_SETTING_TO_DEMO_VIEW, value: { toDemoView: false}});
    }
    if(toPatientList) {
      dispatch({ type: ACTIONTYPE_SETTING_TO_PATIENTLIST, value: { toPatientList: false } });
    }
  }, [toLogin, toResetPassword, toDemoView, toPatientList]);

  // function makeBrand() {
  //   var name;
  //   props.routes.map((prop, key) => {
  //     if (prop.path === props.location.pathname) {
  //       name = prop.navbarName;
  //     }
  //     return null;
  //   });
  //   return name;
  // }
  const { classes, color } = props;
  const appBarClasses = classNames({
    [" " + classes[color]]: color
  });
  return (
    <AppBar className={classes.appBar + appBarClasses}
  style={{ /*zIndex: 2000backgroundImage: `url(${sigknow_style_web})`, backgroundSize: 'cover'*/ }} // make error message topmost
      >
      <Toolbar className={classes.container}>
        <Grid container>
          <Grid item xs={3} sm={3} md={3} style={{ height: "fit-content", marginTop: "auto", marginBottom: "auto"}}>
            <img src={trademark} style={{ width: "55%"}}/>
            {/* Here we create navbar brand, based on route name */}
            {/*props.onlySetting ? null : <Button color="transparent" href="#" className={classes.title}>
              {makeBrand()}
              </Button>*/}
          </Grid>
          <Grid item xs={1} sm={1} md={1} className={classes.flex}></Grid>
          <Grid item xs={4} sm={4} md={4}>
            <h3 style={{ textAlign: 'center', color: '	#0E4073',
              webkitTouchCallout: 'none', /* iOS Safari */
              webkitUserSelect: 'none', /* Safari */
              khtmlUserSelect: 'none', /* Konqueror HTML */
              mozUserSelect: 'none', /* Old versions of Firefox */
              msUserSelect: 'none', /* Internet Explorer/Edge */
              userSelect: 'none' /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */}}>
              <FormattedMessage id="header.system"></FormattedMessage>
            </h3>
          </Grid>

          <Grid item xs={4} sm={4} md={4}>
            <Hidden implementation="css">
              <HeaderLinks onlySetting={props.onlySetting} resetPassword={props.resetPassword}/>
            </Hidden>
          </Grid>
          
          {/*<HeaderLinks classes />
          <Hidden mdUp implementation="css">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={props.handleDrawerToggle}
            >
              <Menu />
            </IconButton>
          </Hidden>*/}
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  routes: PropTypes.array,
  location: PropTypes.shape({
    pathname: PropTypes.string
  }),
  onlySetting: PropTypes.bool,
  resetPassword: PropTypes.bool
};

export default withStyles(headerStyle)(Header);
