import React, { useState, useEffect, useCallback, useRef } from 'react';
import Compose from '../Compose';
import ToolbarButton from '../ToolbarButton';
import Message from '../Message';
import moment from 'moment';
import PropTypes from 'prop-types';
import { style } from 'glamor';
import ScrollToBottom from 'react-scroll-to-bottom';
import './MessageList.css';
import { axiosService } from 'axios/axiosInstance';
import Cookies from "js-cookie";
import Echo from 'laravel-echo';
import useAxiosHandler from '../../../axios/axiosHandler';
window.io = require('socket.io-client').io;

function MessageList(props) {

  const [messages, setMessages] = useState(null);
  const [hasSendMessage, setHasSendMessage] = useState(false);
  const [currentMessage, setCurrentMessage] = useState('');
  const [successHandler, errorHandler] = useAxiosHandler();
  const { uuid, isDemo, chatList, setChatList, editable, handleRefreshRequest } = props;
  var lastId = 0;

  const ROOT_CSS = style({
    /*position: "fixed",
    bottom: "10px",
    right: "10px",
    width: "260px",*/
    width: "100%",
    height: "400px",
  });
  const hasSendMessageRef = useRef();
  hasSendMessageRef.current = hasSendMessage;

  useEffect(() => {
    lastId = chatList.length;
    if(!isDemo && lastId > 0) {
      // send ack to server

      let data = new FormData();
      data.append('lastChatId', lastId);
      data.append('_method', 'PUT');
      axiosService.post('/patientdetail/content/chat/' + /* this. */props.uuid,
        data,
        {
          headers: {
            'Authorization': 'Bearer ' + Cookies.get('apiKey'),
            'X-Socket-ID': window.Echo ? window.Echo.socketId() : ''
          }
        }).then(response => {
          console.log(response);
          successHandler(response);
        }).catch(error => {
          errorHandler(error);
        });
    }
  }, [chatList]);

  useEffect(() => {
    if (!isDemo) {
      lastId = 0;
      if (window.Echo === undefined || window.Echo === null) {
        window.Echo = new Echo({
          broadcaster: 'socket.io',
          host: window.location.protocol + '//' + window.location.hostname,
          transports: ['websocket'], // Fix CORS error!
          enabledTransports: ['ws', 'wss'],
          client: require('socket.io-client').io ,
          authEndpoint: '/webanalyzer/v1/broadcasting/auth',
          // As I'm using JWT tokens, I need to manually set up the headers.
          auth: {
            headers: {
              //AccessControlAllowOrigin: 'https://' + window.location.hostname,
              Authorization: 'Bearer ' + Cookies.get('apiKey'),
              Accept: 'application/json',
            },
          },
        });
      }
      // window.Echo.private('ChatChannel-' + props.uuid)
      //   .listen('Chat', (e) => {
      //     const msg = JSON.parse(e.data);
      //     console.log(msg);
      //     setChatList(oldArray => [...oldArray, msg]);
      //   });
      window.Echo.private('UserChannel-' + Cookies.get('username'))
        .listen('UpdateData', (e) => {

          console.log(e);
          const msg = JSON.parse(e.data);
          if (msg.chats && msg.uuid === uuid && msg.chats.message) {
            console.log('receive UpdateData event for message list');
            setChatList(oldArray => [...oldArray, msg.chats.message]);
          }
        });
      return () => {
        console.log("chat exit!");
        if (window.Echo && typeof window.Echo.private === 'function') {
          // window.Echo.private('ChatChannel-' + props.uuid).stopListening('Chat');
          // window.Echo.private('UserChannel-' + Cookies.get('username')).stopListening('UpdateData');
        }
        if (hasSendMessageRef.current) {
          console.log("refresh for new chat message");
          handleRefreshRequest();
        }
      };
    }
  },[]);

  const onSendNewMessage = (e) => {

    e.preventDefault();

    if(!isDemo && currentMessage.trim().length > 0) {
      setHasSendMessage(true);
      axiosService.post('/patientdetail/content/chat?uuid=' + /* this. */props.uuid + '&id=' + lastId,
      {
        message: 
          {
            name: Cookies.get('username'),
            chatUuid: uuid,
            time: new Date().getTime(),
            type: "text",
            content: {
              message: currentMessage,
            }
          }
        ,
      },
      {
        headers: {
          'Authorization': 'Bearer ' + Cookies.get('apiKey'),
          'X-Socket-ID': window.Echo ? window.Echo.socketId() : ''
        }
      }).then(response => {
        console.log(response.data);
        function onSuccess(response) {
          if (response.data.error === 0) {
            // setChatList(oldArray => [...oldArray, 
            //   { 
            //     name: Cookies.get('username'),
            //     chatUuid: uuid,
            //     time: new Date().getTime(),
            //     type: 'text',
            //     content: {
            //       message: currentMessage,
            //     } }]);
            setCurrentMessage('');
          }
        }
        successHandler(response, onSuccess);
      }).catch(error => {
        errorHandler(error);
      });
    }
  }

  useEffect(useCallback(() => {
    renderMessages();
  }), [chatList]);

  function renderMessages() {
    let i = 0;
    let messageCount = chatList.length;
    let renderMessagesList = [];

    while (i < messageCount) {
      let previous = chatList[i - 1];
      let current = chatList[i];
      let next = chatList[i + 1];
      let isMine = current.name.toLowerCase() === Cookies.get('username').toLowerCase();
      let currentMoment = moment(current.time);
      let prevBySameAuthor = false;
      let nextBySameAuthor = false;
      let startsSequence = true;
      let endsSequence = true;
      let showTimestamp = true;

      if (previous) {
        let previousMoment = moment(previous.time);
        let previousDuration = moment.duration(currentMoment.diff(previousMoment));
        prevBySameAuthor = previous.name === current.name;
        
        if (prevBySameAuthor && previousDuration.as('hours') < 1) {
          startsSequence = false;
        }

        if (previousDuration.as('hours') < 1) {
          showTimestamp = false;
        }
      }

      if (next) {
        let nextMoment = moment(next.time);
        let nextDuration = moment.duration(nextMoment.diff(currentMoment));
        nextBySameAuthor = next.name === current.name;

        if (nextBySameAuthor && nextDuration.as('hours') < 1) {
          endsSequence = false;
        }
      }

      renderMessagesList.push(
        <Message
          username={current.name}
          key={i}
          isMine={isMine}
          startsSequence={startsSequence}
          endsSequence={endsSequence}
          showTimestamp={showTimestamp}
          data={current}
        />
      );

      // Proceed to the next message.
      i += 1;
    }

    setMessages(renderMessagesList);
  }

  return(
    <div className="message-list">
      <ScrollToBottom sticky style={ROOT_CSS} >{messages}</ScrollToBottom>
      <form onSubmit={onSendNewMessage}>
        <Compose editable={editable} rightItems={[
          <ToolbarButton disabled={!editable} editable={editable} className="toolbar-button" key="send" icon="ion-ios-send" onHandleClick={onSendNewMessage}/>
        ]} textValue={currentMessage} handleValueChange={(e) => {
          setCurrentMessage(e.target.value);
          }} onSendNewMessage={onSendNewMessage}/>
      </form>
    </div>
  );
}

MessageList.defaultProps = {
  isDemo: false,
}

MessageList.propTypes = {
  uuid: PropTypes.string,
  isDemo: PropTypes.bool,
  chatList: PropTypes.arrayOf(PropTypes.shape(
    {
      name: PropTypes.string,
      time: PropTypes.number,

    }
  )),
  setChatList: PropTypes.func,
  editable: PropTypes.bool,
  handleRefreshRequest: PropTypes.func
};

export default MessageList;