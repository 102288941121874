import React, { useCallback, useEffect } from "react";
import { createRoot } from 'react-dom/client';
import { useRoutes, Routes, BrowserRouter } from "react-router-dom";
import "assets/css/material-dashboard-react.css?v=1.5.0";

import indexRoutes from "routes/index.jsx";
import en from 'i18n/en.js';
import zh from 'i18n/zh.js';

import ProtectedRoute from "views/Dashboard/ProtectedRoute";

import { SnackbarProvider } from 'notistack';
// import { CookiesProvider } from 'react-cookie';
//import { Provider } from 'react-redux'
import { StoreContext } from 'redux-react-hook';
import { makeStore } from "./redux/store";
import { IntlProvider } from 'react-intl';
import { useMappedState } from "redux-react-hook";
import * as serviceWorker from './serviceWorker';
import { withRouter } from "layouts/Dashboard/withRouter";

const store = makeStore();

const routeObjectConverter = (item) => {
  var retObj = {};
  if(item) {
    if(item.path)
      retObj.path = item.path;
    if(item.component){
      if(item.privateRoute) {
        retObj.element = <ProtectedRoute><item.component></item.component></ProtectedRoute>//withRouter(item.component);
      } else {
        retObj.element = <item.component></item.component>//withRouter(item.component);
      }
    }
    // if(item.sidebarName)
    //   retObj.sidebarName = item.sidebarName;
    // if(item.navbarName)
    //   retObj.navbarName = item.navbarName;
    // if(item.icon)
    //   retObj.icon = item.icon;
    if(item.children)
      retObj.children = item.children.map(routeObjectConverter);
  }
  return retObj;
}

const App = () => {
  // const routeObject = indexRoutes.map((prop, key) => {
  //   return { path: prop.path, element: prop.component, children: prop.children };
  // });
  const routeObject = indexRoutes.map(routeObjectConverter);
  console.log(routeObject);
  const routes = useRoutes(routeObject);

  useEffect(() => {
    console.log(routes);
  }, [routes]);

  return (<>{routes}</>);
}

const Root = () => {
  const locale = useMappedState(useCallback(state => state.setting.language));

  
  let messages;

  if (locale.includes('zh')) {
    messages = zh;
  } else {
    messages = en;
  }

  const onSWUpdate = (registration) => {
    console.log('onSWUpdate');
    console.log(registration);
    // setShowReload(true);
    // setInstallingWorker(registration.installing);
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    }
    window.location.reload();
  };

  React.useEffect(() => {
    serviceWorker.register({ onUpdate: onSWUpdate });
  }, []);

  return (
    <IntlProvider local={locale} key={locale} defaultLocale="en-US" messages={messages}>
      {/* <CookiesProvider> */}
        <SnackbarProvider maxSnack={4}>
          <BrowserRouter >
              <App />
            {/* <Routes>
              {indexRoutes.map((prop, key) => {
                console.log(prop.path);
                return <Route path={prop.path} component={prop.component} key={key}/>;
              })}
            </Routes> */}
          </BrowserRouter>
        </SnackbarProvider>
      {/* </CookiesProvider> */}
    </IntlProvider>);
};

const container = document.getElementById("root");
const rootRender = createRoot(container);

rootRender.render(<StoreContext.Provider value={store}>
  <Root />
</StoreContext.Provider>);

// ReactDOM.render(

//   <StoreContext.Provider value={store}>
//     <Root />
//   </StoreContext.Provider>,
//   document.getElementById("root")
// );

serviceWorker.register();
