import React, { useState, useEffect, useRef, lazy, Suspense } from "react";
import PropTypes from 'prop-types';
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import { makeStyles } from '@material-ui/core/styles';
import { purple, green, blue } from "@material-ui/core/colors";
// @material-ui/icons
import CloudUpload from "@material-ui/icons/CloudUpload";
import CloudDownload from "@material-ui/icons/CloudDownload";
import Edit from "@material-ui/icons/Edit";
import PdfViewer from "@material-ui/icons/PictureAsPdf";
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
// core components
import { axiosService } from "axios/axiosInstance";
import Cookies from "js-cookie";
import LoadingDialog from "./LoadingDialog"

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

import CircularProgress from '@material-ui/core/CircularProgress';
import { useDispatch } from "redux-react-hook";
import { ACTIONTYPE_MESSAGE_CONFIG } from "../../redux/actionTypes";
import { useIntl, FormattedMessage } from "react-intl";
import { humanFileSize } from "../../components/Azure/Utilities";

import SvgIcon from '@material-ui/core/SvgIcon';
import { DialogActions, DialogContent, Typography, Button} from "@material-ui/core";
import useAxiosHandler from "../../axios/axiosHandler";
import useAzureWorkers, { DEFALTE_EXT } from "../../components/Azure/azureWorkers";
import { ButtonGroup } from "@material-ui/core";
// import pdf1102 from "assets/img/1102.pdf";

const WebViewer = lazy(() => import('components/FileComponent/WebViewer/WebViewer'));
const EditViewer = lazy(() => import('components/FileComponent/EditViewer/EditViewer'));

function WebViewerIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 1000 1000">
      <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
      <g><path d="M920,495c-25.9,0-48.5,14.1-60.6,35H648.1l-49.2,159.3l-94.5-496.7c-1.4-7.5-3.3-13.6-5.8-18.7c-6.9-14.3-19.8-23-34.5-23.1c-14.7-0.2-27.8,8.2-35,22.4c-2.6,5.1-4.6,11.2-6.2,18.6l-82.6,387.6L295.3,442c-10.5-32.1-33.3-37.6-42.6-38.5c-9.3-0.9-32.8,0.3-49,30l-53,96.6H10v70h182l51-92.9l60.3,184.2c11.6,35.4,35.3,38.1,45,37.6c9.7-0.5,32.9-5.6,40.7-42.1L462,344l87.9,462.2c7.1,37.1,30.4,42.4,40.1,42.9c0.7,0,1.5,0.1,2.2,0.1c13.1,0,25.1-6.6,33.2-18.3c3.7-5.4,6.7-11.9,9.2-19.9l65.2-211h159.7c12.1,20.9,34.7,35,60.6,35c38.7,0,70-31.3,70-70C990,526.4,958.7,495,920,495z" /></g>
    </SvgIcon>
  );
}



function DownloadButton(props) {
  const { uuid, fileSize, fileName, type, onDownload, downloadStyle } = props;
  return (<Tooltip title={humanFileSize(fileSize, true)}>
    <IconButton aria-label="download" onClick={onDownload(uuid, fileName, fileSize)}>
      <CloudDownload style={downloadStyle} />
    </IconButton>
  </Tooltip>);
}
DownloadButton.propTypes = {
  uuid: PropTypes.string.isRequired,
  fileSize: PropTypes.number.isRequired,
  fileName: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onDownload: PropTypes.func.isRequired,
  downloadStyle: PropTypes.object
}

function DisableDownloadButton(props) {
  const { tooltip } = props;
  return (<Tooltip title={tooltip}>
    <div>
      <IconButton disabled aria-label="download">
        <CloudDownload />
      </IconButton>
    </div>
  </Tooltip>);
}
DisableDownloadButton.propTypes = {
  tooltip: PropTypes.string.isRequired
}

function WebViewerDialog(props) {
  const { prePathname, uuid, signalType, reportType, signalName, reportName, setDialogComponent } = props;

  return (<Dialog open fullWidth maxWidth={"xl"} onClose={() => { setDialogComponent(null); }} aria-labelledby="form-dialog-title" style={{ height: "100%" }} PaperProps={{ style: { height: "100%" } }}
    >
    <DialogContent style={{ paddingTop: '0px' }} >
      <Suspense fallback={<div></div>}>
        <WebViewer signalType={signalType} reportType={reportType} uuid={uuid} signalName={signalName} reportName={reportName}
          onClose={() => { setDialogComponent(null); }} sasPathname={prePathname} />
      </Suspense>
    </DialogContent>
  </Dialog>);
}
WebViewerDialog.propTypes = {
  prePathname: PropTypes.string,
  signalType: PropTypes.string.isRequired,
  reportType: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired,
  signalName: PropTypes.string,
  reportName: PropTypes.string,
  setDialogComponent: PropTypes.func.isRequired,
}

function EditViewerButton(props) {
  const { uuid, type, setDialogComponent, filename, filesize, commentPathname, isCommentModified } = props;
  const intl = useIntl();

  const onEditViewClose = (event, reason) => {
    console.log("editview close reason: " + reason);
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      event.stopPropagation();
      return;
    }
    setDialogComponent(null);
  };

  return (<Tooltip title={intl.formatMessage({ id: "patientList.editPdf"})}>
    <IconButton onClick={(e) => {
      e.stopPropagation();
      setDialogComponent(
        <LoadingDialog
          open
          maxWidth={"xl"}
          fullWidth={true}
          onClose={onEditViewClose}
          aria-labelledby="form-dialog-title" 
          style={{ height: "100%" }} 
          PaperProps={{ style: { width: "100%", height: "100%" } }}>
          <DialogContent >
            <Suspense fallback={null}>
              <EditViewer uuid={uuid} type={type} commentPathname={commentPathname}
                filename={filename} filesize={filesize} onClose={onEditViewClose} />
            </Suspense>
          </DialogContent>
        </LoadingDialog>
        );
    }}>
      <Edit style={{ color: "#D2042D", borderBottom: isCommentModified ? "solid 2px" : "none" }} />
    </IconButton>
  </Tooltip>);
}
EditViewerButton.propTypes = {
  uuid: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  commentPathname: PropTypes.string.isRequired,
  setDialogComponent: PropTypes.func.isRequired,
  filename: PropTypes.string,
  filesize: PropTypes.number,
  isCommentModified: PropTypes.bool,
}

function PdfViewerButton(props) {
  const { prePathname, uuid, type, reportName } = props;
  const [, , getReportViewerPath] = useAzureWorkers();
  const [successHandler, errorHandler] = useAxiosHandler();
  const intl = useIntl();

  return (<Tooltip title={intl.formatMessage({ id: "patientList.pdfViewer"})}>
    <IconButton onClick={(e) => {
      e.stopPropagation();
      axiosService.get(
        prePathname + '?uuid=' + uuid + '&type=' + type + '&action=1&file=' + reportName + '&viewer=1',
        {
          headers: {
            'Authorization': 'Bearer ' + Cookies.get('apiKey'),
            'X-Socket-ID': window.Echo ? window.Echo.socketId() : ''
          }
        }).then(response => {
          console.log(response.data);
          async function onSuccess(response) {
            if (response.status === 200 && response.data.error === 0) {
              const reportViewrPath = await getReportViewerPath({
                uuid: uuid,
                sas: response.data.message.sas,
                fileName: reportName,
                containerName: type
              });
              window.open(reportViewrPath, '_blank');
            }
          }
          successHandler(response, onSuccess);
        }).catch(error => {
          errorHandler(error);
        });
    }}>
      <PdfViewer style={{ color: "#D2042D" }} />
    </IconButton>
  </Tooltip>);
}
PdfViewerButton.propTypes = {
  prePathname: PropTypes.string,
  uuid: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  reportName: PropTypes.string,
}

function WebViewerButton(props) {
  const { prePathname, uuid, signalType, reportType, signalName, reportName, setDialogComponent } = props;

  return (<Tooltip title={'Web Viewer'}>
    <IconButton onClick={(e) => {
      e.stopPropagation();
      setDialogComponent(
        <WebViewerDialog prePathname={prePathname} uuid={uuid} signalType={signalType} reportType={reportType} signalName={signalName} reportName={reportName} setDialogComponent={setDialogComponent} />);
    }}>
      <WebViewerIcon style={{ color: blue['A400'] }} />
    </IconButton>
  </Tooltip>);
}
WebViewerButton.propTypes = {
  prePathname: PropTypes.string,
  uuid: PropTypes.string.isRequired,
  signalType: PropTypes.string.isRequired,
  reportType: PropTypes.string.isRequired,
  signalName: PropTypes.string,
  reportName: PropTypes.string,
  setDialogComponent: PropTypes.func.isRequired,
}

function UploadButton(props) {
  const { uuid, deviceId, patchId, type, fileType, identifier, uploadStyle,
    onUploaded, onFileSelected } = props;

  const dispatch = useDispatch();
  const [openUploadWarningDialog, setOpenUploadWarningDialog] = useState(false);
  const [openFileNameConfirmDialog, setOpenFileNameConfirmDialog] = useState(false);
  const [fileEvent, setFileEvent] = useState(null);
  const inputRef = useRef(null);
  const fileRef = useRef(null);
  
  const intl = useIntl();

  const onInputChange = (e) => {
    console.log("onInputChange")
    console.log('patchID when select file: ' + patchId);
    console.log('deviceId when select file: ' + deviceId);
    if (type.toLowerCase().includes('signal') && e.target.files.length > 0) {
      let showWarning = false;
      if(patchId && patchId !== '') {
        showWarning = !(e.target.files[0].name.toUpperCase().includes(patchId.toUpperCase()));
      } else {
        showWarning = !(e.target.files[0].name.toUpperCase().includes(deviceId.toUpperCase()));
      }
      if(showWarning) {
        fileRef.current = e.target.files;
        setFileEvent(e);
        setOpenFileNameConfirmDialog(true);
        return;
      }
    }
    
    onFileSelected(uuid, e.target.files);
  };

  const onFileNameConfirm = e => {
    e.stopPropagation();
    console.log(fileRef);
    onFileSelected(uuid, fileRef.current);
    setOpenFileNameConfirmDialog(false);
  }

  const onFileNameCancel = e => {
    e.stopPropagation();
    setOpenFileNameConfirmDialog(false);
  }

  const onDialogOk = (e) => {
    e.stopPropagation();
    console.log("Dialog close");
    setOpenUploadWarningDialog(false);
    if(!!inputRef.current) {
      inputRef.current.click();
    }
  }

  return (<div>
    { openFileNameConfirmDialog ? <Dialog open={openFileNameConfirmDialog} maxWidth={"xl"} onClose={() => { }} aria-labelledby="form-dialog-title" style={{ height: "100%" }} >
      <DialogContent >
        <Typography variant="body1" style={{ paddingLeft: "10px", display: "flex", justifyContent: "center" }}>
          {intl.formatMessage({ id: 'error.fileNameMatchFail' }, { fileName: fileEvent ? fileEvent.target.files[0].name : '' , br: <br/>})}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onFileNameCancel}>
          {intl.formatMessage({ id: 'patientDetail.cancel' })}
        </Button>
        <Button color="primary" onClick={onFileNameConfirm}>
          {intl.formatMessage({ id: 'patientList.confirm' })}
        </Button>
      </DialogActions>
    </Dialog> : null }
    {openUploadWarningDialog ? <Dialog open={openUploadWarningDialog} maxWidth={"xl"} onClose={() => { }} aria-labelledby="form-dialog-title" style={{ height: "100%" }} >
      <DialogContent >
        <Typography variant="body1" style={{ paddingLeft: "10px", display: "flex", justifyContent: "center" }}>
          <FormattedMessage id={'error.checkPatientDataBeforeBinUpload'} values={{ br: <br/>}}/>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onDialogOk}>
          {intl.formatMessage({ id: 'patientList.confirm' })}
        </Button>
      </DialogActions>
    </Dialog> : null}
    <input id={"upload-" + type + "-" + uuid + "-" + identifier} ref={inputRef} onClick={(e) => {
      e.target.value = null;
      
      console.log('onInput click');
      return e.stopPropagation();
    }} onChange={e => onInputChange(e)} type="file" accept={fileType} style={{ display: "none" }} />
    <IconButton component="span" aria-label="upload" onClick={(e) => {
      if (type.toLowerCase().includes('signal')) {
        setOpenUploadWarningDialog(true);
      } else {
        if (!!inputRef.current) {
          inputRef.current.click();
        }
      }
      e.stopPropagation();
      console.log('IconButton click');
    }}>
      <CloudUpload style={uploadStyle} />
    </IconButton>
  </div>);
}
UploadButton.propTypes = {
  uuid: PropTypes.string.isRequired,
  deviceId: PropTypes.string.isRequired,
  patchId: PropTypes.string,
  type: PropTypes.string.isRequired,
  fileType: PropTypes.string.isRequired,
  identifier: PropTypes.string.isRequired,
  uploadStyle: PropTypes.object.isRequired,
  onUploaded: PropTypes.func.isRequired,
  onFileSelected: PropTypes.func.isRequired
}

function FileComp({...props}) {

  const { isCommentModified, isDemo, identifier, type, fileType, canUpload, canDownload, canOpenWebViewer, canOpenPdfViewer, canEditComment, uuid, deviceId, patchId, inputFileName, signalName, inputFileSize, onUploaded, onDownloaded, setDialogComponent, prePathname, postPathname } = props;

  // Put upload and download job inside component
  const [isUploading, setIsUploading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [hasFileName, setHasFileName] = useState(!!inputFileName);
  const [fileName, setFileName] = useState(inputFileName);
  const [fileSize, setFileSize] = useState(inputFileSize);
  const [uploadStyle, setUploadStyle] = useState({ color: green['A400'] });
  const [downloadStyle, setDownloadStyle] = useState({ color: purple['A400'] });
  const [successHandler, errorHandler] = useAxiosHandler();
  const [upload, download, getReportViewerPath] = useAzureWorkers();

  const dispatch = useDispatch();
  const intl = useIntl();

  const fileRef = useRef(null);

  useEffect(() => {
    setHasFileName(!!inputFileName);
    setFileName(inputFileName);
  }, [inputFileName]);

  useEffect(() => {
    setFileSize(inputFileSize);
  }, [inputFileSize]);

  // signal uploading status: 1
  // report uploading status: 12

  const useStyles = makeStyles(theme => ({
    progress: {
      margin: theme.spacing(2),
    },
    paper: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing(1),
        left: '30%',
        right: '30%',
      },
  }));
  const styleClasses = useStyles();

  // Marshals a string to an Uint8Array.
  // function stringToUint(string) {
  //   var encodedString = btoa(unescape(encodeURIComponent(string))),
  //     charList = encodedString.split(''),
  //     uintArray = [];
  //   for (var i = 0; i < charList.length; i++) {
  //     uintArray.push(charList[i].charCodeAt(0));
  //   }
  //   return new Uint8Array(uintArray);
  // }

  // Unmarshals a string from an Uint8Array.
  // function uintToString(uintArray) {
  //   var encodedString = String.fromCharCode.apply(null, uintArray),
  //     decodedString = decodeURIComponent(escape(encodedString));
  //   return decodedString;
  // }

  function onDownload(uuid, name, size) {
    return async function (e) {
      e.stopPropagation();
      // const methodParams = [uuid, name, size, type + dev + '/' + uuid, onDownloadFinish];
      setDownloadStyle({ color: purple[500] });

      const deflateName = name + '.deflate';
      const containerName = type + '/' + uuid;
      let url = prePathname + '?uuid=' + uuid + '&type=' + type + '&action=1&file=' + deflateName;
      // 1. notify server and get SAS token
      try{
        const getResp = await axiosService.get(url, {
          headers: {
            'Authorization': 'Bearer ' + Cookies.get('apiKey'),
            'X-Socket-ID': window.Echo ? window.Echo.socketId() : ''
          }
        });


        console.log(getResp);
        if (!(getResp instanceof Error)) {
          successHandler(getResp);
          if (getResp.data.error === 0) {
            setIsDownloading(true);
            // 2. get SAS token success and start download process

            try {
              const azureResp = await download([uuid, name, size, containerName],
                getResp.data.message.sas, getResp.data.message.historyUuid, setProgress);
              console.log(azureResp);
              if (azureResp !== null && azureResp._response.status === 200) {
                const date = new Date();
                // 3. reply download result to server
                if (postPathname) {
                  const postResp = await axiosService.post(
                    postPathname + '?uuid=' + uuid + '&type=' + type + '&action=1&file=' + deflateName, {
                    filename: name,
                    filesize: size,
                    result: azureResp,
                    historyUuid: getResp.data.message.historyUuid,
                  }, {
                    headers: {
                      'Authorization': 'Bearer ' + Cookies.get('apiKey'),
                      'X-Socket-ID': window.Echo ? window.Echo.socketId() : ''
                    }
                  });
                  successHandler(postResp, onDownloaded, {
                    filename: name, filesize: size,
                    time: date.getUTCFullYear() + "-" + (date.getUTCMonth() + 1) + "-" + date.getUTCDate() + " " + date.getUTCHours() + ":" + date.getUTCMinutes() + ":" + date.getUTCSeconds()
                  });
                } else if(typeof onDownloaded === 'function') {
                  onDownloaded(null, {
                    filename: name,
                    filesize: size,
                    time: date.getUTCFullYear() + "-" + (date.getUTCMonth() + 1) + "-" + date.getUTCDate() + " " + date.getUTCHours() + ":" + date.getUTCMinutes() + ":" + date.getUTCSeconds()
                  }
                  );
                }
                setHasFileName(true);
              }
              setIsDownloading(false);
              setProgress(0);
            } catch (err) {
              console.error(err);
              errorHandler(err);
              setIsDownloading(false);
              dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.downloadFail' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top' } } });
            }
          }

        } else {
          console.error(getResp);
          errorHandler(getResp);
          dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.downloadFail' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top' } } });
        }
      } catch (error) {
        console.error(error);
        errorHandler(error);
        dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.downloadFail' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top' } } });
      }
      
    }
  }

  const onFileSelected = (uuid, acceptedFiles) => {
    setUploadStyle({ color: green[500] });
    fileRef.current = null;
    if (acceptedFiles.length > 0) {
      onUpload(acceptedFiles[0], uuid);
    } else {
      console.log('no accepted file');
    }
  }

  var onUpload = async function (file, uuid) {
    setFileName(file.name);
    setFileSize(file.size);
    const methodParams = [Cookies.get('username'), file, type + '/' + uuid];

    let url = prePathname + '?uuid=' + uuid + '&type=' + type + '&action=0';
    if (file.name) {
      url += '&file=' + file.name + DEFALTE_EXT;
    }

    try {
      const getResp = await axiosService.get(url,
        {
          headers: {
            'Authorization': 'Bearer ' + Cookies.get('apiKey'),
            'X-Socket-ID': window.Echo ? window.Echo.socketId() : ''
          }
        }
      );
      console.log(getResp);

      if(!(getResp instanceof Error)) {
        successHandler(getResp);
        if (getResp.status === 200 && getResp.data.error === 0){
          setIsUploading(true);
          const azureResp = await upload(methodParams, getResp.data.message.sas, getResp.data.message.historyUuid, setProgress);

          console.log(azureResp);
          setIsUploading(false);
          const date = new Date();
          if (postPathname) {
            const postResp = await axiosService.post(
              postPathname + '?uuid=' + uuid + '&type=' + type + '&action=0' + (file.name ? '&file=' + file.name + DEFALTE_EXT : ""),
              {
                filename: file.name,
                filesize: file.size,
                result: azureResp,
                historyUuid: getResp.data.message.historyUuid,
              },
              {
                headers: {
                  'Authorization': 'Bearer ' + Cookies.get('apiKey'),
                  'X-Socket-ID': window.Echo ? window.Echo.socketId() : ''
                }
              }
            );
            successHandler(postResp);
            if (postResp.status === 200 && postResp.data.error === 0) {
              setHasFileName(true)
              onUploaded(postResp, {
                filename: file.name, filesize: file.size,
                time: date.getUTCFullYear() + "-" + (date.getUTCMonth() + 1) + "-" + date.getUTCDate() + " " + date.getUTCHours() + ":" + date.getUTCMinutes() + ":" + date.getUTCSeconds()
              });
            } else {
              console.error(postResp);
            }
          } else if (typeof onUploaded === 'function') {
            setHasFileName(true)
            onUploaded(getResp, {
              uuid: uuid,
              type: type,
              filename: file.name, filesize: file.size,
              time: date.getUTCFullYear() + "-" + (date.getUTCMonth() + 1) + "-" + date.getUTCDate() + " " + date.getUTCHours() + ":" + date.getUTCMinutes() + ":" + date.getUTCSeconds()
            });
          }
        } else {
          console.error(getResp);
        }
      } else {
        errorHandler(getResp);
        dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.uploadFail' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top' } } });
      }
      setProgress(0);
    } catch(error) {
      console.error(error);
      setIsUploading(false);
      setProgress(0);
      errorHandler(error);
      dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.uploadFail' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top' } } });
    }
  }

  return (<ButtonGroup  style={{ display: "inline-flex" }}>
    {(canUpload ?
      (isUploading ? <CircularProgress styleClasses={styleClasses.progress} variant="static" value={progress} />
        : <UploadButton uuid={uuid} deviceId={deviceId} patchId={patchId} type={type} fileType={fileType} identifier={identifier} uploadStyle={uploadStyle}
          onUploaded={onUploaded} onFileSelected={onFileSelected}/>)
      : null)}
    {(hasFileName ? 
      (canDownload ? 
        (isDownloading ? <CircularProgress styleClasses={styleClasses.progress} variant="static" value={progress} />
          : <DownloadButton uuid={uuid} fileSize={fileSize} fileName={fileName} type={type} onDownload={onDownload} downloadStyle={downloadStyle}></DownloadButton>) : 
        <DisableDownloadButton tooltip={humanFileSize(fileSize, true)} />)
      : (canUpload ? null : 
        <DisableDownloadButton tooltip={intl.formatMessage({ id: 'patientList.notUploadYet' })} />))}
    {type.toLowerCase().includes('report') && hasFileName ? 
      <>
        {canOpenPdfViewer === true ? <PdfViewerButton prePathname={prePathname} uuid={uuid} type={type} reportName={fileName} setDialogComponent={setDialogComponent} getReportViewerPath={getReportViewerPath}/> : null}
        {canOpenWebViewer === true ? <WebViewerButton prePathname={prePathname} uuid={uuid} signalType={isDemo ? 'signal-demo' : 'signal'} reportType={isDemo ? 'report-demo' : 'report'} signalName={signalName} reportName={fileName} setDialogComponent={setDialogComponent}/> : null}
        {canEditComment === true ? <EditViewerButton prePathname={prePathname} uuid={uuid} type={type} filename={fileName} filesize={fileSize} setDialogComponent={setDialogComponent} commentPathname={isDemo ? "/demo/report" : "/patientdetail/content/report"} isCommentModified={isCommentModified} /> : null}
      </> : null}
  </ButtonGroup>);
}

FileComp.defaultProps = {
  isCommentModified: false,
  isDemo: false,
  prePathname: undefined,
  postPathname: undefined,
}

FileComp.propTypes = {
  isCommentModified: PropTypes.bool,
  isDemo: PropTypes.bool,
  identifier: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  fileType: PropTypes.string.isRequired,
  canUpload: PropTypes.bool.isRequired,
  canDownload: PropTypes.bool.isRequired,
  canOpenWebViewer: PropTypes.bool.isRequired,
  canOpenPdfViewer: PropTypes.bool.isRequired,
  canEditComment: PropTypes.bool.isRequired,
  uuid: PropTypes.string.isRequired,
  deviceId: PropTypes.string.isRequired,
  patchId: PropTypes.string,
  inputFileName: PropTypes.string,
  inputFileSize: PropTypes.number,
  onUploaded: PropTypes.func,
  onDownloaded: PropTypes.func,
  signalName: PropTypes.string,
  setDialogComponent: PropTypes.func,
  prePathname: PropTypes.string,
  postPathname: PropTypes.string,
}

export default withStyles(dashboardStyle)(FileComp);