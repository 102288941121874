import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Poppers from "@material-ui/core/Popper";
import { Grid } from "../PatientDetail/PatientDetail";
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import { MenuProps } from "views/PatientDetail/PatientDetail.jsx";
import { FormGroup, FormLabel, Tooltip } from '@material-ui/core';
import {ArrowDropUp, ArrowDropDown, Close} from '@material-ui/icons';
import { useIntl, FormattedMessage } from "react-intl";
import moment from 'moment-timezone';
import { FormControlLabel } from '@material-ui/core';
import Timer from '@material-ui/icons/Timer';
import { useMappedState } from "redux-react-hook";
import { getAgeValue, parseGenderToString, parsePacemaker, parseDayVersion, parseIcd, abnormalList, genders } from '../PatientDetail/PatientDetail';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFns from '@date-io/date-fns';

const _ = require('lodash');

const ITEM_HEIGHT = 30;
const ITEM_PADDING_TOP = 4;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: '30%',
    right: '30%',
  },
  chip: {
    display: 'none',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
      marginTop: 'auto',
      marginBottom: 'auto',
    }
  },
  inputRoot: {
    flexWrap: 'wrap',
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1,
  },
  divider: {
    height: theme.spacing(2),
  },
  clearIndicator: {
    display: 'none',
  },
  clearIndicatorDirty: {
    display: 'none',
  },
  popupIndicator: {
    display: 'none',
  },
  popupIndicatorOpen: {
    display: 'none',
  },
  autocompleteInput: {
    width: '100%',
  }
}));

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  formControl: {
    marginTop: '16',
    marginBottom: '8',
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  group: {
    margin: `${theme.spacing.unit}px 0`,
    display: 'flex,'
  },
  gridItem: {
    textAlign: 'center'
  }
});

const tagRedTheme = createMuiTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: '#ff0000',
    },
  },
});

const tagGreenTheme = createMuiTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: '#008000',
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#00c853',
    },
  },
});

const tagOrangeTheme = createMuiTheme({
  palette: {
    primary: {
      // orange play nicely together.
      main: '#ffa500',
    },
    secondary: {
      // This is orange.A700 as hex.
      main: '#ff6d00',
    },
  },
});

const tagPinkTheme = createMuiTheme({
  palette: {
    primary: {
      // pink play nicely together.
      main: '#ffc0cb',
    },
    secondary: {
      // This is pink.A700 as hex.
      main: '#c51162',
    },
  },
});

const fillRange = (start, end) => {
  return Array(end - start + 1).fill().map((item, index) => start + index);
};

function PatientInfo(props) {

  const intl = useIntl();
  const classes = useStyles();
  const { deviceDayVersion, patientInfo, editable, onChange, setter, physicianList, locationList } = props;

  const menuRef = useRef(null);
  const [menuRefO, setMenuRefO] = useState(menuRef);
  
  const [openAgeSelect, setOpenAgeSelect] = useState(false);
  const [anchorElAge, setAnchorElAge] = useState(null);
  const [openPhysicianSelect, setOpenPhysicianSelect] = useState(false);
  const [anchorElPhysician, setAnchorElPhysician] = useState(null);
  const [openAbnormalSelect, setOpenAbnormalSelect] = useState(false);
  const [anchorElAbnormal, setAnchorElAbnormal] = useState(null);

  const [localPhysicians, setLocalPhysicians] = useState(JSON.parse(localStorage.getItem("physicianList")) || []);
  const lang = useMappedState(useCallback(state => state.setting.language));

  const parseExpectedTimeOfDate = (expectedDate) => {
    if(isNaN(Date.parse(expectedDate))) {
      return null;
    } else {
      return moment(expectedDate).format("YYYY-MM-DD");
    }
  }

  const parseExpectedTimeOfHour = (expectedDate) => {
    if(isNaN(Date.parse(expectedDate))) {
      return null;
    } else {
      return moment(expectedDate).format("HH:00");
    }
  }

  const parseGender = (gender) => {
    return gender === intl.formatMessage({ id: 'patientDetail.info.male' }) ? 0 :
      (gender === intl.formatMessage({ id: 'patientDetail.info.female' }) ? 1 : 2);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickAway = () => {
    setOpenAgeSelect(false);
    setOpenPhysicianSelect(false);
    setOpenAbnormalSelect(false);
  }

  const handleChange = (key, value) => {
    onChange(key, value, setter);
  };

  const handleChipChange = (index, key, value) => {
    var newTags = _.cloneDeep(patientInfo.tags);
    newTags[index][key] = value;
    handleChange('tags', newTags);
  };

  const getExpectedTimeOfHourListItems = () => {
    return fillRange(0, 23).map(hour => hour.toString().padStart(2, '0')+':00')
    .map( hour => (
      <MenuItem onClick={() => {
        console.log("expected time on click: " + hour);
        const date = moment.utc(patientInfo.expectedDate).local().format('YYYY-MM-DD')+ ' ' + hour;
        handleChange('expectedDate', moment(date).utc().format('YYYY-MM-DD HH:00:00'))
      }}
        disabled={moment().isAfter(moment(moment.utc(patientInfo.expectedDate).local().format('YYYY-MM-DD')+' '+hour))}
        className={classes.dropdownItem}
        key={hour} value={hour}>
        <ListItemText primary={hour} />
      </MenuItem>
    ));
  }

  const getAgeSelectListItems = () => {
    var range = fillRange(1, 120)
    range.push(0)
    return range.map(age => (
      <MenuItem onClick={() => {
        setOpenAgeSelect(!openAgeSelect);
        console.log("menuitem on click: " + age);
        handleChange('age', age);
      }}
        className={classes.dropdownItem}
        key={age} value={age}>
        <ListItemText primary={age === 0 ? intl.formatMessage({ id: 'patientDetail.info.unknown' }) : age} />
      </MenuItem>
    ));
  };

  return (
    <div xs={12} sm={12} md={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Grid container>
        {/* <Grid xs={12} item className={classes.chip}>
          <TagChip label={patientInfo.tags.length > 0 ? patientInfo.tags[0].TagName : ''} theme={tagRedTheme} tagKey={0} selected={patientInfo.tags.length > 0 ? patientInfo.tags[0].TagId : null}
            onChange={handleChipChange} />
          <TagChip label={patientInfo.tags.length > 1 ? patientInfo.tags[1].TagName : ''} theme={tagGreenTheme} tagKey={1} selected={patientInfo.tags.length > 1 ? patientInfo.tags[1].TagId : null}
            onChange={handleChipChange} />
          <TagChip label={patientInfo.tags.length > 2 ? patientInfo.tags[2].TagName : ''} theme={tagOrangeTheme} tagKey={2} selected={patientInfo.tags.length > 2 ? patientInfo.tags[2].TagId : null}
            onChange={handleChipChange} />
          <TagChip label={patientInfo.tags.length > 3 ? patientInfo.tags[3].TagName : ''} theme={tagPinkTheme} tagKey={3} selected={patientInfo.tags.length > 3 ? patientInfo.tags[3].TagId : null}
            onChange={handleChipChange} />
        </Grid> */}
        <Grid xs={6} item>
          <Tooltip style={{ width: '100%', maxWidth: '80%' }}
            title={patientInfo.device.DeviceId ? patientInfo.device.DeviceId.length + '/11' : '0/11'} enterDelay={500}>
            <TextField
              id="asynchronous-patient-deviceId"
              label={intl.formatMessage({ id: 'patientDetail.info.deviceId' })}
              required
              value={patientInfo.device.DeviceId}
              margin="normal"
              // error={patientInfo.device.DeviceId === ""}
              // helperText={patientInfo.device.DeviceId === "" ? intl.formatMessage({ id: 'patientDetail.info.emptyField' }) : ' '}
              disabled
              onInput={(e) => {
                if (e.target.value.length > 11) {
                  e.target.value = e.target.value.slice(0, 11);
                }
                handleChange('device', { Uuid: patientInfo.device.Uuid, DeviceId: e.target.value, Model: patientInfo.device.Model });
              }}
              helperText = { patientInfo.device.Model === 'UG01' ? intl.formatMessage({ id: 'patientDetail.info.patientIdText' }, { days: deviceDayVersion }) : null }
            />
          </Tooltip>
        </Grid>
        <Grid xs={6} item mx="auto">
          <Tooltip style={{ width: '100%', maxWidth: '80%' }}
            title={patientInfo.patch ? patientInfo.patch.length + '/11' : '0/11'} enterDelay={500}>
            <TextField
              id="patch-id"
              label={intl.formatMessage({ id: 'patientDetail.info.patch' })}
              value={patientInfo.patch}
              onInput={(e) => {
                if (e.target.value.length > 11) {
                  e.target.value = e.target.value.slice(0, 11);
                }
                handleChange('patch', e.target.value);
              }}
              margin="normal"
              disabled
              helperText={patientInfo.device.Model === 'UG02' ? intl.formatMessage({ id: 'patientDetail.info.patientIdText'}, {days: deviceDayVersion}) : null}
            />
          </Tooltip>
        </Grid>
        <Grid xs={patientInfo.estimatedDate ? 5 : 6}>
          <FormControl style={{ marginTop: '16px' }}>
            <Grid style={{ display: 'flex' }}>
              <Tooltip title={intl.formatMessage({ id: 'patientDetail.info.isUrgent' })} enterDelay={500}>
                  <FormControlLabel label={<Timer style={{ color: "orange", verticalAlign: "middle" }} />}
                    style={{marginLeft: '0px'}}
                    control={<Checkbox checked={patientInfo.isUrgent} onChange={(e) => {
                      console.log('isUrgent', e.target.checked)
                      handleChange('isUrgent', e.target.checked);
                    }} editable={!editable}
                    />}
                  />
              </Tooltip>
              <Tooltip title={patientInfo.urgentDescription ? patientInfo.urgentDescription.length + '/100' : '0/100'} enterDelay={500}>
                  <TextField
                    style={{ width: '100%' }}
                    multiline
                    required
                    id="patient-id"
                    label={intl.formatMessage({ id: 'patientDetail.info.urgentDescription' })}
                    value={patientInfo.urgentDescription}
                    onInput={(e) => {
                      if (e.target.value.length > 100) {
                        e.target.value = e.target.value.slice(0, 100);
                      }
                      handleChange('urgentDescription', e.target.value);
                    }}
                    disabled={!patientInfo.isUrgent || !editable}
                    error={patientInfo.isUrgent && patientInfo.urgentDescription.length === 0}
                    helperText={patientInfo.isUrgent && patientInfo.urgentDescription.length === 0 ? intl.formatMessage({ id: 'patientDetail.info.emptyField' }) : ''}
                  />
              </Tooltip>
            </Grid>
            <FormHelperText><FormattedMessage id={'patientDetail.info.urgentNote'} /></FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={patientInfo.estimatedDate ? 4 : 6} item>
          <FormGroup row>
            <FormControl style={{ marginTop: '16px' }}>
              {lang === 'en-US' ? 
              <MuiPickersUtilsProvider utils={DateFns} libInstance={moment}>
                <DatePicker 
                  id="patient-expected-date"
                  label={intl.formatMessage({ id: 'patientDetail.info.expectedDate' })}
                  type="text"
                  autoOk={true}
                  onChange={event => {
                    if (isNaN(Date.parse(moment(event).format('YYYY-MM-DD HH:mm:ss')))) {
                      handleChange('expectedDate', null);
                    } else {
                      if (parseExpectedTimeOfHour(moment(event).format('YYYY-MM-DD HH:mm:ss')) && parseExpectedTimeOfDate(moment(event).format('YYYY-MM-DD HH:mm:ss'))) {
                        if (isNaN(Date.parse(patientInfo.expectedDate))) {
                          if (moment().isAfter(moment(event))) {
                            handleChange('expectedDate', moment.utc().add(1, 'hours').format('YYYY-MM-DD HH:00:00'));
                          } else {
                            handleChange('expectedDate', moment(moment(event).format('YYYY-MM-DD')).utc().format('YYYY-MM-DD HH:00:00'));
                          }
                        } else {
                          if (moment().isAfter(moment(moment(event).format('YYYY-MM-DD') + ' ' + moment.utc(patientInfo.expectedDate).local().format('HH:00')))) {
                            handleChange('expectedDate', moment.utc().add(1, 'hours').format('YYYY-MM-DD HH:00:00'));
                          } else {
                            handleChange('expectedDate', moment(moment(event).format('YYYY-MM-DD') + ' ' + moment.utc(patientInfo.expectedDate).local().format('HH:00')).utc().format('YYYY-MM-DD HH:00:00'));
                          }
                        }
                      } else {
                        console.error('parse failed')
                        console.error(parseExpectedTimeOfHour(moment(event).format('YYYY-MM-DD HH:mm:ss')), parseExpectedTimeOfDate(moment(event).format('YYYY-MM-DD HH:mm:ss')))
                      }
                    }
                  }
                  }
                  // views={["date"]}
                  format={"dd/MM/yyyy"}
                  placeholder='DD/MM/YYYY'
                  value={!patientInfo.expectedDate || isNaN(Date.parse(patientInfo.expectedDate)) ? null:moment.utc(patientInfo.expectedDate).local()}
                  className={classes.textField}
                  clearable
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disablePast
                  error={patientInfo.isUrgent && isNaN(Date.parse(patientInfo.expectedDate))}
                  inputProps={patientInfo.isUrgent && isNaN(Date.parse(patientInfo.expectedDate)) ?
                    { style: { "color": "red" }, min: moment().format('YYYY-MM-DD') } :
                    { style: { "color": "black" }, min: moment().format('YYYY-MM-DD') }}
                  disabled={!patientInfo.isUrgent || !editable}
                  required
                  helperText={patientInfo.isUrgent && isNaN(Date.parse(patientInfo.expectedDate)) ? intl.formatMessage({ id: 'patientDetail.info.emptyField' }) : ''}
                />
              </MuiPickersUtilsProvider> :
              <TextField
                id="patient-expected-date"
                label={intl.formatMessage({ id: 'patientDetail.info.expectedDate' })}
                type="date"
                onChange={event => {
                  console.log('expected date event', event.target.value)
                  if (isNaN(Date.parse(event.target.value))) {
                    handleChange('expectedDate', null);
                  } else {
                    if (parseExpectedTimeOfHour(event.target.value) && parseExpectedTimeOfDate(event.target.value)) {
                      if (isNaN(Date.parse(patientInfo.expectedDate))) {
                        if (moment().isAfter(moment(event.target.value))) {
                          handleChange('expectedDate', moment.utc().add(1, 'hours').format('YYYY-MM-DD HH:00:00'));
                        } else {
                          handleChange('expectedDate', moment(event.target.value).utc().format('YYYY-MM-DD HH:00:00'));
                        }
                      } else {
                        if (moment().isAfter(moment(event.target.value + ' ' + moment.utc(patientInfo.expectedDate).local().format('HH:00')))) {
                          handleChange('expectedDate', moment.utc().add(1, 'hours').format('YYYY-MM-DD HH:00:00'));
                        } else {
                          handleChange('expectedDate', moment(event.target.value + ' ' + moment.utc(patientInfo.expectedDate).local().format('HH:00')).utc().format('YYYY-MM-DD HH:00:00'));
                        }
                      }
                    } else {
                      console.error('parse failed')
                      console.error(parseExpectedTimeOfHour(event.target.value), parseExpectedTimeOfDate(event.target.value))
                    }
                  }
                }
                }
                value={moment.utc(patientInfo.expectedDate).local().format('YYYY-MM-DD')}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                renderValue={moment.utc(patientInfo.expectedDate).local().format('YYYY-MM-DD')}
                error={patientInfo.isUrgent && isNaN(Date.parse(patientInfo.expectedDate))}
                inputProps={patientInfo.isUrgent && isNaN(Date.parse(patientInfo.expectedDate)) ?
                  { style: { "color": "red" }, min: moment().format('YYYY-MM-DD') } :
                  { style: { "color": "black" }, min: moment().format('YYYY-MM-DD') }}
                disabled={!patientInfo.isUrgent || !editable}
                required
                helperText={patientInfo.isUrgent && isNaN(Date.parse(patientInfo.expectedDate)) ? intl.formatMessage({ id: 'patientDetail.info.emptyField' }) : ''}
              />}
            </FormControl>
            <FormControl style={{ marginTop: '16px' }}>
              <TextField
                id="patient-expected-hour"
                label={' '}
                select
                className={classes.textField}
                SelectProps={{
                  SelectDisplayProps: patientInfo.isUrgent && isNaN(Date.parse(patientInfo.expectedDate)) ?
                    { style: { "color": "red", "padding-top": "0px", "padding-bottom": "0px" } } :
                    { style: { "color": "black", "padding-top": "0px", "padding-bottom": "0px" } }
                }}
                disabled={!patientInfo.isUrgent || !editable || !moment(patientInfo.expectedDate).isValid()}
                error={patientInfo.isUrgent && isNaN(Date.parse(patientInfo.expectedDate))}
                value={isNaN(Date.parse(patientInfo.expectedDate)) ? '00:00' : moment.utc(patientInfo.expectedDate).local().format('HH:00')}
                renderValue={isNaN(Date.parse(patientInfo.expectedDate)) ? '00:00' : moment.utc(patientInfo.expectedDate).local().format('HH:00')}
                helperText={patientInfo.isUrgent && isNaN(Date.parse(patientInfo.expectedDate)) ? intl.formatMessage({ id: 'patientDetail.info.emptyField' }) : ''}
              >
                {getExpectedTimeOfHourListItems()}
              </TextField>
            </FormControl>
          </FormGroup>
        </Grid>
        <Grid xs={3} style={{display: patientInfo.estimatedDate ? 'block':'none'}} item>
          <FormControl style={{ marginTop: '16px', width: '100%' }}>
            <Tooltip title={isNaN(Date.parse(patientInfo.estimatedDate)) ? "":(lang === 'en-US'? moment.utc(patientInfo.estimatedDate).local().format('DD/MM/YYYY HH:mm:ss'):moment.utc(patientInfo.estimatedDate).local().toDate().toLocaleString(navigator.language, { second: '2-digit', minute: '2-digit', hour: '2-digit', hour12: false, day: '2-digit', month: '2-digit', year: 'numeric' }))}>
              <TextField
                id="patient-estimated-date"
                label={intl.formatMessage({ id: 'patientDetail.info.estimatedDate' })}
                type="text"
                // onChange={event => handleChange('estimatedDate', moment(event.target.value).utc().format('YYYY-MM-DD HH:mm:ss'))}
                value={isNaN(Date.parse(patientInfo.estimatedDate)) ? "":(lang === 'en-US'? moment.utc(patientInfo.estimatedDate).local().format('DD/MM/YYYY HH:mm:ss'):moment.utc(patientInfo.estimatedDate).local().toDate().toLocaleString(navigator.language, { second: '2-digit', minute: '2-digit', hour: '2-digit', hour12: false, day: '2-digit', month: '2-digit', year: 'numeric' }))}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                renderValue={isNaN(Date.parse(patientInfo.estimatedDate)) ? "":(lang === 'en-US'? moment.utc(patientInfo.estimatedDate).local().format('DD/MM/YYYY HH:mm:ss'):moment.utc(patientInfo.estimatedDate).local().toDate().toLocaleString(navigator.language, { second: '2-digit', minute: '2-digit', hour: '2-digit', hour12: false, day: '2-digit', month: '2-digit', year: 'numeric' }))}
                inputProps={{ "readOnly": true, style: { "color": patientInfo.estimatedDate === "" ? "red" : "black" }}}
              />
            </Tooltip>
          </FormControl>
        </Grid>
        <Grid xs={3} item mx="auto">
          <Tooltip title={patientInfo.patientId ? patientInfo.patientId.length + '/40' : '0/40'} enterDelay={500}>
            <TextField
              id="patient-id"
              label={intl.formatMessage({ id: 'patientDetail.info.patientId' })}
              value={patientInfo.patientId}
              onInput={(e) => {
                if (e.target.value.length > 40) {
                  e.target.value = e.target.value.slice(0, 40);
                }
                handleChange('patientId', e.target.value);
              }}
              inputProps={{"readOnly": !!!patientInfo.canEditPatientId}}
              margin="normal"
              disabled={!editable}
            />
          </Tooltip>
        </Grid>
        <Grid xs={3} item mx="auto">
          <Tooltip title={patientInfo.name ? patientInfo.name.length + '/40' : '0/40'} enterDelay={500}>
              <TextField
                id="patient-name"
                label={intl.formatMessage({ id: 'patientDetail.info.name' })}
                value={patientInfo.name}
                onInput={(e) => {
                  if (e.target.value.length > 40) {
                    e.target.value = e.target.value.slice(0, 40);
                  }
                  handleChange('name', e.target.value);}}
                margin="normal"
                inputProps={{ "readOnly": !!!patientInfo.canEditName }}
                disabled={!editable}
              />
          </Tooltip>
        </Grid>
        <Grid xs={2} item>
          <FormControl style={{ marginTop: '16px', minWidth: '60px', maxWidth: '125px' }} required
            error={getAgeValue(patientInfo.age) === ''}>
            <InputLabel htmlFor="select-multiple-checkbox-age">
              <FormattedMessage id='patientDetail.info.age' />
            </InputLabel>
            <Input
              id="select-multiple-checkbox-age"
              type={'text'}
              disabled={!editable}
              value={getAgeValue(patientInfo.age)}
              onChange={(e) => {
                console.log("target in onChange: " + e.target.value);
                let validAge = e.target.value.replace(/[^0-9]/g, '');
                if (validAge === '') {
                  handleChange('age', undefined);
                } else {
                  if (parseInt(validAge, 10) < 1 || parseInt(validAge, 10) > 120)
                    return;
                  handleChange('age', parseInt(validAge, 10));
                }
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle age visibility"
                    disabled={!editable}
                    onClick={(e)=>{
                      setAnchorElAge(e.currentTarget);
                      setOpenAgeSelect(!openAgeSelect);
                    }}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {openAgeSelect ? <ArrowDropUp /> : <ArrowDropDown />}
                  </IconButton>
                  <Poppers
                    style={{ zIndex: 2000 }}
                    open={openAgeSelect}
                    anchorEl={anchorElAge}
                    transition
                    disablePortal
                    className={
                      classNames({ [classes.popperClose]: !openAgeSelect })
                    }
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        id="menu-list-grow"
                        style={{
                          transformOrigin:
                            placement === "bottom" ? "center top" : "center bottom"
                        }}
                      >
                        <Paper style={{ maxHeight: 200, overflow: 'auto' }}>
                          <ClickAwayListener onClickAway={handleClickAway}>
                            <MenuList role="menu" variant="selectedMenu">
                              {getAgeSelectListItems()}
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Poppers>
                </InputAdornment>
              }
            />
            <FormHelperText>{getAgeValue(patientInfo.age) === '' ? intl.formatMessage({ id: 'patientDetail.info.emptyField' }) : ' '}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={2} item>
          <FormControl style={{ marginTop: '16px' }}>
            {lang === 'en-US' ?
              <MuiPickersUtilsProvider utils={DateFns} libInstance={moment}>
                <DatePicker 
                  id="patient-birthday"
                  label={intl.formatMessage({ id: 'patientDetail.info.dateOfBirth' })}
                  type="text"
                  autoOk={true}
                  onChange={event => {
                      if(moment(event).isValid()) {
                        console.log('patient birth is valid');
                        handleChange('patientBirthday', moment(event).format('YYYY-MM-DD'));
                      } else {
                        console.log('patient birth is invalid');
                        handleChange('patientBirthday', null);
                      }
                    }
                  }
                  // views={["date"]}
                  format={"dd/MM/yyyy"}
                  placeholder='DD/MM/YYYY'
                  value={!patientInfo.patientBirthday || isNaN(Date.parse(patientInfo.patientBirthday)) ? null:moment.utc(patientInfo.patientBirthday).local()}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  clearable
                  disabled={!editable}
                />
              </MuiPickersUtilsProvider>:
              <TextField
                id="patient-birthday"
                label={intl.formatMessage({ id: 'patientDetail.info.dateOfBirth' })}
                type="date"
                onChange={event => handleChange('patientBirthday', moment(event.target.value).format('YYYY-MM-DD'))}
                value={isNaN(Date.parse(patientInfo.patientBirthday)) ? "" : moment(patientInfo.patientBirthday).format('YYYY-MM-DD')}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  onKeyDown: (event) => {
                    event.preventDefault();
                  }}
                }
                renderValue={isNaN(Date.parse(patientInfo.patientBirthday)) ? "" : moment(patientInfo.patientBirthday).format('YYYY-MM-DD')}
                disabled={!editable}
              />}
          </FormControl>
        </Grid>
        <Grid xs={2} item>
          <FormControl style={{ marginTop: '16px', minWidth: '60px' }} required
            error={parseGenderToString(patientInfo.gender) === ''}>
            <InputLabel htmlFor="select-multiple-checkbox-sex">
              <FormattedMessage id='patientDetail.info.sex' />
            </InputLabel>
            <Select
              value={parseGenderToString(patientInfo.gender)}
              onChange={event => {
                handleChange('gender', parseGender(event.target.value));
              }}
              input={<Input id="select-multiple-checkbox-sex" />}
              renderValue={selected => selected}
              MenuProps={MenuProps}
              disabled={!editable}
            >
              {genders().map(gender => (
                <MenuItem key={parseGender(gender)} value={gender}>
                  <ListItemText primary={gender} />
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{parseGenderToString(patientInfo.gender) === '' ? intl.formatMessage({ id: 'patientDetail.info.emptyField' }) : ' '}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={2} item>
          <FormControl style={{ marginTop: '16px' }}>
            <InputLabel htmlFor="select-multiple-checkbox-pacemaker" shrink={true}>
              <FormattedMessage id='patientDetail.info.pacemaker' />
            </InputLabel>
            <Select
              value={patientInfo.paceMaker}
              onChange={event => handleChange('paceMaker', event.target.value)}
              input={<Input id="select-multiple-checkbox-pacemaker" />}
              renderValue={selected => parsePacemaker(selected)}
              MenuProps={MenuProps}
              disabled={!editable}
            >
              {fillRange(0, 5).map(type => (
                <MenuItem key={type} value={type}>
                  <ListItemText primary={parsePacemaker(type)} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={2} item>
          <FormControl style={{ marginTop: '16px' }}>
            <InputLabel htmlFor="select-multiple-checkbox-icd" shrink={true}>
              <FormattedMessage id='patientDetail.info.icd' />
            </InputLabel>
            <Select
              value={patientInfo.icd}
              onChange={event => handleChange('icd', event.target.value)}
              input={<Input id="select-multiple-checkbox-icd" />}
              renderValue={selected => parseIcd(selected)}
              MenuProps={MenuProps}
              disabled={!editable}
            >
              {[true, false].map(icd => (
                <MenuItem key={icd} value={icd}>
                  <ListItemText primary={parseIcd(icd)} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={2} item>
          <FormControl style={{ marginTop: '16px' }}>
            <InputLabel htmlFor="select-multiple-checkbox-rfca" shrink={true}>
              <FormattedMessage id='patientDetail.info.rfca' />
            </InputLabel>
            <Select
              value={patientInfo.electrocauterization}
              onChange={event => handleChange('electrocauterization', event.target.value)}
              input={<Input id="select-multiple-checkbox-rfca" />}
              renderValue={selected => parseIcd(selected)}
              MenuProps={MenuProps}
              disabled={!editable}
            >
              {[true, false].map(electrocauterization => (
                <MenuItem key={electrocauterization} value={electrocauterization}>
                  <ListItemText primary={parseIcd(electrocauterization)} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={3} item>
          <Tooltip title={patientInfo.location.Name} enterDelay={500}>
            <FormControl style={{ marginTop: '16px' }} required
              error={patientInfo.location.Name === ""}>
              <InputLabel htmlFor="select-multiple-checkbox-location" >
                <FormattedMessage id='patientDetail.info.location' />
              </InputLabel>
              <Select
                style={{ minWidth: 120, maxWidth: 200 }}
                value={patientInfo.location.Name}
                onChange={event => handleChange('location', locationList.find(function (l) {
                  return l.Uuid === event.target.value;
                }))
                }
                input={<Input id="select-multiple-checkbox-location" />}
                renderValue={selected => selected}
                // MenuProps={MenuProps}
                disabled={!editable}
                SelectDisplayProps={{ style: { "userSelect": "text" } }}
              >
                {locationList.sort((a, b) => {
                  return a.Name.toUpperCase().localeCompare(b.Name.toUpperCase(), "zh-hant")
                }).map(location => (
                  <MenuItem key={location.Uuid} value={location.Uuid}>
                    <ListItemText primary={location.Name} />
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{patientInfo.location.Name === "" ? intl.formatMessage({ id: 'patientDetail.info.emptyField' }) : ' '}</FormHelperText>
            </FormControl>
          </Tooltip>
        </Grid>
        <Grid xs={3} item>
          <Tooltip title={patientInfo.physicianName ? patientInfo.physicianName.length + '/40' : '0/40'} enterDelay={500}>
            <FormControl style={{ marginTop: '16px' }} >
              <InputLabel htmlFor="select-multiple-checkbox-physician">
                <FormattedMessage id='patientDetail.info.physician' />
              </InputLabel>
              <Input
                id="select-multiple-checkbox-physician"
                type={'text'}
                disabled={!editable}
                value={patientInfo.physicianName}
                onChange={(e) => {
                  if (e.target.value.length > 40) {
                    e.target.value = e.target.value.slice(0, 40);
                  }
                  handleChange('physicianName', e.target.value);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle physician visibility"
                      disabled={!editable || localPhysicians.length === 0}
                      onClick={(e) => {
                        setAnchorElPhysician(e.currentTarget);
                        setOpenPhysicianSelect(!openPhysicianSelect);
                      }}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {openPhysicianSelect ? <ArrowDropUp /> : <ArrowDropDown />}
                    </IconButton>
                    <Poppers
                      style={{ zIndex: 2000 }}
                      open={openPhysicianSelect}
                      anchorEl={anchorElPhysician}
                      transition
                      disablePortal
                      className={
                        classNames({ [classes.popperClose]: !openPhysicianSelect })
                      }
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          id="menu-list-grow"
                          style={{
                            transformOrigin:
                              placement === "bottom" ? "center top" : "center bottom"
                          }}
                        >
                          <Paper style={{ maxHeight: 200, overflow: 'auto' }}>
                            <ClickAwayListener onClickAway={handleClickAway}>
                              <MenuList role="menu" variant="selectedMenu">
                                {localPhysicians.map(physician => (
                                  <MenuItem onClick={() => {
                                    setOpenPhysicianSelect(!openPhysicianSelect);
                                    handleChange('physicianName', physician);
                                  }}
                                    className={classes.dropdownItem}
                                    key={physician} value={physician}>
                                    <ListItemText primary={physician} />
                                    <IconButton
                                      onClick={(e) => {
                                        const idx = localPhysicians.indexOf(physician);
                                        console.log('index of physician: ', physician, idx);
                                        if (idx !== -1) {
                                          let removed = localPhysicians.filter((value, index, arr) => {
                                            return index !== idx;
                                          });
                                          // removed = removed.filter(idx, 1);
                                          console.log("removed physicians: ", removed);
                                          localStorage.setItem("physicianList", JSON.stringify(removed));
                                          setLocalPhysicians(removed);
                                        }
                                        e.stopPropagation();
                                      }}><Close /></IconButton>
                                  </MenuItem>
                                ))}
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Poppers>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Tooltip>
        </Grid>
        <Grid xs={3} item>
          <Tooltip style={{ width: '100%', maxWidth: '80%' }}
            title={patientInfo.checkId ? patientInfo.checkId.length + '/40' : '0/40'} enterDelay={500}>
            <TextField
              id="patient-checkId"
              label={intl.formatMessage({ id: 'patientDetail.info.checkId'})}
              value={patientInfo.checkId}
              margin="normal"
              inputProps={{ "readOnly": !!!patientInfo.canEditCheckId }}
              disabled={!editable}
              onInput={(e) => {
                if(e.target.value.length > 40) {
                  e.target.value = e.target.value.slice(0, 40);
                }
                handleChange('checkId', e.target.value);
              }}
            />
          </Tooltip>
        </Grid>
        
        <Grid xs={3} item>
          <FormControl style={{ marginTop: '16px' }} required
            error={isNaN(Date.parse(patientInfo.setupDate))}>
            {lang === 'en-US' ? 
            <MuiPickersUtilsProvider utils={DateFns} libInstance={moment}>
              <DatePicker 
                id="patient-setup-date"
                label={intl.formatMessage({ id: 'patientDetail.info.setupDate' })}
                type="text"
                autoOk={true}
                onChange={event => {
                  if(moment(event).isValid()) {
                    handleChange('setupDate', moment(event.toDateString()).format('YYYY-MM-DD'));
                  } else {
                    handleChange('setupDate', null);
                  }
                }}
                // views={["date"]}
                format={"dd/MM/yyyy"}
                placeholder='DD/MM/YYYY'
                value={!patientInfo.setupDate || isNaN(Date.parse(patientInfo.setupDate)) ? null:moment.utc(patientInfo.setupDate).local()}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                error={isNaN(Date.parse(patientInfo.setupDate))}
                inputProps={isNaN(Date.parse(patientInfo.setupDate)) ?
                  { style: { "color": "red" } } :
                  { style: { "color": "black" } }}
                disabled={!editable}
                clearable
                required
                helperText={isNaN(Date.parse(patientInfo.setupDate)) ? intl.formatMessage({ id: 'patientDetail.info.emptyField' }) : ' '}
              />
            </MuiPickersUtilsProvider> :
            <TextField
              id="patient-setup-date"
              label={intl.formatMessage({ id: 'patientDetail.info.setupDate' })}
              type="date"
              onChange={event => handleChange('setupDate', moment(event.target.value).format('YYYY-MM-DD'))}
              value={isNaN(Date.parse(patientInfo.setupDate)) ? "" : moment(patientInfo.setupDate).format('YYYY-MM-DD')}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              renderValue={isNaN(Date.parse(patientInfo.setupDate)) ? "" : moment(patientInfo.setupDate).format('YYYY-MM-DD')}
              error={isNaN(Date.parse(patientInfo.setupDate))}
              inputProps={isNaN(Date.parse(patientInfo.setupDate)) ? {
                style: { "color": "red" }, onKeyDown: (event) => {
                  event.preventDefault();
                }
              } : {
                style: { "color": "black" }, onKeyDown: (event) => {
                  event.preventDefault();
                }
              }}
              disabled={!editable}
              required
              helperText={isNaN(Date.parse(patientInfo.setupDate)) ? intl.formatMessage({ id: 'patientDetail.info.emptyField' }) : ' '}
            />}
          </FormControl></Grid>
        <Grid xs={3} item>
          <FormControl style={{ marginTop: '16px' }} required
            error={patientInfo.dayVersion === 0}>
            <InputLabel htmlFor="select-multiple-dropbox-day-version" shrink={patientInfo.dayVersion !== 0} style={{ color: patientInfo.dayVersion === 0 ? "red" : "black", minWidth: 'max-content'}}>
              <FormattedMessage id='patientDetail.info.dayVersion' />
            </InputLabel>
            <Select
              style={{ minWidth: 120}}
              value={patientInfo.dayVersion}
              onChange={event => handleChange('dayVersion', event.target.value)}
              input={<Input id="select-multiple-dropbox-day-version" />}
              renderValue={selected => parseDayVersion(selected)}
              MenuProps={MenuProps}
              disabled={!editable}
            >
              {[1, 2, 3, 7, 14].map(dayVersion => (
                <MenuItem key={dayVersion} value={dayVersion}>
                  <ListItemText primary={parseDayVersion(dayVersion)} />
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{patientInfo.dayVersion === 0 ? intl.formatMessage({ id: 'patientDetail.info.emptyField' }) : ' '}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={3} item>
          <Tooltip title={patientInfo.interpretedDoctor ? patientInfo.interpretedDoctor.length + '/40' : '0/40'} enterDelay={500}>
            <FormControl style={{ marginTop: '16px' }} >
              <InputLabel htmlFor="select-multiple-checkbox-interpretedDoctor">
                <FormattedMessage id='patientDetail.info.interpretedDoctor' />
              </InputLabel>
              <Input
                id="select-multiple-checkbox-interpretedDoctor"
                type={'text'}
                disabled={!editable}
                value={patientInfo.interpretedDoctor}
                onChange={(e) => {
                  if (e.target.value.length > 40) {
                    e.target.value = e.target.value.slice(0, 40);
                  }
                  handleChange('interpretedDoctor', e.target.value);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle physician visibility"
                      disabled={!editable || localPhysicians.length === 0}
                      onClick={(e) => {
                        setAnchorElPhysician(e.currentTarget);
                        setOpenPhysicianSelect(!openPhysicianSelect);
                      }}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {openPhysicianSelect ? <ArrowDropUp /> : <ArrowDropDown />}
                    </IconButton>
                    <Poppers
                      style={{ zIndex: 2000 }}
                      open={openPhysicianSelect}
                      anchorEl={anchorElPhysician}
                      transition
                      disablePortal
                      className={
                        classNames({ [classes.popperClose]: !openPhysicianSelect })
                      }
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          id="menu-list-grow"
                          style={{
                            transformOrigin:
                              placement === "bottom" ? "center top" : "center bottom"
                          }}
                        >
                          <Paper style={{ maxHeight: 200, overflow: 'auto' }}>
                            <ClickAwayListener onClickAway={handleClickAway}>
                              <MenuList role="menu" variant="selectedMenu">
                                {localPhysicians.map(physician => (
                                  <MenuItem onClick={() => {
                                    setOpenPhysicianSelect(!openPhysicianSelect);
                                    handleChange('interpretedDoctor', physician);
                                  }}
                                    className={classes.dropdownItem}
                                    key={physician} value={physician}>
                                    <ListItemText primary={physician} />
                                    <IconButton
                                      onClick={(e) => {
                                        const idx = localPhysicians.indexOf(physician);
                                        console.log('index of physician: ', physician, idx);
                                        if (idx !== -1) {
                                          let removed = localPhysicians.filter((value, index, arr) => {
                                            return index !== idx;
                                          });
                                          // removed = removed.filter(idx, 1);
                                          console.log("removed physicians: ", removed);
                                          localStorage.setItem("physicianList", JSON.stringify(removed));
                                          setLocalPhysicians(removed);
                                        }
                                        e.stopPropagation();
                                      }}><Close /></IconButton>
                                  </MenuItem>
                                ))}
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Poppers>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Tooltip>
        </Grid>
        <Grid xs={3} item style={{ display: "none" }}>
          <FormControl style={{ marginTop: '16px' }}>
            <InputLabel htmlFor="select-multiple-checkbox-physician">
              <FormattedMessage id='patientDetail.info.physician' />
            </InputLabel>
            <Select
              style={{ minWidth: 120, maxWidth: 250 }}
              value={patientInfo.physician.map(physician => physician.Uuid)}
              multiple
              onChange={event => handleChange('physician', event.target.value.map(v => physicianList.find(function (p) {
                return p.Uuid === v;
              })))
              }
              input={<Input id="select-multiple-checkbox-physician" />}
              renderValue={selected => selected.map(s => {
                const f = physicianList.find(function (p) {
                  return p.Uuid === s;
                });
                if (f) {
                  return f.Name;
                } else {
                  return '';
                }
              }).join(', ')}
              disabled={!editable}
              ref={menuRefO}
              onOpen={(e) => {
                setMenuRefO(e.currentTarget);
              }}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                getContentAnchorEl: () => {
                  return menuRefO.current;
                },
                PaperProps: {
                  style: {
                    maxHeight: ITEM_HEIGHT * 9 + ITEM_PADDING_TOP,
                    width: 250,
                  },
                },
              }}
            >
              {physicianList.map(physician => (
                <MenuItem key={physician.Uuid} value={physician.Uuid}>
                  <Checkbox checked={patientInfo.physician.map(physician => physician.Uuid).indexOf(physician.Uuid) > -1} />
                  <ListItemText primary={physician.Name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {/* <Grid xs={4} item style={{ display: "none" }}>
          <FormControl style={{ marginTop: '16px' }}>
            <InputLabel htmlFor="select-multiple-checkbox-model">
              <FormattedMessage id='patientDetail.info.model' />
            </InputLabel>
            <Select
              readOnly
              value={patientInfo.device.Model}
              onChange={event => handleChange('device', { Uuid: patientInfo.device.Uuid, DeviceId: patientInfo.device.DeviceId, Model: event.target.value })}
              input={<Input id="select-multiple-checkbox-model" />}
              renderValue={selected => selected}
              MenuProps={MenuProps}
              disabled={!editable}
            >
              {['UG01', 'UG02'].map(type => (
                <MenuItem key={type} value={type}>
                  <ListItemText primary={type} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={4}></Grid> */}
        <Grid xs={4} item>
          <Tooltip disableTouchListener={patientInfo.abnormal.key !== 3} disableFocusListener={patientInfo.abnormal.key !== 3} disableHoverListener={patientInfo.abnormal.key !== 3} title={patientInfo.abnormal.value.length + '/100'} enterDelay={500}>
            <FormControl style={{ marginTop: '16px', minWidth: '150px' }}>
              <InputLabel htmlFor="select-multiple-checkbox-abnormal">
                <FormattedMessage id='patientDetail.info.abnormal' />
              </InputLabel>
              <Input
                id="select-multiple-checkbox-abnormal"
                type={'text'}
                disabled={!editable}
                value={patientInfo.abnormal.key === 3 ? intl.formatMessage({ id: 'patientDetail.info.abnormal.other'}) + ": " + patientInfo.abnormal.value : abnormalList().find(v=>v.key === patientInfo.abnormal.key).value}
                readOnly={patientInfo.abnormal.key !== 3}
                onChange={(e) => {
                  let commaIdx = e.target.value.indexOf(':');
                  if(commaIdx === -1) {
                    if (e.target.value.length > 100)
                      return;
                    handleChange('abnormal', { key: 3, value: e.target.value});
                  }
                  else {
                    if (e.target.value.substr(commaIdx + 2).length > 100)
                      return;
                    handleChange('abnormal', { key: 3, value: e.target.value.substr(commaIdx+2) });
                  }
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle abnormal visibility"
                      disabled={!editable}
                      onClick={(e) => {
                        setAnchorElAbnormal(e.currentTarget);
                        setOpenAbnormalSelect(!openAbnormalSelect);
                      }}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {openAbnormalSelect ? <ArrowDropUp /> : <ArrowDropDown />}
                    </IconButton>
                    <Poppers
                      style={{ zIndex: 2000 }}
                      open={openAbnormalSelect}
                      anchorEl={anchorElAbnormal}
                      transition
                      disablePortal
                      className={
                        classNames({ [classes.popperClose]: !openAbnormalSelect })
                      }
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          id="menu-list-grow"
                          style={{
                            transformOrigin:
                              placement === "bottom" ? "center top" : "center bottom"
                          }}
                        >
                          <Paper style={{ maxHeight: 200, overflow: 'auto' }}>
                            <ClickAwayListener onClickAway={handleClickAway}>
                              <MenuList role="menu" variant="selectedMenu">
                                {abnormalList().map(abnormal => (
                                  <MenuItem onClick={() => {
                                    setOpenAbnormalSelect(!openAbnormalSelect);
                                    // abnormalSelected.setIsAbnormalSelected(true);
                                    console.log("menuitem on click: " + abnormal.value);
                                    if(abnormal.key === 3)
                                      handleChange('abnormal', {key: abnormal.key, value: ''});
                                    else
                                      handleChange('abnormal', abnormal);
                                  }}
                                    className={classes.dropdownItem}
                                    key={abnormal.key} value={abnormal.value}>
                                    <ListItemText primary={abnormal.value} />
                                  </MenuItem>
                                ))}
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Poppers>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Tooltip>
        </Grid>
        <Grid xs={1} />
        <Grid xs={7} item>
          <Tooltip title={patientInfo.comment ? patientInfo.comment.length + '/1000' : '0/1000'} enterDelay={500}>
            <TextField
              id="patient-comment"
              label={intl.formatMessage({ id: 'patientDetail.info.comment' })}
              value={patientInfo.comment}
              onInput={(e) => {
                if (e.target.value.length > 1000) {
                  e.target.value = e.target.value.slice(0, 1000);
                }
                handleChange('comment', e.target.value);
              }}
              fullWidth
              margin="normal"
              disabled={!editable}
            />
          </Tooltip>
        </Grid>
        {/* <Grid xs={1}/>
        <Grid xs={10} item>
          <Tooltip title={patientInfo.indication ? patientInfo.indication.length+'/1000' : '0/1000'} enterDelay={500}>
            <TextField
              id="patient-indication"
              label={intl.formatMessage({ id: 'patientDetail.info.indication' })}
              multiline
              value={patientInfo.indication}
              onInput={(e) => {
                if (e.target.value.length > 1000) {
                  e.target.value = e.target.value.slice(0, 1000);
                }
                handleChange('indication', e.target.value);
              }}
              margin="normal"
              fullWidth
              disabled={!editable}
            />
          </Tooltip>
        </Grid> */}
      </Grid>
    </div>
  );
}

PatientInfo.propTypes = {
  deviceDayVersion: PropTypes.number,
  isNewRecord: PropTypes.bool,
  physicianList: PropTypes.array,
  locationList: PropTypes.array,
  hospitalStaffList: PropTypes.array,
  patientInfo: PropTypes.object.isRequired,
  editable: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  setter: PropTypes.func.isRequired
};

export default withStyles(styles)(PatientInfo);