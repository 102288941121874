import { ACTIONTYPE_VIEW,
  ACTIONTYPE_ANALYSTLIST, ACTIONTYPE_PHYSICIANLIST, ACTIONTYPE_LOCATIONLIST, ACTIONTYPE_HOSPITALSTAFFLIST,
  ACTIONTYPE_PATIENTLIST_COUNT, 
  ACTIONTYPE_PATIENTLIST_ADD, ACTIONTYPE_PATIENTLIST_REMOVEALL, ACTIONTYPE_PATIENTLIST_UPDATE,
  ACTIONTYPE_SETTING_LANGUAGE,
  ACTIONTYPE_SETTING_TO_LOGIN,
  ACTIONTYPE_SETTING_TO_RESET_PASSWORD,
  ACTIONTYPE_SETTING_TO_DEMO_VIEW,
  ACTIONTYPE_SETTING_TO_PATIENTLIST,
  ACTIONTYPE_SETTING_ROLE,
  ACTIONTYPE_MESSAGE_CONFIG,
  ACTIONTYPE_TIMER_APIKEYTIMER,
ACTIONTYPE_REPORT_EDIT_LOADING} from '../actionTypes';

export default function reducer(
  state,
  action,
) {
  if (!state) {
    return null;
  }

  switch (action.type) {
    case ACTIONTYPE_REPORT_EDIT_LOADING: {
      return {
        ...state,
        view: {
          reportEditLoading: action.value,
        }
      }
    }
    case ACTIONTYPE_TIMER_APIKEYTIMER: {
      if(state.timer.apiKeyTimer) {
        clearTimeout(state.timer.apiKeyTimer);
      }
      return {
        ...state,
        timer: {
          apiKeyTimer: action.value,
        }
      }
    }
    case ACTIONTYPE_VIEW: {
      return {
        ...state,
        view: {
          mobileOpen: action.value.mobileOpen,
        }
      };
    }
    case ACTIONTYPE_PATIENTLIST_REMOVEALL: {
      return {
        ...state,
        patientList: {
          ...state.patientList,
          listContent: action.value.listContent,
        }
      };
    }
    case ACTIONTYPE_PATIENTLIST_COUNT: {
      return {
        ...state,
        patientList: {
          ...state.patientList,
          count: action.value,
        }
      };
    }
    case ACTIONTYPE_PATIENTLIST_ADD: {
      return {
        ...state,
        patientList: {
          ...state.patientList,
          listContent: addItem(state.patientList.listContent, action.value),
        }
      };
    }
    case ACTIONTYPE_PATIENTLIST_UPDATE: {
      return {
        ...state,
        patientList: {
          ...state.patientList,
          listContent: state.patientList.listContent.map((item) => {
            if (item.uuid === action.value.uuid) {
              return action.value;
            }
            return item;
          })
        }
      };
    }
    case ACTIONTYPE_ANALYSTLIST: {
      return {
        ...state,
        initialData: {
          ...state.initialData,
          analystList: action.value.analystList
        }
      };
    }
    case ACTIONTYPE_PHYSICIANLIST: {
      return {
        ...state,
        initialData: {
          ...state.initialData,
          physicianList: action.value.physicianList
        }
      };
    }
    case ACTIONTYPE_LOCATIONLIST: {
      return {
        ...state,
        initialData: {
          ...state.initialData,
          locationList: action.value.locationList
        }
      };
    }
    case ACTIONTYPE_HOSPITALSTAFFLIST: {
      return {
        ...state,
        initialData: {
          ...state.initialData,
          hospitalStaffList: action.value.hospitalStaffList
        }
      };
    }
    case ACTIONTYPE_SETTING_LANGUAGE: {
      return {
        ...state,
        setting: {
          ...state.setting,
          language: action.value.language,
        }
      }
    }
    case ACTIONTYPE_MESSAGE_CONFIG: {
      return {
        ...state,
        message:{
          ...state.message,
          open: action.value.open,
          message: action.value.message ? action.value.message : state.message.message,
          anchorOrigin: action.value.anchorOrigin ? action.value.anchorOrigin : state.message.anchorOrigin,
          color: action.value.color ? action.value.color : state.message.color,
          timeout: action.value.timeout,
          key: action.value.key
        }
      }
    }
    case ACTIONTYPE_SETTING_TO_LOGIN: {
      return {
        ...state,
        setting: {
          ...state.setting,
          toLogin: action.value.toLogin,
        }
      }
    }
    case ACTIONTYPE_SETTING_TO_RESET_PASSWORD: {
      return {
        ...state,
        setting: {
          ...state.setting,
          toResetPassword: action.value.toResetPassword,
        }
      }
    }
    case ACTIONTYPE_SETTING_TO_DEMO_VIEW: {
      return {
        ...state,
        setting: {
          ...state.setting,
          toDemoView: action.value.toDemoView,
        }
      }
    }
    case ACTIONTYPE_SETTING_TO_PATIENTLIST: {
      return {
        ...state,
        setting: {
          ...state.setting,
          toPatientList: action.value.toPatientList,
        }
      }
    }
    case ACTIONTYPE_SETTING_ROLE: {
      return {
        ...state,
        setting: {
          ...state.setting,
          role: action.value.role,
        }
      }
    }

    default:
      return state;
  }
}

function addItem(array, action) {
  let newArray = array.slice();
  newArray.splice(action.listContentIndex, 0, action.listContentItem);
  return newArray;
}

// function removeItem(array, action) {
//   let newArray = array.slice();
//   newArray.splice(action.index, 1);
//   return newArray;
// }

/*const initialState = {
  login: [],
  byIds: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ADD_TODO: {
      const { id, content } = action.payload;
      return {
        ...state,
        allIds: [...state.allIds, id],
        byIds: {
          ...state.byIds,
          [id]: {
            content,
            completed: false
          }
        }
      };
    }
    case TOGGLE_TODO: {
      const { id } = action.payload;
      return {
        ...state,
        byIds: {
          ...state.byIds,
          [id]: {
            ...state.byIds[id],
            completed: !state.byIds[id].completed
          }
        }
      };
    }
    default:
      return state;
  }
}*/
