import { saveAs } from 'file-saver';
import {
  BlockBlobClient,
  AnonymousCredential
} from "@azure/storage-blob";
import { setLogLevel } from "@azure/logger";
import Uploader from 'worker-loader!./upload.worker.js';
import Downloader from 'worker-loader!./download.worker.js';
import Deflator from 'worker-loader!./deflate.worker.js';
import Inflator from 'worker-loader!./inflate.worker.js';
export const DEFALTE_EXT = '.deflate';

setLogLevel("verbose");


export function useAzureWorkers() {


  var BLOCK_BLOB_MAX_UPLOAD_BLOB_BYTES = 256 * 1024 * 1024; // 256MB
  var BLOCK_BLOB_MAX_STAGE_BLOCK_BYTES = 4000 * 1024 * 1024; // 4000MB
  var BLOCK_BLOB_MAX_BLOCKS = 50000;
  var DEFAULT_BLOCK_BUFFER_SIZE_BYTES = 8 * 1024 * 1024; // 8MB
  var DEFAULT_BLOB_DOWNLOAD_BLOCK_BYTES = 4 * 1024 * 1024; // 4MB
  var DEFAULT_MAX_DOWNLOAD_RETRY_REQUESTS = 5;

  function readFile(file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();

      reader.onabort = function (event) {
        console.log("on abort");
        reject(event);
      }

      reader.onerror = function (event) {
        console.log("on error");
        reject(event);
      };

      reader.onload = function () {
        console.log("file read success");
        resolve(reader.result);
      };

      reader.readAsArrayBuffer(file);
    });
  }

  function compress(file, setProgress) {
    return new Promise((resolve, reject) => {

      try {
        const worker = new Deflator();

        worker.onmessage = function (e) {
          if (e.data.error === 0) {
            if (e.data.status) {
              setProgress(e.data.status * 25);
            } else{
              console.log(e);
              if (e.data.message) {
                console.log(e.data.message.byteLength);
                setProgress(25);
                resolve(e.data.message);
              }
            }
          } else {
            console.error(e);
            reject(e);
          }
        };

        worker.postMessage(file);
      } catch (e) {
        console.error(e);
      }
    });

  }

  function decompress(fileContent, fileSize, setProgress) {
    return new Promise((resolve, reject) => {

      try {
        const worker = new Inflator();

        worker.onmessage = function (e) {
          if (e.data.error === 0) {
            if (e.data.status) {
              setProgress(75 + e.data.status * 25);
            } else {
              console.log(e);
              if (e.data.message) {
                console.log(e.data.message.byteLength);
                setProgress(100);
                resolve(e.data.message);
              }
            }
          } else {
            console.error(e);
            reject(e);
          }
        };

        worker.postMessage({ size: fileSize,
          data: fileContent
        });
      } catch (e) {
        console.error(e);
      }
    });
  }

  function azureUpload(props, setProgress) {
    return new Promise((resolve, reject) => {
      try {
        console.log('create upload worker');
        const worker = new Uploader();
        console.log(worker);
        worker.onmessage = function (e) {
          console.log(e.data);
          if (e.data.error === 0) {
            if (e.data.status) {
              setProgress(e.data.status);
            } else {
              console.log(e);
              if (e.data.message) {
                console.log(e.data.message);
                setProgress(100);
                resolve(e.data.message);
              }
            }
          } else {
            console.error(e);
            reject(e);
          }
        };

        console.log('post message to upload worker');

        worker.postMessage(props);

        console.log('worker post message finish');
      } catch (e) {
        console.error(e);
      }
    });
  }

  async function upload(props, sas, historyUuid, setProgress) {
    const [username, file, containerName] = props;
    try {
      const url = "https://sigknowsoutheastasia.blob.core.windows.net/" + containerName + "/" + file.name + '.deflate' + "?" + sas;

      console.log(Date.now());
      const publicIp = require('public-ip');
      const pubip = await publicIp.v4();

      const uploadResult = await azureUpload(
        {
          username: username ? username : "",
          ip: pubip ? pubip : "",
          uuid: historyUuid ? historyUuid : "",
          file: file,
          url: url,
          blockSize: DEFAULT_BLOB_DOWNLOAD_BLOCK_BYTES,
          concurrentCount: 3
        }, setProgress);

      console.log(uploadResult);

    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  function azureDownload(props, setProgress) {
    return new Promise((resolve, reject) => {
      try {
        console.log('create download worker');
        const worker = new Downloader();
        console.log(worker);
        worker.onmessage = function (e) {
          console.log(e.data);
          if (e.data.error === 0) {
            if (e.data.status) {
              setProgress(e.data.status);
            } else {
              console.log(e);
              if (e.data.message) {
                console.log(e.data.message);
                setProgress(100);
                // const downloadEle = document.createElement("a");
                // downloadEle.href = e.data.file;
                // downloadEle.download = props.fileName;
                // document.body.appendChild(downloadEle);
                // downloadEle.click();
                // setTimeout(function () {
                //   document.body.removeChild(downloadEle);
                //   window.URL.revokeObjectURL(e.data.file);
                //   resolve(e.data.message);
                // }, 0); 
                saveAs(e.data.file, props.fileName);
                // saveAs(new Blob([e.data.file], { type: 'application/octet-stream' }), fileName);
                resolve(e.data.message);
              }
            }
          } else {
            console.error(e);
            reject(e);
          }
        };

        console.log('post message to download worker');

        worker.postMessage(props);

        console.log('worker post message finish');
      } catch (e) {
        console.error(e);
      }
    });
  }

  async function download(props, sas, historyUuid, setProgress) {

    const [uuid, fileName, fileSize, containerName] = props;
    try {

      const downloadResult = await azureDownload(
        {
          uuid: uuid,
          fileName: fileName,
          fileSize: fileSize,
          containerName: containerName,
          sas: sas
        }, setProgress);

      console.log(downloadResult);
      return downloadResult;

    } catch (err) {
      console.error(err);
      throw {
        err: err, props: { uuid, historyUuid, fileName, fileSize, err }
      };
    }
    
  }


  async function getReportViewerPath(props) {
    const { uuid, sas, fileName, containerName } = props;
    try {

      console.log("blob download filename: " + fileName);
      const url = "https://sigknowsoutheastasia.blob.core.windows.net/" + containerName + "/" + uuid + "/" + fileName + "?" + sas;
      const blockBlobClient = new BlockBlobClient(
        url,
        new AnonymousCredential()
      );
      await blockBlobClient.setHTTPHeaders({
        blobContentDisposition: "inline; filename=\"" + encodeURIComponent(fileName) + "\""
      });

      return url;
    }
    catch (err) {
      console.log(err);
      throw {
        err: err, props: { uuid, fileName, err }
      };
    }
  }

  return [upload, download, getReportViewerPath];

}

export default useAzureWorkers;