import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { lighten } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import { Grid } from "../PatientDetail/PatientDetail";
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import { MenuProps } from "views/PatientDetail/PatientDetail.jsx"
import Gallery from 'react-grid-gallery';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import NewReleases from "@material-ui/icons/NewReleases";
import Checkbox from '@material-ui/core/Checkbox';
import { useIntl, FormattedMessage } from "react-intl";
import { Delete } from '@material-ui/icons';
import Toolbar from '@material-ui/core/Toolbar';
import { useToolbarStyles } from '../../components/Table/EnhancedTable.jsx';

const ITEM_HEIGHT = 30;
const ITEM_PADDING_TOP = 4;

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  wildTextField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 400,
  },
  formControl: {
    marginTop: '16',
    marginBottom: '8',
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  group: {
    margin: `${theme.spacing.unit}px 0`,
    display: 'flex,'
  },
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
});

const fileToJson = (file) => {
  return {
    lastModified: file.lastModified,
    lastModifiedDate: file.lastModifiedDate,
    name: file.name,
    size: file.size,
    type: file.type 
  };
}

function EmergentNotification(props) {
  const intl = useIntl();
  const toolbarClasses = useToolbarStyles();
  const { emergentNote, editable, onChange, setter, uuid } = props;
  const menuRef = useRef(null);
  const [menuRefO, setMenuRefO] = useState(menuRef);

  const yesOrNo = [
    true,
    false,
  ];

  const reasons = [
    { key: 1, value: 'Pause ≧ 3 seconds'},
    { key: 2, value: 'Atrioventricular block (≧ 2°II)' },
    { key: 3, value: 'Ventricular tachycardia (≧3 beats)' },
    { key: 4, value: 'PSVT > 30 seconds' },
    { key: 5, value: 'HR< 40/min' },
    { key: 6, value: 'VPC>10000/day' },
    { key: 7, value: 'Wide QRS (≧100bpm, ≧ 3 beats)' },
    { key: 8, value: 'Atrial Fibrillation' },
    { key: 9, value: 'Ventricular tachycardia (≧4 beats)'},
    { key: 10, value: 'Ventricular tachycardia (≧5 beats)'}];

  const convertYesOrNo = (boolean) => {
    return boolean ? 
      intl.formatMessage({ id: 'patientDetail.emergency.yes'}) : 
      intl.formatMessage({ id: 'patientDetail.emergency.no' });
  };

  function readFileAsDataURL(file) {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = () => {
        reader.abort();
        reject(new DOMException("Problem parsing input file."));
      };
      reader.readAsDataURL(file);
    });
  }

  async function getImageFromFile(files) {
    return await Promise.all(Array.from(files).map(async (f) => {
      return {
        info: fileToJson(f),
        content: await readFileAsDataURL(f)
      };}));
  }

  function onUploadFigure(uuid, acceptedFiles) {
    getImageFromFile(acceptedFiles).then(
      content => {
        handleChange('figures', emergentNote.figures.concat(
          content.map(f => 
          {
            return {
              src: f.content,
              thumbnail: f.content,
              thumbnailWidth: 100,
              thumbnailHeight: 100,
              caption: f.info.name,
              /*srcSet: [`${f.content} 1024w`, `${f.content} 800w`, `${f.content} 500w`, `${f.content} 320w`],*/
              isSelected: false,
            };
          })
        ));
      }
    );
    
  }
  
  const handleChange = (key, value) => {
    onChange(key, value, setter);
  };

  return (
    <div xs={12} sm={12} md={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Grid container>
        <Grid xs={1} item>{emergentNote.isEmergency ? <Tooltip title={intl.formatMessage({ id: 'patientList.emergency' })}><NewReleases style={{ color: "red" }}></NewReleases></Tooltip> : null}</Grid>
        <Grid xs={2} item style={{ textAlign: 'left'}}>
          <FormControl style={{ minWidth: '60%', marginTop: '16px', marginBottom: '8px'}} className={styles.textField}>
            <InputLabel htmlFor="select-multiple-checkbox-emergency" shrink={true} >
              <FormattedMessage id='patientDetail.emergency.emergency'/>
            </InputLabel>
            <Select
              value={emergentNote.isEmergency}
              onChange={event => handleChange('isEmergency', event.target.value)}
              input={<Input id="select-multiple-checkbox-emergency" />}
              renderValue={selected => convertYesOrNo(selected)}
              disabled={!editable}
            >
              {yesOrNo.map(item => (
                <MenuItem key={item} value={item}>
                  <ListItemText primary={convertYesOrNo(item)} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={8} item>
          <FormControl style={{ marginTop: '16px', marginBottom: '8px', width: '100%' }} className={styles.textField}>
            <InputLabel htmlFor="select-multiple-checkbox-reason">
              <FormattedMessage id='patientDetail.emergency.reason' />
            </InputLabel>
            <Select
              multiple
              SelectDisplayProps={{
                  style: { whiteSpace: 'normal'}}}
              value={JSON.parse(emergentNote.reason).map(reason => reasons[reason-1].value)}
              onChange={(e, child) => {
                let reasonArray = JSON.parse(emergentNote.reason);
                const index = reasonArray.indexOf(child.props.value.key);
                if(index > -1){
                  reasonArray.splice(index, 1);
                } else {
                  reasonArray = reasonArray.concat([child.props.value.key]);
                }
                return handleChange('reason', JSON.stringify(reasonArray));
              }}
              input={<Input id="select-multiple-checkbox-reason" />}
              renderValue={selected => selected.join(', ')}
              disabled={!editable}
              ref={menuRefO}
              onOpen={(e) => {
                setMenuRefO(e.currentTarget);
              }}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                getContentAnchorEl: () => {
                  return menuRefO.current;
                },
                PaperProps: {
                  style: {
                    maxHeight: ITEM_HEIGHT * 9 + ITEM_PADDING_TOP,
                    width: 250,
                  },
                },
              }}
            >
              {reasons.map(reason => (
                <MenuItem key={reason.key} value={reason}>
                  <Checkbox checked={JSON.parse(emergentNote.reason).indexOf(reason.key) > -1} />
                  <ListItemText primary={reason.value} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {emergentNote.isEmergency ? 
        <Grid xs={12} container>
          <Grid xs={2} item style={{textAlign: 'left'}}>
            <p xs={12}>
              <FormattedMessage id='patientDetail.emergency.figures' />
            </p>
          </Grid>
          <Grid xs={10} item style={{ textAlign: 'right'}}>
            <Toolbar>
              <div className={toolbarClasses.spacer} />
              <div className={toolbarClasses.actions}>
                <div style={{ textAlign: 'right' }}><input id={"upload-figure-" + uuid} disabled={!editable} multiple
                  onClick={(e) => {
                    e.target.value = null;
                    return e.stopPropagation();
                  }} onChange={(e) => {
                    return onUploadFigure(uuid, e.target.files);
                  }} type="file" accept={".jpg, .png"} style={{ display: "none" }}></input>
                  <label htmlFor={"upload-figure-" + uuid} >
                    <Tooltip title={intl.formatMessage({ id: 'patientDetail.emergency.addFigure' })}>
                      <IconButton
                        disabled={!editable} component="span" aria-label="upload" onClick={(e) => {
                          return e.stopPropagation();
                        }}>
                        <AddCircleIcon color={!editable ? "disabled" : "error"} className={styles.iconHover} />
                      </IconButton>
                    </Tooltip>
                  </label>
                </div>
                <Tooltip title={intl.formatMessage({ id: 'patientDetail.emergency.removeFigure' })}>
                  <IconButton
                    disabled={!editable} component="span" aria-label="upload" onClick={(e) => {
                      handleChange("figures", emergentNote.figures.filter(f => !f.isSelected))
                      return e.stopPropagation();
                    }}>
                    <Delete color={!editable ? "disabled" : "error"} className={styles.iconHover} />
                  </IconButton>
                </Tooltip>
              </div>
              </Toolbar>
            </Grid>
          <Grid xs={1} item></Grid>
          <Grid xs={10} item>
            {emergentNote.figures.length > 0 ?
              (<Gallery 
                enableImageSelection 
                onSelectImage={(index) => {
                  console.log('on image select');
                  var images = emergentNote.figures.slice();
                  var img = images[index];
                  if (Object.prototype.hasOwnProperty.call(img, "isSelected"))
                    img.isSelected = !img.isSelected;
                  else
                    img.isSelected = true;
                }}
                images={emergentNote.figures}
          />) : <Typography style={{textAlign: "center"}}>
            <FormattedMessage id="patientDetail.emergency.noFigure" />
              </Typography>}</Grid>
        </Grid> : null}
      </Grid>
    </div>
  );
}

EmergentNotification.propTypes = {
  uuid: PropTypes.string,
  emergentNote: PropTypes.object.isRequired,
  editable: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  setter: PropTypes.func.isRequired,
};


export default withStyles(styles)(EmergentNotification);