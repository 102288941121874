import React, { useEffect, useState, useRef } from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Cookies from "js-cookie";
import { axiosService } from "axios/axiosInstance";
import { useIntl, FormattedMessage } from 'react-intl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import useAxiosHandler from "../../axios/axiosHandler";
import { range } from "d3";
import moment from "moment";

const ITEM_HEIGHT = 30;
const ITEM_PADDING_TOP = 4;
const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  input: {
    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    }
  }
};

function Announcement(props) {
  const { classes, onClose } = props;
  const [configList, setConfigList] = useState([]);
  const [currConfig, setCurrConfig] = useState({});
  const menuRef = useRef(null);
  const [menuRefO, setMenuRefO] = useState(menuRef);
  const [successHandler, errorHandler] = useAxiosHandler();
  const intl = useIntl();

  const handleChange = (key, value) => {
    setCurrConfig(prevState => {
      return { ...prevState, [key]: value };
    });
  };

  const handleMessageChange = (value) => {
    setCurrConfig(value);
  }

  const statusMapping = (status) => {
    if(status === 0) {
      return "DO NOT SEND";
    } else if (status === 1) {
      return "SEND ONCE";
    } else if (status === 2) {
      return "SEND ALWAYS";
    } else {
      return "UNKNOW";
    }
  }
  const levelMapping = (level) => {
    if (level === 0) {
      return "DEFAULT";
    } else if (level === 1) {
      return "ERROR";
    } else if (level === 2) {
      return "SUCCESS";
    } else if (level === 3) {
      return "WARNING";
    } else if (level === 4) {
      return "INFO";
    } else {
      return "DEFAULT";
    }
  }

  const positionMapping = (position) => {
    if(position === 1) {
      return "TOP-LEFT";
    } else if(position === 2) {
      return "TOP-CENTER";
    } else if (position === 3) {
      return "TOP-RIGHT";
    } else if (position === 4) {
      return "BOTTOM-LEFT";
    } else if (position === 5) {
      return "BOTTOM-CENTER";
    } else if (position === 6) {
      return "BOTTOM-RIGHT";
    } else {
      return "TOP-CENTER";
    }
  }

  const getCurrentDate = () => {
    const date = new Date();
    return date.getFullYear() + '-'
      + ('0' + (date.getMonth() + 1)).slice(-2) + '-'
      + ('0' + date.getDate()).slice(-2) + 'T'
      + ('0' + date.getHours()).slice(-2) + ':'
      + ('0' + date.getMinutes()).slice(-2) + ':'
      + ('0' + date.getSeconds()).slice(-2);
  }

  useEffect(() => {
    if (currConfig.messageId) {
      var newConfigList = configList.map(c => {
        if(c.messageId === currConfig.messageId) {
          return currConfig;
        } else {
          return c;
        }
      });

      setConfigList(newConfigList);
    }
  }, [currConfig]);

  useEffect(() => {
    // query sales privilege status
    axiosService.get('/settings/setting',
      {
        headers: {
          'Authorization': 'Bearer ' + Cookies.get('apiKey'),
          'X-Socket-ID': window.Echo ? window.Echo.socketId() : ''
        }
      }).then(response => {
        console.log(response.data);
        function onSuccess(response) {
          if(response.status === 200) {
            if(response.data.error === 0) {
              if (response.data.message.length > 0) {
                setConfigList(response.data.message.map(message => {
                  let obj = message
                  obj.messageId =  message.messageId;
                  obj.duration = !!message.duration ? message.duration : 3000;
                  obj.level = !!message.level ? message.level : 4;
                  obj.notifyAfter = !!message.notifyAfter ? message.notifyAfter : getCurrentDate()
                  obj.notifyBefore = !!message.notifyBefore ? message.notifyBefore : getCurrentDate()
                  obj.position = !!message.position ? message.position : 2;
                  obj.status = !!message.status ? message.status : 2;
                  return obj
                }));
                setCurrConfig(response.data.message[0]);
              }
            } else if (response.data.error === 3) {
              onClose();
            }
          }
        }
        successHandler(response, onSuccess);
      }).catch(error => {
        errorHandler(error);
      });
  }, []);

  const onClickApply = () => {
    axiosService.post('/settings/setting',
      {
        messages: configList
      },
      {
        headers: {
          'Authorization': 'Bearer ' + Cookies.get('apiKey'),
          'X-Socket-ID': window.Echo ? window.Echo.socketId() : ''
        }
      }).then(response => {
        console.log(response.data);
        function onSuccess(response) {
          if(response.status === 200 && (response.data.error === 0 || response.data.error === 3)) {
            onClose();
          }
        }
        successHandler(response, onSuccess);
      }).catch(error => {
        errorHandler(error);
      });
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center' }}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="select-multiple-checkbox-user">
              <FormattedMessage id='settings.announcement.config' />
            </InputLabel>
            <Select
              style={{ minWidth: 120, maxWidth: 250 }}
              value={currConfig}
              onChange={event => handleMessageChange(event.target.value)}
              input={<Input id="select-multiple-checkbox-user" />}
              renderValue={selected => selected.messageId ? selected.messageId : "Select a notification.."}
              ref={menuRefO}
              onOpen={(e) => {
                setMenuRefO(e.currentTarget);
              }}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center"
                },
                getContentAnchorEl: () => {
                  return menuRefO.current;
                },
                PaperProps: {
                  style: {
                    maxHeight: ITEM_HEIGHT * 9 + ITEM_PADDING_TOP,
                    // width: 250,
                  },
                },
              }}
            >
              {configList.map(config => (
                <MenuItem key={config.messageId} value={config}>
                  <ListItemText primary={JSON.parse(config.message)['en-US']} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center' }}>
          <GridContainer xs={12}>
            <GridItem xs={3}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="select-announcement-status">
                  <FormattedMessage id='settings.announcement.status' />
                </InputLabel>
                <Select
                  style={{ minWidth: 120, maxWidth: 250 }}
                  value={currConfig.status?? 2}
                  onChange={event => handleChange("status", event.target.value)}
                  input={<Input id="select-announcement-status" />}
                  renderValue={selected => statusMapping(selected)}
                  ref={menuRefO}
                  onOpen={(e) => {
                    setMenuRefO(e.currentTarget);
                  }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    getContentAnchorEl: () => {
                      return menuRefO.current;
                    },
                    PaperProps: {
                      style: {
                        maxHeight: ITEM_HEIGHT * 9 + ITEM_PADDING_TOP,
                      },
                    },
                  }}
                >
                  {range(3).map(status => (
                    <MenuItem key={status} value={status}>
                      <ListItemText primary={statusMapping(status)} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={3}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="select-announcement-level">
                  <FormattedMessage id='settings.announcement.level' />
                </InputLabel>
                <Select
                  style={{ minWidth: 120, maxWidth: 250 }}
                  value={currConfig.level?? 4}
                  onChange={event => handleChange("level", event.target.value)}
                  input={<Input id="select-announcement-level" />}
                  renderValue={selected => levelMapping(selected)}
                  ref={menuRefO}
                  onOpen={(e) => {
                    setMenuRefO(e.currentTarget);
                  }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    getContentAnchorEl: () => {
                      return menuRefO.current;
                    },
                    PaperProps: {
                      style: {
                        maxHeight: ITEM_HEIGHT * 9 + ITEM_PADDING_TOP,
                      },
                    },
                  }}
                >
                  {range(5).map(level => (
                    <MenuItem key={level} value={level}>
                      <ListItemText primary={levelMapping(level)} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={3}>
              <TextField
                label={intl.formatMessage({ id: "settings.announcement.duration"})}
                defaultValue={3000}
                value={currConfig.duration?? 3000}
                onChange={event => handleChange("duration", Number(event.target.value)) }
                type="number"
                inputProps={{ className: classes.input }}
                endAdornment={<InputAdornment position="end">ms</InputAdornment>}>
              </TextField>
            </GridItem>
            <GridItem xs={3}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="select-announcement-position">
                  <FormattedMessage id='settings.announcement.position' />
                </InputLabel>
                <Select
                  style={{ minWidth: 120, maxWidth: 250 }}
                  value={currConfig.position ?? 2}
                  onChange={event => handleChange("position", event.target.value)}
                  input={<Input id="select-announcement-position" />}
                  renderValue={selected => positionMapping(selected)}
                  ref={menuRefO}
                  onOpen={(e) => {
                    setMenuRefO(e.currentTarget);
                  }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    getContentAnchorEl: () => {
                      return menuRefO.current;
                    },
                    PaperProps: {
                      style: {
                        maxHeight: ITEM_HEIGHT * 9 + ITEM_PADDING_TOP,
                      },
                    },
                  }}
                >
                  {range(1,7).map(position => (
                    <MenuItem key={position} value={position}>
                      <ListItemText primary={positionMapping(position)} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={6}>
              <TextField
                label={intl.formatMessage({ id: "settings.announcement.notifyAfter" })}
                defaultValue={getCurrentDate()}
                value={currConfig.notifyAfter? moment(currConfig.notifyAfter).format("YYYY-MM-DDTHH:mm:ss") : getCurrentDate() }
                onChange={event => handleChange("notifyAfter", moment(event.target.value).format("YYYY-MM-DD HH:mm:ss"))}
                type="datetime-local">
              </TextField>
            </GridItem>
            <GridItem xs={6}>
              <TextField
                label={intl.formatMessage({ id: "settings.announcement.notifyBefore" })}
                defaultValue={getCurrentDate() }
                value={currConfig.notifyBefore ? moment(currConfig.notifyBefore).format("YYYY-MM-DDTHH:mm:ss") : getCurrentDate() }
                onChange={event => handleChange("notifyBefore", moment(event.target.value).format("YYYY-MM-DD HH:mm:ss"))}
                type="datetime-local">
              </TextField>
            </GridItem>
            {currConfig.message ?
              Object.keys(JSON.parse(currConfig.message)).map(k => {
                return (<GridItem xs={12}>
                  <TextField
                    label={k}
                    multiline
                    value={JSON.parse(currConfig.message)[k]}
                    onChange={event => {
                      var message = JSON.parse(currConfig.message);
                      message[k] = event.target.value;
                      handleChange("message", JSON.stringify(message));
                    }}
                    type="text"
                    style={{
                      width: "100%"
                    }}>
                  </TextField>
                </GridItem>);
              }) : null
            }
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center' }}>
          <Button variant="contained" color="primary" onClick={onClickApply}>
            <FormattedMessage id="settings.privilege.apply"></FormattedMessage>
          </Button>
        </GridItem>
      </GridContainer>
    </div>
  );
}

Announcement.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func
}

export default withStyles(styles)(Announcement);
