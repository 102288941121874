import React, { useEffect, useState, useCallback, Suspense } from 'react';
import { useDispatch, useMappedState } from "redux-react-hook";
import withStyles from "@material-ui/core/styles/withStyles";
import Divider from '@material-ui/core/Divider';
import { axiosService } from "axios/axiosInstance";
import Cookies from "js-cookie";
import ReportInfo from "views/PatientDetail/ReportInfo.jsx"
import PatientInfo from "views/PatientDetail/PatientInfo.jsx"
import EmergentNotification from "views/PatientDetail/EmergentNotification.jsx"
import FileHistory from "views/PatientDetail/FileHistory.jsx";
import { ACTIONTYPE_ANALYSTLIST, ACTIONTYPE_PHYSICIANLIST, ACTIONTYPE_LOCATIONLIST, ACTIONTYPE_HOSPITALSTAFFLIST, ACTIONTYPE_MESSAGE_CONFIG } from "../../redux/actionTypes";

import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import useAxiosHandler from '../../axios/axiosHandler';
import Messenger, { MessengerHeader } from '../PatientDetail/Messenger';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Grid } from 'views/PatientDetail/PatientDetail.jsx';

const styles = {
  image: {
    maxWidth: "300px",
    maxHeight: "420px"
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

function PatientDetailForm(props) {

  const intl = useIntl();
  const dispatch = useDispatch();
  const [successHandler, errorHandler] = useAxiosHandler();
  const { isNewRecord, uuid, onClose, handleRefreshRequest,
    model, deviceId, serviceId, deviceDayVersion, setDeviceDayVersion,
    setIsDetailModified, tags,
    reportInfo, setReportInfo,
    patientInfo, setPatientInfo,
    emergentNote, setEmergentNote,
    setModifiedReportInfo,
    setModifiedPatientInfo,
    setModifiedEmergentNote,
    onSelect, onUploaded, onDownloaded, pathname, isDemo } = props;

  const analystList = useMappedState(useCallback(state => state.initialData.analystList));
  const physicianList = useMappedState(useCallback(state => state.initialData.physicianList));
  const locationList = useMappedState(useCallback(state => state.initialData.locationList));
  const hospitalStaffList = useMappedState(useCallback(state => state.initialData.hospitalStaffList));
  const role = useMappedState(useCallback(state => state.setting.role));

  const [editMessenger, setEditMessenger] = useState(false);

  const [deviceIdState, setDeviceIdState] = useState(deviceId);
  const [serviceIdState, setServiceIdState] = useState(serviceId);
  const [dialogComponent, setDialogComponent] = useState(null);
  const [canUploadSignal, setCanUploadSignal] = useState(false);
  const [canUploadReport, setCanUploadReport] = useState(false);
  const [canDownloadSignal, setCanDownloadSignal] = useState(false);
  const [canDownloadReport, setCanDownloadReport] = useState(false);
  const [canOpenWebViewer, setCanOpenWebViewer] = useState(false);
  const [canOpenPdfViewer, setCanOpenPdfViewer] = useState(false);
  const [canEditComment, setCanEditComment] = useState(false);
  const [signalHistory, setSignalHistory] = useState([]);
  const [reportHistory, setReportHistory] = useState([]);

  const [showReportInfo, setShowReportInfo] = useState(false || (isNewRecord && (role === 4 || role === 5 || role === 100)));
  const [showPatientInfo, setShowPatientInfo] = useState(false || isNewRecord);
  const [showEmergentNote, setShowEmergentNote] = useState(false || isNewRecord);
  const [showHistory, setShowHistory] = useState(false);
  const [showMessenger, setShowMessenger] = useState(false);
  const [editReportInfo, setEditReportInfo] = useState(true);
  const [editPatientInfo, setEditPatientInfo] = useState(true);
  const [editEmergentNote, setEditEmergentNote] = useState(role === 4 || role === 5 || role === 100);
  const [isCommentModified, setIsCommentModified] = useState(false);

  useEffect(() => {
    setPatientInfo(preState => {
      return {
        ...preState,
        device: {
          ...preState.deviceId,
          Model: model,
          DeviceId: deviceId
        },
        patch: serviceId
      };
    })
  }, [model, deviceId, serviceId]);

  function onChange(key, value, setter) {
    if (typeof setter === 'function') {
      setter(prevState => {
        console.log(JSON.stringify(prevState[key]));
        console.log(JSON.stringify(value));
        if (JSON.stringify(prevState[key]) === JSON.stringify(value)) {
          console.log(key + ' state not updated');
          return prevState;
        } else {
          console.log(key + ' state updated');
          if (setter === setReportInfo) {
            setIsDetailModified(true);
            setModifiedReportInfo(preModifiedReportInfo => {
              return {
                ...preModifiedReportInfo,
                [key]: Array.isArray(value) ? Array.from(value) : value
              }
            });
          } else if (setter === setPatientInfo) {
            setIsDetailModified(true);
            setModifiedPatientInfo(preModifiedPatientInfo => {
              return {
                ...preModifiedPatientInfo,
                [key]: value
              };
            });
          } else if (setter === setEmergentNote) {
            setIsDetailModified(true);
            setModifiedEmergentNote(preModifiedEmergentNote => {
              return {
                ...preModifiedEmergentNote,
                [key]: value
              };
            });
          }
          return { ...prevState, [key]: value };
        }
      });
    }
  }

  function dispatchInitData(message) {
    if (message.initialData !== undefined) {
      if (message.initialData.analystList !== undefined) {
        dispatch({ type: ACTIONTYPE_ANALYSTLIST, value: { analystList: message.initialData.analystList } });
      }
      if (message.initialData.physicianList !== undefined) {
        dispatch({ type: ACTIONTYPE_PHYSICIANLIST, value: { physicianList: message.initialData.physicianList } });
      }
      if (message.initialData.locations !== undefined) {
        dispatch({ type: ACTIONTYPE_LOCATIONLIST, value: { locationList: message.initialData.locations } });
      }
      if (message.initialData.hospitalStaff !== undefined) {
        dispatch({ type: ACTIONTYPE_HOSPITALSTAFFLIST, value: { hospitalStaffList: message.initialData.hospitalStaff } });
      }
    }
  }

  const handleMessage = (message, isQueryInitData) => {
    if (message !== undefined && message !== null) {
      if (isQueryInitData) {
        dispatchInitData(message);
      }
      setDeviceDayVersion(message.deviceDayVersion);
      setEditMessenger(message.boardEditable);
      setShowMessenger(true);
      setEditEmergentNote(message.emergentNoteEditable);
      setEditPatientInfo(message.patientInfoEditable);
      setEditReportInfo(message.reportInfoEditable);
      if (message.emergentNote !== undefined && message.emergentNote !== null) {
        setShowEmergentNote(true);
        setEmergentNote(message.emergentNote);
      } else {
        setShowEmergentNote(false);
      }
      if (message.patientInfo !== undefined && message.patientInfo !== null) {
        setShowPatientInfo(true);
        setPatientInfo(message.patientInfo);
        setDeviceIdState(message.patientInfo.device.DeviceId);
        setServiceIdState(message.patientInfo.patch);
      } else {
        setShowPatientInfo(false);
      }
      if (message.reportInfo !== undefined && message.reportInfo !== null) {
        setShowReportInfo(true);
        setReportInfo(message.reportInfo);
      } else {
        setShowReportInfo(false);
      }
      if (message.isCommentModified !== undefined && message.isCommentModified !== null) {
        setIsCommentModified(message.isCommentModified);
      }
      if (message.history !== undefined && message.history !== null) {
        setShowHistory(true);
        if (message.history.canUploadSignal !== undefined && message.history.canUploadSignal !== null) {
          setCanUploadSignal(message.history.canUploadSignal);
        }
        if (message.history.canUploadReport !== undefined && message.history.canUploadReport !== null) {
          setCanUploadReport(message.history.canUploadReport);
        }
        if (message.history.canDownloadSignal !== undefined && message.history.canDownloadSignal !== null) {
          setCanDownloadSignal(message.history.canDownloadSignal);
        }
        if (message.history.canDownloadReport !== undefined && message.history.canDownloadReport !== null) {
          setCanDownloadReport(message.history.canDownloadReport);
        }
        if (message.history.canOpenWebViewer !== undefined && message.history.canOpenWebViewer !== null) {
          setCanOpenWebViewer(message.history.canOpenWebViewer);
        }
        if (message.history.canOpenPdfViewer !== undefined && message.history.canOpenPdfViewer !== null) {
          setCanOpenPdfViewer(message.history.canOpenPdfViewer);
        }
        if (message.history.canEditComment !== undefined && message.history.canEditComment != null) {
          setCanEditComment(message.history.canEditComment);
        }
        if (message.history.signal !== undefined && message.history.signal !== null) {
          setSignalHistory(message.history.signal);
        }
        if (message.history.report !== undefined && message.history.report !== null) {
          setReportHistory(message.history.report);
        }
      } else {
        setShowHistory(false);
      }
    }
  }

  useEffect(() => {
    let isQueryInitData = locationList.length === 0;


    if (isNewRecord === false && typeof uuid === 'string') {
      if (typeof onSelect === 'function') {
        handleMessage(onSelect({ uuid, isQueryInitData }), isQueryInitData)
      }
      if (pathname) {
        // query patient info from server by uuid
        axiosService.get(
          pathname + '?uuid=' + uuid + '&initdata=' + isQueryInitData,
          {
            headers: {
              'Authorization': 'Bearer ' + Cookies.get('apiKey'),
              'X-Socket-ID': window.Echo ? window.Echo.socketId() : ''
            }
          }).then(response => {
            console.log(response.data);
            function onSuccess(response) {
              if (response.status === 200) {
                if (response.data.error === 0) {
                  handleMessage(response.data.message, isQueryInitData);
                } else if (response.data.error === 4) {
                  onClose();
                }
              }
            }
            successHandler(response, onSuccess);
          }).catch(error => {
            errorHandler(error);
          });
      }
    } else {
      // if (isDemo) {
      // if (locationList.length === 0) {
      //   dispatch({ type: ACTIONTYPE_LOCATIONLIST, value: { locationList: [{ Name: "Demo", Uuid: "demo_location" }] } });
      // }
      // } else 
      if (!isDemo && isQueryInitData && pathname) {
        axiosService.get(
          '/initdata',
          {
            headers: {
              'Authorization': 'Bearer ' + Cookies.get('apiKey'),
              'X-Socket-ID': window.Echo ? window.Echo.socketId() : ''
            }
          }).then(response => {
            console.log(response.data);
            function onSuccess(response) {
              if (response.status === 200 && response.data.error === 0
                && response.data.message !== undefined) {
                dispatchInitData(response.data.message);
              }
            }
            successHandler(response, onSuccess);
          }).catch(error => {
            errorHandler(error);
          });
      }
    }
    return () => {
    };
  }, []);

  useEffect(() => {
    if (locationList.length === 1) {
      setPatientInfo(prevState => {
        return {
          ...prevState,
          location: { Uuid: /*"40ecd831-7adc-11ea-b330-080027e0977a"*/locationList[0].Uuid, Name: /*"三軍總醫院"*/locationList[0].Name }
        };
      });
    }
  }, [locationList])

  return (
    <Grid container style={{ width: '' }}>
      <div>{dialogComponent}</div>
      <Grid container>
        <Grid xs={12} item>
          {isNewRecord ? <Grid xs={12} item mx="auto">
            <FormControlLabel
              style={{ float: 'right' }}
              control={
                <Checkbox
                  checked={patientInfo.isNewPatient}
                  onChange={() => onChange('isNewPatient', !patientInfo.isNewPatient, setPatientInfo)}
                  value={patientInfo.isNewPatient}
                />}
              label={intl.formatMessage({ id: 'patientDetail.newPatient' })}
            />
          </Grid> : null}
        </Grid>
      </Grid>
      <Grid xs={12} item>
        <Grid container>
          <Grid xs={12} item>
            {showEmergentNote ?
              <EmergentNotification emergentNote={emergentNote} onChange={onChange} setter={setEmergentNote} style={{ mx: 'auto' }} editable={editEmergentNote} uuid={uuid} /> : null}
          </Grid>
          <Grid xs={12} item>
            {showEmergentNote ? <Divider variant="middle" /> : null}
          </Grid>
          <Grid xs={12} item>
            {showPatientInfo ? <PatientInfo deviceDayVersion={deviceDayVersion}
              isNewRecord={isNewRecord} patientInfo={patientInfo} onChange={onChange} setter={setPatientInfo} style={{ mx: 'auto' }} editable={editPatientInfo} physicianList={physicianList} locationList={locationList} hospitalStaffList={hospitalStaffList} /> : null}
          </Grid>
          <Grid xs={12} item>
            {showPatientInfo ? <Divider variant="middle" /> : null}
          </Grid>
          <Grid xs={12} item style={{ margin: 8 }}>
            {showReportInfo ? <ReportInfo open reportInfo={reportInfo} onChange={onChange} setter={setReportInfo} editable={editReportInfo} analystList={analystList} /> : null}
          </Grid>
          <Grid xs={12} item>
            {showReportInfo ? <Divider variant="middle" /> : null}
          </Grid>
          <Grid container style={{ margin: 8 }}>
            {showHistory && !isNewRecord ?
              <Grid xs={12} item>
                <FileHistory isDemo={isDemo} dense secondary canUpload={canUploadSignal} canDownload={canDownloadSignal} canOpenWebViewer={canOpenWebViewer} canOpenPdfViewer={canOpenPdfViewer} canEditComment={canEditComment} history={signalHistory} historySetter={setSignalHistory} title={intl.formatMessage({ id: 'patientDetail.signal' })}
                  type={isDemo ? 'signal-demo' : 'signal'} fileType={'.bin'} uuid={uuid} deviceId={deviceIdState} patchId={serviceIdState} onUploaded={onUploaded} onDownloaded={onDownloaded} />
                <FileHistory isDemo={isDemo} dense secondary canUpload={canUploadReport} canDownload={canDownloadReport} canOpenWebViewer={canOpenWebViewer} canOpenPdfViewer={canOpenPdfViewer} canEditComment={canEditComment} history={reportHistory} historySetter={setReportHistory} title={intl.formatMessage({ id: 'patientDetail.report' })}
                  type={isDemo ? 'report-demo' : 'report'} fileType={'.pdf,.doc,.docx'} signalHistory={signalHistory} uuid={uuid} deviceId={deviceIdState} patchId={serviceIdState} setDialogComponent={setDialogComponent} onUploaded={onUploaded} onDownloaded={onDownloaded} isCommentModified={isCommentModified} />
              </Grid> : null}
          </Grid>
          <Grid xs={12} item>
            {showHistory && !isNewRecord ? <Divider variant="middle" /> : null}
          </Grid>
          <Grid xs={12} item>
            {showMessenger && <MessengerHeader uuid={uuid} editable={editMessenger} enableChatNotify={patientInfo.enableChatNotify} setEnableChatNotify={(enable) => {
              onChange('enableChatNotify', enable, setPatientInfo);
            }} />}
          </Grid>
          <Grid xs={12} item>
            {showMessenger && <Messenger isDemo={isDemo} uuid={uuid} editable={editMessenger} handleRefreshRequest={handleRefreshRequest} messages={isDemo ? onSelect({ uuid }).board.messages : []} />}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

PatientDetailForm.defaultProps = {
  model: 'UG01',
  deviceId: '',
  serviceId: '',
  isNewRecord: false,
  uuid: '',
  onClose: () => { console.log('default onClose'); },
  handleRefreshRequest: () => { console.log('default handleRefreshRequest'); },
}

PatientDetailForm.propTypes = {
  model: PropTypes.string,
  deviceId: PropTypes.string,
  serviceId: PropTypes.string,
  deviceDayVersion: PropTypes.number,
  setDeviceDayVersion: PropTypes.func,
  isNewRecord: PropTypes.bool.isRequired,
  uuid: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  handleRefreshRequest: PropTypes.func.isRequired,
  setIsDetailModified: PropTypes.func.isRequired,
  tags: PropTypes.array,
  selected: PropTypes.array.isRequired,
  onSelect: PropTypes.func,
  onUploaded: PropTypes.func,
  onDownload: PropTypes.func,
  isDemo: PropTypes.bool,
  reportInfo: PropTypes.object.isRequired,
  setReportInfo: PropTypes.func.isRequired,
  patientInfo: PropTypes.object.isRequired,
  setPatientInfo: PropTypes.func.isRequired,
  emergentNote: PropTypes.object.isRequired,
  setEmergentNote: PropTypes.func.isRequired,
  setModifiedReportInfo: PropTypes.func.isRequired,
  setModifiedPatientInfo: PropTypes.func.isRequired,
  setModifiedEmergentNote: PropTypes.func.isRequired,
};

export default withStyles(styles)(PatientDetailForm);