import React, { useEffect, useState } from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';
import { axiosService } from "axios/axiosInstance";
import { Tooltip } from '@material-ui/core';
import { useDispatch } from "redux-react-hook";
import { ACTIONTYPE_MESSAGE_CONFIG } from "../../redux/actionTypes"
import { useIntl, FormattedMessage } from 'react-intl';

import useAxiosHandler from "../../axios/axiosHandler";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

function LocationProfile(props) {
  const { classes, onClose } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const [successHandler, errorHandler] = useAxiosHandler();
  const [newLocation, setNewLocation] = useState(true);
  const [profileList, setProfileList] = useState([]);
  const [profile, setProfile] = useState({
    uuid: '',
    customerId: '',
    name: '',
    division: '',
    phone: '',
    address: ''
  });
  const menuRef = React.useRef(null);
  const [menuRefO, setMenuRefO] = React.useState(menuRef);

  const handleChange = (key, value) => {
    setProfile(prevState => {
      console.log(JSON.stringify(prevState[key]));
      console.log(JSON.stringify(value));
      return { ...prevState, [key]: value };
    });
  };

  const handleLocationChange = (e) => {
    console.log('handleLocationChange IN');
    console.log(e.target.value);
    let l = profileList.find(profile => profile.name === e.target.value);
    if (l.name === intl.formatMessage({ id: 'locationProfile.addProfile'})){
      setProfile({
        'uuid': '',
        'customerId': '',
        'name': '',
        'division': '',
        'phone': '',
        'address': ''})
        setNewLocation(true);
    } else {
      console.log('division', l.division);
      setProfile({
        'uuid': l.uuid,
        'customerId': l.customerId,
        'name': l.name,
        'division': l.division ?? '',
        'phone': l.phone,
        'address': l.address
      });
      setNewLocation(false);
    }
  }

  const verifyData = () => {
    const breakLoop = false;
    do{
      if(newLocation && profile.customerId === ''){
        dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.location.customerId' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top' } } });
        break;
      }
      if(profile.name === '') {
        dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.location.name' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top'} } });
        break;
      }
      return true;
    }while(breakLoop);
    return false;
  }

  const onHandleClick = () => {
    if(!verifyData()){
      return;
    }
    if(newLocation){
      axiosService.post('/location/profile',
        {
          ...profile,
        },
        {
          headers: {
            'Authorization': 'Bearer ' + Cookies.get('apiKey'),
            'X-Socket-ID': window.Echo ? window.Echo.socketId() : ''
          }
        }).then(response => {
          console.log(response.data);
          function onSuccess(response) {
            if (response.status === 200 && (response.data.error === 0 || response.data.error === 3)) {
              if(response.data.error === 0)
                dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'locationProfile.success' }), timeout: 3000, color: 'success', anchorOrigin: { horizontal: 'center', vertical: 'top' } } });
              onClose();
            }
          }
          successHandler(response, onSuccess);
        }).catch(error => {
          errorHandler(error);
        });
    } else {
      let data = new FormData();
      data.append('profile', JSON.stringify(
        { ...profile,}));
      data.append('_method', 'PUT');
      axiosService.post('/location/profile/' + profile.uuid,
        data,
        {
          headers: {
            'Authorization': 'Bearer ' + Cookies.get('apiKey'),
            'X-Socket-ID': window.Echo ? window.Echo.socketId() : ''
          }
        }).then(response => {
          console.log(response.data);
          function onSuccess(response) {
            if (response.status === 200 && (response.data.error === 0 || response.data.error === 3)) {
              dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'locationProfile.success' }), timeout: 3000, color: 'success', anchorOrigin: { horizontal: 'center', vertical: 'top' } } });
              onClose();
            }
          }
          successHandler(response, onSuccess);
        }).catch(error => {
          errorHandler(error);
        });
    }
  };

  useEffect(()=>{
    axiosService.get(
      '/location/profile',
      {
        headers: {
          'Authorization': 'Bearer ' + Cookies.get('apiKey'),
          'X-Socket-ID': window.Echo ? window.Echo.socketId() : ''
        }
      }).then(response => {
        console.log(response.data);
        function onSuccess(response) {
          if(response.status === 200) {
            if (response.data.error === 0 && response.data.message !== undefined) {
              console.log('receive user profile from server');
              response.data.message.locationList.splice(0, 0, 
                { name: intl.formatMessage({ id: 'locationProfile.addProfile'}),
                  division: '',
                  phone: '',
                  address: ''})
              setProfileList(response.data.message.locationList);
            } else {
              onClose();
            }
          }
        }
        successHandler(response, onSuccess);
      }).catch(error => {
        errorHandler(error);
      });
  }, []);
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>
                <FormattedMessage id={"locationProfile.location"}></FormattedMessage>
              </h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} style={{textAlign: 'center'}}>
                  <FormControl className={classes.formControl}>
                    <Select
                      style={{ minWidth: 120, maxWidth: 250 }}
                      value={newLocation ? intl.formatMessage({ id: 'locationProfile.addProfile'}) :profile.name}
                      onChange={event => handleLocationChange(event)}
                      input={<Input id="select-multiple-checkbox-location" />}
                      ref={menuRefO}
                      onOpen={(e) => {
                        setMenuRefO(e.currentTarget);
                      }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        },
                        getContentAnchorEl: () => {
                          return menuRefO.current;
                        },
                        PaperProps: {
                          style: {
                            //maxHeight: ITEM_HEIGHT * 9 + ITEM_PADDING_TOP,
                            //width: 250,
                          },
                        },
                      }}
                    >
                      {profileList.sort((a, b) => {
                        return a.name.toUpperCase().localeCompare(b.name.toUpperCase(), "zh-hant")
                      }).map(location => (
                        <MenuItem key={location.uuid} value={location.name}>
                          <ListItemText primary={location.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <Tooltip title={profile.customerId ? profile.customerId.length + '/17' : '0/17'} enterDelay={500}>
                    <TextField
                      id="locationProfile-customerId"
                      label={intl.formatMessage({ id: 'locationProfile.customerId' })}
                      disabled={!newLocation}
                      value={profile.customerId ?? ''}
                      onInput={(e) => {
                        if (e.target.value.length > 17) {
                          e.target.value = e.target.value.slice(0, 17);
                        }
                        handleChange('customerId', e.target.value);
                      }}
                      margin="normal"
                      fullWidth
                      required
                    />
                  </Tooltip>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <Tooltip title={profile.name ? profile.name.length + '/100' : '0/100'} enterDelay={500}>
                    <TextField
                      id="locationProfile-name"
                      label={intl.formatMessage({ id: 'locationProfile.name' })}
                      value={profile.name}
                      onInput={(e) => {
                        if (e.target.value.length > 100) {
                          e.target.value = e.target.value.slice(0, 100);
                        }
                        handleChange('name', e.target.value);
                      }}
                      margin="normal"
                      fullWidth
                      required
                    />
                  </Tooltip>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <Tooltip title={profile.division ? profile.division.length + '/100' : '0/100'} enterDelay={500}>
                    <TextField
                      id="locationProfile-division"
                      helperText={intl.formatMessage({ id: 'locationProfile.divisionHelper'})}
                      label={intl.formatMessage({ id: 'locationProfile.division' })}
                      value={profile.division}
                      onInput={(e) => {
                        if (e.target.value.length > 100) {
                          e.target.value = e.target.value.slice(0, 100);
                        }
                        handleChange('division', e.target.value);
                      }}
                      fullWidth
                      margin="normal"
                    />
                  </Tooltip>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <Tooltip title={profile.phone ? profile.phone.length + '/32' : '0/32'} enterDelay={500}>
                    <TextField
                      id="locationProfile-phone"
                      label={intl.formatMessage({ id: 'userProfile.phone' })}
                      value={profile.phone}
                      onInput={(e) => {
                        if (e.target.value.length > 32) {
                          e.target.value = e.target.value.slice(0, 32);
                        }
                        handleChange('phone', e.target.value);
                      }}
                      fullWidth
                      margin="normal"
                    />
                  </Tooltip>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <Tooltip title={profile.address ? profile.address.length + '/260' : '0/260'} enterDelay={500}>
                    <TextField
                      id="locationProfile-address"
                      label={intl.formatMessage({ id: 'locationProfile.address' })}
                      value={profile.address}
                      onInput={(e) => {
                        if (e.target.value.length > 260) {
                          e.target.value = e.target.value.slice(0, 260);
                        }
                        handleChange('address', e.target.value);
                      }}
                      fullWidth
                      margin="normal"
                    />
                  </Tooltip>
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter style={{justifyContent: "center"}}>
              <Button color="primary" onClick={onHandleClick}>
                <FormattedMessage id={newLocation ? "locationProfile.add" : "locationProfile.apply"}></FormattedMessage>
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

LocationProfile.propTypes = {
  classes: PropTypes.shape({
    cardTitleWhite: PropTypes.string,
    formControl: PropTypes.string
  }),
  onClose: PropTypes.func
}

export default withStyles(styles)(LocationProfile);
