import React, { useCallback } from "react";
import Dialog from '@material-ui/core/Dialog';
import LinearProgress from '@material-ui/core/LinearProgress';
import Close from "@material-ui/icons/Close";
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import { useMappedState } from "redux-react-hook";

export const LoadingDialog = ({ children, ...props }) => {

  const loading = useMappedState(useCallback(state => state.view.reportEditLoading));

  return (
    <Dialog {...props}>
      {loading ? <LinearProgress variant="indeterminate"></LinearProgress> : null}
      <div style={{textAlign: "end"}}>
        <IconButton onClick={props.onClose}><Close /></IconButton>
      </div>
      {children}
    </Dialog>
  )
}
LoadingDialog.propTypes = {
  onClose: PropTypes.func,
  children: PropTypes.node
}
export default LoadingDialog;