import React, { Component } from 'react';
import './Compose.css';
import { injectIntl } from 'react-intl';
import PropTypes from "prop-types";

class Compose extends Component {
  render() {
    const { formatMessage } = this.props.intl;
    return (
      <div className="compose">
        <textarea
          aria-label="chat-input"
          disabled={!this.props.editable}
          style={{
            height: "28px",
            paddingLeft: '10px',
            paddingTop: '10px',
            borderRadius: "10px",
            border: "1px solid #9E9E9E",
            outline: "none",
            resize: "none"
          }}
          className="compose-input"
          placeholder={formatMessage({ id: 'patientDetail.inputMessage' })}
          value={this.props.textValue}
          onKeyDown={event => {
            if ((event.key === 'Enter' || event.keyCode === 13) && !event.shiftKey)
              this.props.onSendNewMessage(event);
          }}
          onInput={this.props.handleValueChange}
          
        />

        {
          this.props.rightItems
        }
      </div>
    );
  }
}

Compose.propTypes={
  editable: PropTypes.bool,
  textValue: PropTypes.string,
  onSendNewMessage: PropTypes.func,
  handleValueChange: PropTypes.func,
  rightItems: PropTypes.node,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  })
};

export default injectIntl(Compose);