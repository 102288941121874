import React, {useCallback, useEffect, useState} from 'react';
import withStyles from "@material-ui/core/styles/withStyles";

import { TextField, Typography } from '@material-ui/core';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { useMappedState } from "redux-react-hook";
import { useIntl, FormattedMessage } from 'react-intl';
import { Grid, getAgeValue, parseGenderToString, parsePacemaker, parseDayVersion, parseIcd, abnormalList, genders } from 'views/PatientDetail/PatientDetail.jsx';

const styles = {
  image: {
    maxWidth: "300px",
    maxHeight: "420px"
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  textField: {
    width: "90%",
  }
};

function ConfirmDetail(props) {

  const intl = useIntl();
  const { patientInfo } = props;
  const lang = useMappedState(useCallback(state => state.setting.language));
  const [applyDate, setApplyDate] = useState('');
  const [patientBirthday, setPatientBirthday] = useState('');
  const [expectedDate, setExpectedDate] = useState('');

  useEffect(() => {
    if(moment(patientInfo.setupDate).isValid()) {
      setApplyDate(lang === 'en-US' ? moment(patientInfo.setupDate).format('DD/MM/YYYY') : moment(patientInfo.setupDate).toDate().toLocaleString(navigator.language, { day: '2-digit', month: '2-digit', year: 'numeric' }))
    } else {
      setApplyDate('')
    }
  }, [patientInfo.setupDate]);
  
  useEffect(() => {
    if(moment(patientInfo.patientBirthday).isValid()) {
      setPatientBirthday(lang === 'en-US' ? moment(patientInfo.patientBirthday).format('DD/MM/YYYY') : moment(patientInfo.patientBirthday).toDate().toLocaleString(navigator.language, { day: '2-digit', month: '2-digit', year: 'numeric' }))
    } else {
      setPatientBirthday('')
    }
  }, [patientInfo.patientBirthday]);
  
  useEffect(() => {
    console.log(patientInfo);
    if(moment(patientInfo.expectedDate).isValid()) {
      setExpectedDate(lang === 'en-US' ? moment.utc(patientInfo.expectedDate).local().format('DD/MM/YYYY HH:mm') : moment.utc(patientInfo.expectedDate).local().toDate().toLocaleString(navigator.language, {  minute: '2-digit', hour: '2-digit', hour12: false, day: '2-digit', month: '2-digit', year: 'numeric' }))
    } else {
      setExpectedDate('')
    }
  }, [patientInfo.expectedDate]);

  return (
    <Grid container style={{ width: '' }}>
      <Grid container>
        <Grid xs={12} item>
          <Typography variant="h5" gutterBottom>
            <FormattedMessage id={"patientDetail.confirm.title"} />
          </Typography>
        </Grid>
      </Grid>
      <Grid xs={3} item />
      <Grid xs={3} item style={{ marginTop: "auto", marginBottom: "auto" }}>
        <FormattedMessage id={"patientDetail.info.deviceId"} />
      </Grid>
      <Grid xs={6} item style={{ textAlign: "start", marginTop: "auto", marginBottom: "auto" }}>
        <TextField style={{...styles.textField}} InputProps={{ readOnly: true, style: { fontWeight: 'bold' } }} value={patientInfo.device.DeviceId} />
      </Grid>
      {patientInfo.device.Model === 'UG02' ? <><Grid xs={3} item />
      <Grid xs={3} item style={{ marginTop: "auto", marginBottom: "auto" }}>
        <FormattedMessage id={"patientDetail.info.patch"} />
      </Grid>
      <Grid xs={6} item style={{ textAlign: "start", marginTop: "auto", marginBottom: "auto" }}>
        <TextField style={{ ...styles.textField }} InputProps={{ readOnly: true, style: { fontWeight: 'bold' } }} value={patientInfo.patch} />
      </Grid></> : null}
      <Grid xs={3} item />
      <Grid xs={3} item style={{ marginTop: "auto", marginBottom: "auto" }}>
        <FormattedMessage id={"patientDetail.info.isUrgent"} />
      </Grid>
      <Grid xs={6} item style={{ textAlign: "start", marginTop: "auto", marginBottom: "auto" }}>
        <TextField style={{ ...styles.textField }} InputProps={{ readOnly: true, style: { fontWeight: 'bold' } }} value={parseIcd(patientInfo.isUrgent)} />
      </Grid>
      {patientInfo.isUrgent ? 
        (<><Grid xs={3} item />
          <Grid xs={3} item style={{ marginTop: "auto", marginBottom: "auto" }}>
            <FormattedMessage id={"patientDetail.info.urgentDescription"} />
          </Grid>
          <Grid xs={6} item style={{ textAlign: "start", marginTop: "auto", marginBottom: "auto" }}>
            <TextField style={{ ...styles.textField }} InputProps={{ readOnly: true, style: { fontWeight: 'bold' } }} value={patientInfo.urgentDescription} />
          </Grid>
          <Grid xs={3} item />
          <Grid xs={3} item style={{ marginTop: "auto", marginBottom: "auto" }}>
            <FormattedMessage id={"patientDetail.info.expectedDate"} />
          </Grid>
          <Grid xs={6} item style={{ textAlign: "start", marginTop: "auto", marginBottom: "auto" }}>
            <TextField style={{ ...styles.textField }} InputProps={{ readOnly: true, style: { fontWeight: 'bold' } }} value={expectedDate} />
          </Grid></>) : null}
      <Grid xs={3} item />
      <Grid xs={3} item style={{ marginTop: "auto", marginBottom: "auto" }}>
        <FormattedMessage id={"patientDetail.info.patientId"} />
      </Grid>
      <Grid xs={6} item style={{ textAlign: "start", marginTop: "auto", marginBottom: "auto" }}>
        <TextField style={{ ...styles.textField }} InputProps={{ readOnly: true, style: { fontWeight: 'bold' } }} value={patientInfo.patientId} />
      </Grid>
      <Grid xs={3} item />
      <Grid xs={3} item style={{ marginTop: "auto", marginBottom: "auto" }}>
        <FormattedMessage id={"patientDetail.info.name"}/>
      </Grid>
      <Grid xs={6} item style={{ textAlign: "start", marginTop: "auto", marginBottom: "auto" }}>
        <TextField style={{ ...styles.textField }} InputProps={{ readOnly: true, style: { fontWeight: 'bold' } }} value={patientInfo.name} />
      </Grid>
      <Grid xs={3} item />
      <Grid xs={3} item style={{ marginTop: "auto", marginBottom: "auto" }}>
        <FormattedMessage id={"patientDetail.info.age"} />
      </Grid>
      <Grid xs={6} item style={{ textAlign: "start", marginTop: "auto", marginBottom: "auto" }}>
        <TextField style={{ ...styles.textField }} InputProps={{ readOnly: true, style: { fontWeight: 'bold' } }} value={getAgeValue(patientInfo.age)} />
      </Grid>
      <Grid xs={3} item />
      <Grid xs={3} item style={{ marginTop: "auto", marginBottom: "auto" }}>
        <FormattedMessage id={"patientDetail.info.dateOfBirth"} />
      </Grid>
      <Grid xs={6} item style={{ textAlign: "start", marginTop: "auto", marginBottom: "auto" }}>
        <TextField style={{ ...styles.textField }} InputProps={{ readOnly: true, style: { fontWeight: 'bold' } }} value={patientBirthday} />
      </Grid>
      <Grid xs={3} item />
      <Grid xs={3} item style={{ marginTop: "auto", marginBottom: "auto" }}>
        <FormattedMessage id={"patientDetail.info.sex"} />
      </Grid>
      <Grid xs={6} item style={{ textAlign: "start", marginTop: "auto", marginBottom: "auto" }}>
        <TextField style={{ ...styles.textField }} InputProps={{ readOnly: true, style: { fontWeight: 'bold' } }} value={parseGenderToString(patientInfo.gender)} />
      </Grid>
      <Grid xs={3} item />
      <Grid xs={3} item style={{ marginTop: "auto", marginBottom: "auto" }}>
        <FormattedMessage id={"patientDetail.info.pacemaker"} />
      </Grid>
      <Grid xs={6} item style={{ textAlign: "start", marginTop: "auto", marginBottom: "auto" }}>
        <TextField style={{ ...styles.textField }} InputProps={{ readOnly: true, style: { fontWeight: 'bold' } }} value={parsePacemaker(patientInfo.paceMaker)} />
      </Grid>
      <Grid xs={3} item />
      <Grid xs={3} item style={{ marginTop: "auto", marginBottom: "auto" }}>
        <FormattedMessage id={"patientDetail.info.icd"} />
      </Grid>
      <Grid xs={6} item style={{ textAlign: "start", marginTop: "auto", marginBottom: "auto" }}>
        <TextField style={{ ...styles.textField }} InputProps={{ readOnly: true, style: { fontWeight: 'bold' } }} value={parseIcd(patientInfo.icd)} />
      </Grid>
      <Grid xs={3} item />
      <Grid xs={3} item style={{ marginTop: "auto", marginBottom: "auto" }}>
        <FormattedMessage id={"patientDetail.info.rfca"} />
      </Grid>
      <Grid xs={6} item style={{ textAlign: "start", marginTop: "auto", marginBottom: "auto" }}>
        <TextField style={{ ...styles.textField }} InputProps={{ value: parseIcd(patientInfo.electrocauterization), readOnly: true, style: { fontWeight: 'bold' } }} /*value={parseIcd(patientInfo.electrocauterization)}*/ />
      </Grid>
      <Grid xs={3} item />
      <Grid xs={3} item style={{ marginTop: "auto", marginBottom: "auto" }}>
        <FormattedMessage id={"patientDetail.info.location"} />
      </Grid>
      <Grid xs={6} item style={{ textAlign: "start", marginTop: "auto", marginBottom: "auto" }}>
        <TextField style={{ ...styles.textField }} InputProps={{ value: patientInfo.location.Name, readOnly: true, style: {fontWeight: 'bold', textAlign: 'center'} }} /*value={patientInfo.location.Name}*/ />
      </Grid>
      <Grid xs={3} item />
      <Grid xs={3} item style={{ marginTop: "auto", marginBottom: "auto" }}>
        <FormattedMessage id={"patientDetail.info.physician"} />
      </Grid>
      <Grid xs={6} item style={{ textAlign: "start", marginTop: "auto", marginBottom: "auto" }}>
        <TextField style={{ ...styles.textField }} InputProps={{ value: patientInfo.physicianName, readOnly: true, style: { fontWeight: 'bold', textAlign: 'center' } }} />
      </Grid>
      <Grid xs={3} item />
      <Grid xs={3} item style={{ marginTop: "auto", marginBottom: "auto" }}>
        <FormattedMessage id={"patientDetail.info.interpretedDoctor"} />
      </Grid>
      <Grid xs={6} item style={{ textAlign: "start", marginTop: "auto", marginBottom: "auto" }}>
        <TextField style={{ ...styles.textField }} InputProps={{ value: patientInfo.interpretedDoctor, readOnly: true, style: { fontWeight: 'bold', textAlign: 'center' } }} />
      </Grid>
      <Grid xs={3} item />
      <Grid xs={3} item style={{ marginTop: "auto", marginBottom: "auto" }}>
        <FormattedMessage id={"patientDetail.info.checkId"} />
      </Grid>
      <Grid xs={6} item style={{ textAlign: "start", marginTop: "auto", marginBottom: "auto" }}>
        <TextField style={{ ...styles.textField }} InputProps={{ value: patientInfo.checkId, readOnly: true, style: { fontWeight: 'bold', textAlign: 'center' } }} />
      </Grid>
      <Grid xs={3} item />
      <Grid xs={3} item style={{ marginTop: "auto", marginBottom: "auto" }}>
        <FormattedMessage id={"patientDetail.info.setupDate"} />
      </Grid>
      <Grid xs={6} item style={{ textAlign: "start", marginTop: "auto", marginBottom: "auto" }}>
        <TextField style={{ ...styles.textField }} value={applyDate} InputProps={{ readOnly: true, style: { fontWeight: 'bold', textAlign: 'center' }} } />
      </Grid>
      <Grid xs={3} item />
      <Grid xs={3} item style={{ marginTop: "auto", marginBottom: "auto" }}>
        <FormattedMessage id={"patientDetail.info.dayVersion"} />
      </Grid>
      <Grid xs={6} item style={{ textAlign: "start", marginTop: "auto", marginBottom: "auto" }}>
        <TextField style={{ ...styles.textField }} InputProps={{ value: parseDayVersion(patientInfo.dayVersion) , readOnly: true, style: { fontWeight: 'bold', textAlign: 'center' } }} />
      </Grid>
      <Grid xs={3} item />
      <Grid xs={3} item style={{ marginTop: "auto", marginBottom: "auto" }}>
        <FormattedMessage id={"patientDetail.info.abnormal"} />
      </Grid>
      <Grid xs={6} item style={{ textAlign: "start", marginTop: "auto", marginBottom: "auto" }}>
        <TextField style={{ ...styles.textField }} InputProps={{ value: patientInfo.abnormal.key === 3 ? intl.formatMessage({ id: 'patientDetail.info.abnormal.other' }) + ": " + patientInfo.abnormal.value : abnormalList().find(v => v.key === patientInfo.abnormal.key).value, readOnly: true, style: { fontWeight: 'bold', textAlign: 'center' } }} />
      </Grid>
      <Grid xs={3} item />
      <Grid xs={3} item style={{ marginTop: "auto", marginBottom: "auto" }}>
        <FormattedMessage id={"patientDetail.info.comment"} />
      </Grid>
      <Grid xs={6} item style={{ textAlign: "start", marginTop: "auto", marginBottom: "auto" }}>
        <TextField style={{ ...styles.textField }} multiline InputProps={{ value: patientInfo.comment, readOnly: true, style: {fontWeight: 'bold', textAlign: 'center' }  }} />
      </Grid>
    </Grid>
  );
}

ConfirmDetail.defaultProps = {
}

ConfirmDetail.propTypes = {
  patientInfo: PropTypes.object.isRequired
};

export default withStyles(styles)(ConfirmDetail);