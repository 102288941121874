import React, { useEffect, useCallback } from "react";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import withStyles from "@material-ui/core/styles/withStyles";
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import { useIntl, FormattedMessage } from 'react-intl';
import { Grid } from "../PatientDetail/PatientDetail";

const ITEM_HEIGHT = 30;
const ITEM_PADDING_TOP = 4;
export const MenuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left"
  },
  getContentAnchorEl: null,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const parseStatus = (status) => {
  switch (status) {
    case 0:
      return <FormattedMessage id={'patientDetail.info.status.created'}/>;
    case 1:
      return <FormattedMessage id={'patientDetail.info.status.signalUploading' }/>;
    case 2:
      return <FormattedMessage id={'patientDetail.info.status.signalFailed' }/>;
    case 3:
      return <FormattedMessage id={'patientDetail.info.status.signalSuccessed' } />;
    case 4:
      return <FormattedMessage id={'patientDetail.info.status.inspecting' } />;
    case 5:
      return <FormattedMessage id={'patientDetail.info.status.inspected' } />;
    case 6:
      return <FormattedMessage id={'patientDetail.info.status.analyzing' } />;
    case 7:
      return <FormattedMessage id={'patientDetail.info.status.analyzed' } />;
    case 8:
      return <FormattedMessage id={'patientDetail.info.status.reviewing' } />;
    case 9:
      return <FormattedMessage id={'patientDetail.info.status.reviewed' } />;
    case 10:
      return <FormattedMessage id={'patientDetail.info.status.confirming' } />;
    case 11:
      return <FormattedMessage id={'patientDetail.info.status.confirmed' } />;
    case 12:
      return <FormattedMessage id={'patientDetail.info.status.reportUploading' } />;
    case 13:
      return <FormattedMessage id={'patientDetail.info.status.reportUploaded' } />;
    case 14:
      return <FormattedMessage id={'patientDetail.info.status.reportReviewing' } />;
    case 15:
      return <FormattedMessage id={'patientDetail.info.status.reportReviewed' } />;
    case 16:
      return <FormattedMessage id={'patientDetail.info.status.reportDownloaded' } />;
    case 17:
      return <FormattedMessage id={'patientDetail.info.status.archived' } />;
  }
};

export const parseStringToStatus = (status) => {
  switch (status) {
    case (<FormattedMessage id={'patientDetail.info.status.created'} />):
      return 0;
    case (<FormattedMessage id={'patientDetail.info.status.signalUploading'} />):
      return 1;
    case (<FormattedMessage id={'patientDetail.info.status.signalFailed'} />):
      return 2;
    case (<FormattedMessage id={'patientDetail.info.status.signalSuccessed'} />):
      return 3;
    case (<FormattedMessage id={'patientDetail.info.status.inspecting'} />):
      return 4;
    case (<FormattedMessage id={'patientDetail.info.status.inspected'} />):
      return 5;
    case (<FormattedMessage id={'patientDetail.info.status.analyzing'} />):
      return 6;
    case (<FormattedMessage id={'patientDetail.info.status.analyzed'} />):
      return 7;
    case (<FormattedMessage id={'patientDetail.info.status.reviewing'} />):
      return 8;
    case (<FormattedMessage id={'patientDetail.info.status.reviewed'} />):
      return 9;
    case (<FormattedMessage id={'patientDetail.info.status.confirming'} />):
      return 10;
    case (<FormattedMessage id={'patientDetail.info.status.confirmed'} />):
      return 11;
    case (<FormattedMessage id={'patientDetail.info.status.reportUploading'} />):
      return 12;
    case (<FormattedMessage id={'patientDetail.info.status.reportUploaded'} />):
      return 13;
    case (<FormattedMessage id={'patientDetail.info.status.reportReviewing'} />):
      return 14;
    case (<FormattedMessage id={'patientDetail.info.status.reportReviewed'} />):
      return 15;
    case (<FormattedMessage id={'patientDetail.info.status.reportDownloaded'} />):
      return 16;
    case (<FormattedMessage id={'patientDetail.info.status.archived'} />):
      return 17;
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
});

function ReportInfo(props) {

  const intl = useIntl();
  const classes = useStyles();
  const { reportInfo, editable, onChange, setter, analystList } = props;
  const menuRef = React.useRef(null);
  const [menuRefO, setMenuRefO] = React.useState(menuRef);

  useEffect(useCallback(() => {
    if (reportInfo.reportReviewer.length > 0 && reportInfo.status < 15) {
      console.log('update status to 15')
      handleChange('status', 15);
    } else if (reportInfo.reportReviewer.length === 0 && reportInfo.status >= 15) {
      handleChange('status', 13);
    }else{
      console.log('nothing changed');
    }

  }), [reportInfo.reportReviewer]);

  const statuses = [
    { key: 0, value: intl.formatMessage({ id: 'patientDetail.info.status.created' }) },
    { key: 1, value: intl.formatMessage({ id: 'patientDetail.info.status.signalUploading' }) },
    { key: 2, value: intl.formatMessage({ id: 'patientDetail.info.status.signalFailed' }) },
    { key: 3, value: intl.formatMessage({ id: 'patientDetail.info.status.signalSuccessed' }) },
    { key: 4, value: intl.formatMessage({ id: 'patientDetail.info.status.inspecting' }) },
    { key: 5, value: intl.formatMessage({ id: 'patientDetail.info.status.inspected' }) },
    { key: 6, value: intl.formatMessage({ id: 'patientDetail.info.status.analyzing' }) },
    { key: 7, value: intl.formatMessage({ id: 'patientDetail.info.status.analyzed' }) },
    { key: 8, value: intl.formatMessage({ id: 'patientDetail.info.status.reviewing' }) },
    { key: 9, value: intl.formatMessage({ id: 'patientDetail.info.status.reviewed' }) },
    // { key: 10, value: intl.formatMessage({ id: 'patientDetail.info.status.confirming' }) },
    // { key: 11, value: intl.formatMessage({ id: 'patientDetail.info.status.confirmed' }) },
    { key: 12, value: intl.formatMessage({ id: 'patientDetail.info.status.reportUploading' }) },
    { key: 13, value: intl.formatMessage({ id: 'patientDetail.info.status.reportUploaded' }) },
    { key: 14, value: intl.formatMessage({ id: 'patientDetail.info.status.reportReviewing' }) },
    { key: 15, value: intl.formatMessage({ id: 'patientDetail.info.status.reportReviewed' }) },
    { key: 16, value: intl.formatMessage({ id: 'patientDetail.info.status.reportDownloaded' }) },
    { key: 17, value: intl.formatMessage({ id: 'patientDetail.info.status.archived' }) },
  ];

  const handleChange = (key, value) => {
    onChange(key, value, setter);
  };

  return (
    <Grid container>
      <Grid item xs mx="auto">
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="select-multiple-checkbox-analyst">
            <FormattedMessage id='patientDetail.report.analyst'/>
          </InputLabel>
          <Select
            style={{ minWidth: 120, maxWidth: 220 }}
            multiple
            value={reportInfo.analyst.map(analyst => analyst.Name)}
            onChange={event => handleChange('analyst', event.target.value.map(v => analystList.find(function (p) {
              return p.Name === v;
            })))}
            input={<Input id="select-multiple-checkbox-analyst" />}
            renderValue={selected => selected.join(', ')}
            disabled={!editable}
            ref={menuRefO}
            onOpen={(e) => {
              console.log('on open');
              console.log(e);
              setMenuRefO(e.currentTarget);
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              getContentAnchorEl: () =>{
                console.log('calling get content anchorEl');
                console.log(menuRefO.current);
                return menuRefO.current;
              },
              PaperProps: {
                style: {
                  maxHeight: ITEM_HEIGHT * 9 + ITEM_PADDING_TOP,
                  width: 250,
                },
              },
            }}
          >
            {analystList.map(analyst => 
              <MenuItem key={analyst.Uuid} value={analyst.Name}>
                <Checkbox checked={reportInfo.analyst.map(analyst => analyst.Name).indexOf(analyst.Name) > -1} />
                <ListItemText primary={analyst.Name} />
              </MenuItem>
            )}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs mx="auto">
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="select-multiple-checkbox-reviewer">
            <FormattedMessage id='patientDetail.report.reviewer' />
          </InputLabel>
          <Select
            style={{ minWidth: 120, maxWidth: 220 }}
            multiple
            value={reportInfo.reviewer.map(reviewer => reviewer.Name)}
            onChange={event => handleChange('reviewer', event.target.value.map(v => analystList.find(function (p) {
              return p.Name === v;
            })))}
            input={<Input id="select-multiple-checkbox-reviewer" />}
            renderValue={selected => selected.join(', ')}
            disabled={!editable}
            ref={menuRefO}
            onOpen={(e) => {
              setMenuRefO(e.currentTarget);
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              getContentAnchorEl: () => {
                return menuRefO.current;
              },
              PaperProps: {
                style: {
                  maxHeight: ITEM_HEIGHT * 9 + ITEM_PADDING_TOP,
                  width: 250,
                },
              },
            }}
          >
            {analystList.map(analyst => (
              <MenuItem key={analyst.Uuid} value={analyst.Name}>
                <Checkbox checked={reportInfo.reviewer.map(analyst => analyst.Name).indexOf(analyst.Name) > -1} />
                <ListItemText primary={analyst.Name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="select-multiple-checkbox-report-reviewer">
            <FormattedMessage id='patientDetail.report.reportReviewer' />
          </InputLabel>
          <Select
            style={{ minWidth: 140, maxWidth: 220 }}
            multiple
            value={reportInfo.reportReviewer.map(reportReviewer => reportReviewer.Name)}
            onChange={event => {
                handleChange('reportReviewer', event.target.value.map(v => analystList.find(function (p) {
                  return p.Name === v;
                })));
              }
            }
            input={<Input id="select-multiple-checkbox-report-reviewer" />}
            renderValue={selected => selected.join(', ')}
            disabled={!editable}
            ref={menuRefO}
            onOpen={(e) => {
              setMenuRefO(e.currentTarget);
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              getContentAnchorEl: () => {
                return menuRefO.current;
              },
              PaperProps: {
                style: {
                  maxHeight: ITEM_HEIGHT * 9 + ITEM_PADDING_TOP,
                  width: 250,
                },
              },
            }}
          >
            {analystList.map(analyst => (
              <MenuItem key={analyst.Uuid} value={analyst.Name}>
                <Checkbox checked={reportInfo.reportReviewer.map(analyst => analyst.Name).indexOf(analyst.Name) > -1} />
                <ListItemText primary={analyst.Name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={2}>
        <FormControl className={classes.formControl} mx="auto">
          <InputLabel htmlFor="select-multiple-checkbox-status">
            <FormattedMessage id='patientDetail.report.status' />
          </InputLabel>
          <Select
            style={{ minWidth: 120, maxWidth: 220 }}
            value={parseStatus(reportInfo.status)}
            onChange={event => handleChange('status', event.target.value.key)}
            input={<Input id="select-multiple-checkbox-status" />}
            renderValue={selected => selected}
            disabled={!editable}
            ref={menuRefO}
            onOpen={(e) => {
              setMenuRefO(e.currentTarget);
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              getContentAnchorEl: () => {
                return menuRefO.current;
              },
              PaperProps: {
                style: {
                  maxHeight: ITEM_HEIGHT * 9 + ITEM_PADDING_TOP,
                  width: 250,
                },
              },
            }}
          >
            {statuses.map(status => (
              <MenuItem key={status.key} value={status} disabled={status.key<15 && reportInfo.reportReviewer.length > 0}>
                <ListItemText primary={status.value} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
}

ReportInfo.propTypes = {
  reportInfo: PropTypes.object.isRequired,
  editable: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  setter: PropTypes.func.isRequired,
  analystList: PropTypes.array
};

export default withStyles(styles)(ReportInfo);