import React, { useEffect, useState, useRef } from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Cookies from "js-cookie";
import { axiosService } from "axios/axiosInstance";
import { FormattedMessage } from 'react-intl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import useAxiosHandler from "../../axios/axiosHandler";

const ITEM_HEIGHT = 30;
const ITEM_PADDING_TOP = 4;
const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

function SalesPrivilege(props) {
  const { classes, onClose } = props;
  const [locationList, setLocationList] = useState([]);
  const [privilegeList, setPrivilegeList] = useState([]);
  const [currUser, setCurrUser] = useState({Uuid:'', UserId: '', LoUuid: []});
  const [currLocation, setCurrLocation] = useState([]);
  const menuRef = useRef(null);
  const [menuRefO, setMenuRefO] = useState(menuRef);
  const [successHandler, errorHandler] = useAxiosHandler();

  const handleChange = (key, value) => {
    console.log(value);
    console.log("key: " + key + " value: " + value);
    if (key === 'user') {
      setCurrUser(value);
      setCurrLocation(value.LoUuid.map(l => locationList.find(v => v.Uuid === l)));
    } else if (key === 'location') {
      setCurrLocation(value);
      setPrivilegeList(prevState => {
        return prevState.map(prev => {
          if(prev.Uuid === currUser.Uuid){
            prev.LoUuid = value.map(l => l.Uuid);
            return prev;
          } else {
            return prev;
          }
        });
      });
    }
  };

  useEffect(() => {
    // query sales privilege status
    axiosService.get('/settings/privilege',
      {
        headers: {
          'Authorization': 'Bearer ' + Cookies.get('apiKey'),
          'X-Socket-ID': window.Echo ? window.Echo.socketId() : ''
        }
      }).then(response => {
        console.log(response.data);
        function onSuccess(response) {
          if(response.status === 200) {
            if(response.data.error === 0) {
              setLocationList(response.data.message.locations);
              setPrivilegeList(response.data.message.sales);
              if (response.data.message.sales.length > 0) {
                setCurrUser(response.data.message.sales[0]);
                setCurrLocation(response.data.message.sales[0].LoUuid.map(l => response.data.message.locations.find(v => v.Uuid === l)));
              }
            } else if (response.data.error === 3) {
              onClose();
            }
          }
        }
        successHandler(response, onSuccess);
      }).catch(error => {
        errorHandler(error);
      });
  }, []);

  const onClickApply = () => {
    axiosService.post('/settings/privilege',
      {
        privilegeList: privilegeList
      },
      {
        headers: {
          'Authorization': 'Bearer ' + Cookies.get('apiKey'),
          'X-Socket-ID': window.Echo ? window.Echo.socketId() : ''
        }
      }).then(response => {
        console.log(response.data);
        function onSuccess(response) {
          if(response.status === 200 && (response.data.error === 0 || response.data.error === 3)) {
            onClose();
          }
        }
        successHandler(response, onSuccess);
      }).catch(error => {
        errorHandler(error);
      });
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6} style={{ textAlign: 'center' }}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="select-multiple-checkbox-user">
              <FormattedMessage id='settings.privilege.user' />
            </InputLabel>
            <Select
              style={{ minWidth: 120, maxWidth: 250 }}
              value={currUser}
              onChange={event => handleChange('user', event.target.value)}
              input={<Input id="select-multiple-checkbox-user" />}
              renderValue={selected => selected.UserId}
              ref={menuRefO}
              onOpen={(e) => {
                setMenuRefO(e.currentTarget);
              }}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                getContentAnchorEl: () => {
                  return menuRefO.current;
                },
                PaperProps: {
                  style: {
                    maxHeight: ITEM_HEIGHT * 9 + ITEM_PADDING_TOP,
                    width: 250,
                  },
                },
              }}
            >
              {privilegeList.map(userPrivilege => (
                <MenuItem key={userPrivilege.Uuid} value={userPrivilege}>
                  <ListItemText primary={userPrivilege.UserId} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </GridItem>
        <GridItem xs={12} sm={12} md={6} style={{ textAlign: 'center' }}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="select-multiple-checkbox-location">
              <FormattedMessage id='settings.privilege.location' />
            </InputLabel>
            <Select
              style={{ minWidth: 120, maxWidth: 250 }}
              multiple
              value={currLocation}
              onChange={event => handleChange('location', event.target.value)}
              input={<Input id="select-multiple-checkbox-location" />}
              renderValue={selected => selected.map(lo => lo.Name).join(', ')}
              ref={menuRefO}
              onOpen={(e) => {
                setMenuRefO(e.currentTarget);
              }}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                getContentAnchorEl: () => {
                  return menuRefO.current;
                },
                PaperProps: {
                  style: {
                    maxHeight: ITEM_HEIGHT * 9 + ITEM_PADDING_TOP,
                    width: 250,
                  },
                },
              }}
            >
              {locationList.sort((a, b) => {
                return a.Name.toUpperCase().localeCompare(b.Name.toUpperCase(), "zh-hant")
              }).map(location => (
                <MenuItem key={location.Uuid} value={location}>
                  <Checkbox checked={currLocation.find(selected => selected.Uuid === location.Uuid) !== undefined} />
                  <ListItemText primary={location.Name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center' }}>
          <Button variant="contained" color="primary" onClick={onClickApply}>
            <FormattedMessage id="settings.privilege.apply"></FormattedMessage>
          </Button>
        </GridItem>
      </GridContainer>
    </div>
  );
}

SalesPrivilege.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func
}

export default withStyles(styles)(SalesPrivilege);
