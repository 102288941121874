import React, { useEffect, useState } from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
// core components
import ReactExport from "react-export-excel";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import TextField from '@material-ui/core/TextField';
import Cookies from "js-cookie";
import { axiosService } from "axios/axiosInstance";
import { useIntl, FormattedMessage } from 'react-intl';
import moment from 'moment-timezone';
import useAxiosHandler from "../../axios/axiosHandler";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

function Export(props) {
  const { classes } = props;
  const intl = useIntl();
  const [successHandler, errorHandler] = useAxiosHandler();
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [isDownload, setIsDownload] = useState(false);
  const [signalUpload, setSignalUpload] = useState(null);
  const [signalDownload, setSignalDownload] = useState(null);
  const [reportUpload, setReportUpload] = useState(null);
  const [reportDownload, setReportDownload] = useState(null);

  useEffect(() => {
    if(signalUpload && signalDownload && reportUpload && reportDownload){
      setIsDownload(true);
    }
  }, [signalUpload, signalDownload, reportUpload, reportDownload]);

  const handleChange = (key, value) => {
    console.log("key: "+key+" value: "+value );
    if(key === 'startDate') {
      setStartDate(value);
      if (moment(value).isAfter(moment(endDate))){
        setEndDate(value);
      }
    } else if (key === 'endDate') {
      setEndDate(value);
      if (moment(value).isBefore(moment(startDate))){
        setStartDate(value);
      }
    }
  };

  const onClickExport = () => {
    setIsDownload(false);
    setSignalUpload(null);
    setSignalDownload(null);
    setReportUpload(null);
    setReportDownload(null);
    axiosService.get('/settings/export?from=' + encodeURIComponent(moment(startDate).utc().format()) + '&to=' + encodeURIComponent(moment(endDate).utc().add(1, 'day').format()),
      {
        headers: {
          'Authorization': 'Bearer ' + Cookies.get('apiKey'),
          'X-Socket-ID': window.Echo ? window.Echo.socketId() : ''
        }
      }).then(response => {
        console.log(response.data);
        function onSuccess(response) {
          if(response.status === 200 && response.data.error === 0){
            setSignalUpload(response.data.message.signalUpload);
            setSignalDownload(response.data.message.signalDownload);
            setReportUpload(response.data.message.reportUpload);
            setReportDownload(response.data.message.reportDownload);
          }
        }
        successHandler(response, onSuccess);
      }).catch(error => {
        errorHandler(error);
      });
  };

  useEffect(() => {
  }, []);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6} style={{ textAlign: 'center' }}>
          <TextField
            id="from-date"
            label={intl.formatMessage({id: 'settings.export.from'})}
            type="date"
            defaultValue={startDate}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            value={startDate}
            onChange={(e) => handleChange('startDate', e.target.value)}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6} style={{textAlign: 'center'}}>
          <TextField
            id="to-date"
            label={intl.formatMessage({ id: 'settings.export.to'})}
            type="date"
            defaultValue={endDate}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            value={endDate}
            onChange={(e) => handleChange('endDate', e.target.value)}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center' }}>
          <Button variant="contained" color="primary" onClick={onClickExport}>
            <FormattedMessage id="settings.export.export"></FormattedMessage>
          </Button>
          {isDownload ? <ExcelFile hideElement>
            <ExcelSheet data={signalUpload} name="Signal Upload">
              <ExcelColumn label="DeviceId" value="DeviceId" />
              <ExcelColumn label="ServiceId" value="PatchId" />
              <ExcelColumn label="User" value={(col) => col.UserId} />
              <ExcelColumn label="Time(UTC)" value="Time" />
            </ExcelSheet>
            <ExcelSheet data={signalDownload} name="Signal Download">
              <ExcelColumn label="DeviceId" value="DeviceId" />
              <ExcelColumn label="ServiceId" value="PatchId" />
              <ExcelColumn label="User" value={(col) => col.UserId} />
              <ExcelColumn label="Time(UTC)" value="Time" />
            </ExcelSheet>
            <ExcelSheet data={reportUpload} name="Report Upload">
              <ExcelColumn label="DeviceId" value="DeviceId" />
              <ExcelColumn label="ServiceId" value="PatchId" />
              <ExcelColumn label="User" value={(col) => col.UserId} />
              <ExcelColumn label="Time(UTC)" value="Time" />
            </ExcelSheet>
            <ExcelSheet data={reportDownload} name="Report Download">
              <ExcelColumn label="DeviceId" value="DeviceId" />
              <ExcelColumn label="ServiceId" value="PatchId" />
              <ExcelColumn label="User" value={(col) => col.UserId} />
              <ExcelColumn label="Time(UTC)" value="Time" />
            </ExcelSheet>
          </ExcelFile> : null}
        </GridItem>
      </GridContainer>
    </div>
  );
}

Export.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Export);
