export const ACTIONTYPE_VIEW = "view";
export const ACTIONTYPE_PATIENTLIST_REMOVEALL = "patientlist_removeall";
export const ACTIONTYPE_PATIENTLIST_ADD = "patientlist_add";
export const ACTIONTYPE_PATIENTLIST_UPDATE = "patientlist_update";
export const ACTIONTYPE_PATIENTLIST_COUNT = "patientlist_count";
export const ACTIONTYPE_ANALYSTLIST = "analyst_list";
export const ACTIONTYPE_PHYSICIANLIST = "physician_list";
export const ACTIONTYPE_LOCATIONLIST = "location_list";
export const ACTIONTYPE_HOSPITALSTAFFLIST = "hospital_staff_list";
export const ACTIONTYPE_SETTING_LANGUAGE = "setting_language";
export const ACTIONTYPE_SETTING_TO_LOGIN = "setting_to_login";
export const ACTIONTYPE_MESSAGE_CONFIG = "message_config";
export const ACTIONTYPE_SETTING_ROLE = "setting_role";
export const ACTIONTYPE_SETTING_TO_RESET_PASSWORD = "setting_to_reset_password";
export const ACTIONTYPE_SETTING_TO_DEMO_VIEW = "setting_to_demo_view";
export const ACTIONTYPE_SETTING_TO_PATIENTLIST = "setting_to_patientlist";
export const ACTIONTYPE_TIMER_APIKEYTIMER = "timer_apikey_timer";
export const ACTIONTYPE_REPORT_EDIT_LOADING = "report_edit_loading";