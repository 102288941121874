import { createStore } from "redux";
import reducers from "./reducers/reducers";
import Cookies from 'js-cookie';
import decode from "jwt-claims";

function getRole(apiKey) {
  try{
    return decode(apiKey).Role;
  }catch(e) {
    Cookies.remove('username', { path: '/', secure: true });
    Cookies.remove('apiKey', { path: '/', secure: true });
    return 1;
  }
}

function getLang(navigatorLang) {
  switch(navigatorLang) {
    case 'zh-TW':
      return 'zh-TW';
    case 'en-US':
    case 'en-GB':
    default:
      return 'en-US';
  }
}

export function makeStore(){
  
  if (!!!Cookies.get('lang')) {
    Cookies.set('lang', getLang(navigator.language), { path: '/', secure: true })
  }
  const lang = getLang(Cookies.get('lang') === undefined ? navigator.language : Cookies.get('lang'));
  const apiKey = Cookies.get('apiKey');

  const role = apiKey !== undefined ? getRole(apiKey) : 1;
  
  return createStore(reducers, {
    timer: {
      apiKeyTimer: null,
    },
    message: {
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
      open: false,
      message: '',
      color: 'danger',
      timeout: 3000
    },
    setting: {
      language: lang,
      toLogin: false,
      toResetPassword: false,
      toDemoView: false,
      toPatientList: false,
      role: role,
    },
    view: {
      mobileOpen: false,
      reportEditLoading: false,
    },
    patientList: {
      count: 0,
      listContent: [],
    },
    initialData: {
      analystList: [],
      physicianList: [],
      locationList: [],
      hospitalStaffList: []
    }
  },
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
}
