import React, { Component } from 'react';
import moment from 'moment';
import './Message.css';
import PropTypes from 'prop-types';

export default class Message extends Component {
  render() {
    const {
      data,
      isMine,
      startsSequence,
      endsSequence,
      showTimestamp,
      username
    } = this.props;

    const utctime = new Date(data.time);
    const time = new Date(Date.UTC(utctime.getFullYear(), utctime.getMonth(), utctime.getDate(), utctime.getHours(), utctime.getMinutes(), utctime.getSeconds()));
    const friendlyTimestamp = moment(time).format('LLLL');
    return (
      <div className={[
        'message',
        `${isMine ? 'mine' : ''}`,
        `${startsSequence ? 'start' : ''}`,
        `${endsSequence ? 'end' : ''}`
      ].join(' ')}>
        {
          showTimestamp &&
            <div className="timestamp">
              { friendlyTimestamp }
            </div>
        }

        {startsSequence && !isMine && <div className={['message',
          `${isMine ? 'mine' : ''}`,
          `${'username'}`].join(' ')}>{username}</div>}
        <div className="bubble-container">
          <div className="bubble" style={{wordBreak: "break-all"}} title={friendlyTimestamp}>
            {data.content.message.split("\n").map((value, index) => {
              return <div style={{wordBreak: "normal"}} key={'msg_' + index}>{value}</div>;
            })}
          </div>
        </div>
      </div>
    );
  }
}

Message.propTypes = {
  data: PropTypes.shape({
      time: PropTypes.number,
      content: PropTypes.shape(
        {
          message: PropTypes.string
        }
      )
    }),
  isMine: PropTypes.bool,
  startsSequence: PropTypes.bool,
  endsSequence: PropTypes.bool,
  showTimestamp: PropTypes.bool,
  username: PropTypes.string
}