import React, { useEffect, useState, useCallback } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from "prop-types";

import { axiosService } from "axios/axiosInstance";
import Cookies from "js-cookie";

import Notifications from '@material-ui/icons/Notifications';
import NotificationsActive from '@material-ui/icons/NotificationsActive';
import useAxiosHandler from '../../axios/axiosHandler';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import MessageList from 'components/Chat/MessageList';

export function MessengerHeader(props) {
  const {editable, enableChatNotify, setEnableChatNotify} = props;
  const intl = useIntl();

  const onChangeNotify = () => {

    setEnableChatNotify(!enableChatNotify);
  }

  return (
    <Tooltip title={intl.formatMessage({ id: 'patientDetail.newMessageNotification' })}>
      <IconButton disabled={!editable} style={{ float: 'right' }} onClick={() => onChangeNotify()}>
        {enableChatNotify ? <NotificationsActive style={{ color: "green" }} /> : <Notifications />}
      </IconButton>
    </Tooltip>);
}
MessengerHeader.propTypes = {
  uuid: PropTypes.string.isRequired,
  editable: PropTypes.bool.isRequired,
  enableChatNotify: PropTypes.bool.isRequired,
  setEnableChatNotify: PropTypes.func.isRequired,
}

function Messenger(props) {
  const { uuid, editable, handleRefreshRequest, isDemo, messages } = props;
  const [chatList, setChatList] = useState(messages);
  const [successHandler, errorHandler] = useAxiosHandler();

  useEffect(useCallback(()=>{
    if (!isDemo) {
      axiosService.get('/patientdetail/content/chat?uuid=' + uuid,
        {
          headers: {
            'Authorization': 'Bearer ' + Cookies.get('apiKey'),
            'X-Socket-ID': window.Echo ? window.Echo.socketId() : ''
          }
        }).then(response => {
          console.log(response.data);
          function onSuccess(response) {
            if (response.status === 200 && response.data.error === 0) {
              console.log('get char content success.');
              if (response.data.message.board !== undefined && response.data.message.board !== null) {
                if (response.data.message.board.messages !== undefined) {
                  setChatList(response.data.message.board.messages);
                }
              }
            }
          }
          successHandler(response, onSuccess);
        }).catch(error => {
          errorHandler(error);
        });
    }
  }), []);
  return (
    <MessageList uuid={uuid} isDemo={isDemo} chatList={chatList} setChatList={setChatList} editable={editable} handleRefreshRequest={handleRefreshRequest} />
  );
}

Messenger.defaultProps = {
  isDemo: false,
  messages: [],
}

Messenger.propTypes = {
  uuid: PropTypes.string.isRequired,
  editable: PropTypes.bool.isRequired,
  handleRefreshRequest: PropTypes.func,
  isDemo: PropTypes.bool,
  messages: PropTypes.array,
}

export default Messenger;