/* eslint-disable */
import React, { useRef, useState, useEffect, useCallback, Fragment } from "react";
import PropTypes from "prop-types";
import { Outlet } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Header from "components/Header/Header.jsx";
import { useDispatch, useMappedState } from "redux-react-hook";
import { ACTIONTYPE_MESSAGE_CONFIG } from "../../redux/actionTypes";

import dashboardRoutes from "routes/dashboard.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx";

import { useSnackbar } from 'notistack';
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";

function TopmostMessage() {
  const { enqueueSnackbar, closeSnackbar} = useSnackbar();
  const dispatch = useDispatch();
  const messageConfig = useMappedState(useCallback(state => state.message));
  useEffect(() => {
    console.log("on open effected: "+messageConfig.open+", timeout: "+messageConfig.timeout);
    console.log("message key: ", messageConfig.key);
    if (messageConfig.open) {
      const action = (key) => (
        <Fragment>
          <IconButton onClick={() => { closeSnackbar(key) }}>
            <Close />
          </IconButton>
        </Fragment>
      );
      enqueueSnackbar(<div style={{
              maxWidth: "600px",
              overflowWrap: "break-word",
              maxHeight: "300px",
              overflow: "auto"
            }
          }>{messageConfig.message}</div>, {
        key: messageConfig.key,
        variant: messageConfig.color,
        autoHideDuration: messageConfig.timeout === 0 ? null : messageConfig.timeout,
        anchorOrigin: messageConfig.anchorOrigin,
        style: {display: 'block', pointerEvents: 'all'},
        action: messageConfig.timeout === 0 ? action : null,
        onClose: () => {
          dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: false } });
          if(messageConfig.onCLose && typeof messageConfig === 'function') {
            messageConfig.onClose();
          }
        },
       });
    }
  }, [messageConfig]);
  return null;
}

const App = (props) => {

  const { classes, ...rest } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const mainPanelRef = useRef();

  useEffect(useCallback(() => {
    if (navigator.platform.indexOf("Win") > -1 && !getIsLogin() && !getIsUploadfiles()) {
      const ps = new PerfectScrollbar(mainPanelRef);
    }
    if (mainPanelRef.current) {
      mainPanelRef.current.scrollTop = 0;
    }
    window.addEventListener("resize", resizeFunction, { capture: true });
    return () => {
      window.removeEventListener("resize", resizeFunction);
    };
  }), []);

  const resizeFunction = () => {
    setMobileOpen(window.innerWidth < 960);
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const getIsLogin = () => {
    return window.location.pathname.toLowerCase() === "/webanalyzer/v1/login";
  }

  const getIsResetPassword = () => {
    return window.location.pathname.toLowerCase() === "/webanalyzer/v1/user/resetpassword";
  }

  const getIsUploadfiles = () => {
    return window.location.pathname.toLowerCase() === "/webanalyzer/v1/uploadfiles";
  }

  return (
    <div className={classes.wrapper} /*token={this.state.token}*/ style={{ background: 'white' }}>
      {/*<Sidebar
          hide="true"
          routes={dashboardRoutes}
          logoText={"JD"}
          logo={logo}
          image={image}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          color="primary"
          {...rest}
        />*/}
      <div className={classes.mainPanel} ref={mainPanelRef}>
        <TopmostMessage />
        <Header
          onlySetting={getIsLogin()}
          resetPassword={getIsResetPassword()}
          routes={dashboardRoutes}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {/*this.getIsMaps() ? (
            <div className={classes.map}>{switchRoutes}</div>
          ) : (*/
          <div className={classes.content}>
            <div className={classes.container}><Outlet /></div>
            {/* <div className={classes.container}>Test Dashboard</div> */}
          </div>
          /*)*/}
        {/*this.getIsMaps() ? <Footer /> : null*/}
      </div>
    </div>
  );
};

App.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(App);
